import React from 'react';
import { Link } from 'react-router-dom';
import './fj-landing.scss';
// import '../../promo-landing.scss';

import playOnline from '../../../../assets/images/online.png';
import appleStore from '../../../../assets/images/apple store.png';
import googlePlay from '../../../../assets/images/google play.png';
import howToPlay1 from '../../../../assets/images/svg/1.png';
import howToPlay2 from '../../../../assets/images/svg/2.png';
import howToPlay3 from '../../../../assets/images/svg/3.png';
import fjlogo from '../../../../assets/images/fjlogo.png';
import thriveLogo from '../../../../assets/images/logo.png';

const FJLanding = () => {
    return (
        <>
            <section className="landing-section-1-promo">
                <div className="promo-left">
                    <div className="fj-content">
                        <div className="fj-banner-cta">
                            <p>Sign up now and get a $25 FJ Fantasy Sports Credit</p>

                        </div>

                        <div className="fj-banner-instructions">
                            <p className="how-to-claim">How to Claim</p>
                            <div className="instruction-block">
                                <p>1. </p>
                                <p>Make a minimum deposit of $10* on ThriveFantasy and play in a paid contest</p>

                            </div>
                            <div className="fj-second-instruction">
                                <div className="instruction-block">
                                    <p>2.</p>
                                    <p>Receive a <strong>FREE $25 FJ Fantasy Sports Credit</strong> </p>
                                </div>
                            </div>
                            <div className="instruction-block">
                                <p>3.</p>
                                <p>You will receive a confirmation email from FJ Fantasy Sports within 24-48 hours</p>
                            </div>
                        </div>
                    </div>
                    <Link to={process.env.REACT_APP_START_URL + "sign-up"} title="Play Online">
                        <span>PLAY NOW</span>
                    </Link>
                    <p id="fj-warning">*Valid for new users only</p>
                </div>
                <div className="promo-right">
                    <div className="thrive-fj">
                        <div className="thrive-fj-logo"></div>
                    </div>
                </div>
            </section>

            <section className="landing-section-2">
                <div className="inner">
                    <h2 id="how-to-play">How To Play!</h2>
                    <iframe width="920" height="518" src="https://www.youtube.com/embed/VALDCo6wn68" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <ul>
                        <li>
                            <div>
                                <img src={howToPlay1} alt="Choose a Contest" />
                            </div>
                            <h3>Choose a Contest!</h3>
                            <p>We Offer NFL, NBA, MLB, PGA, Cricket and Esports (CS:GO, Dota, LoL)</p>
                        </li>
                        <li>
                            <div>
                                <img src={howToPlay2} alt="Pick Your Side" />
                            </div>
                            <h3>Pick Your Props!</h3>
                            <p>Make A Lineup In Minutes.</p>
                        </li>
                        <li>
                            <div>
                                <img src={howToPlay3} alt="Get Your Points" />
                            </div>
                            <h3>Win Cash Prizes!</h3>
                            <p>Watch Your Team and Rack Up Points!</p>
                        </li>
                    </ul>
                    <p>
                        By focusing solely on top-tier athletes while streamlining the drafting process, we've created a unique DFS experience that gives the casual sports and Esports fan an opportunity to compete and succeed at a high level.
                    </p>
                </div>
            </section>
            <section className="landing-section-3">
                <div className="inner">
                    <h2>#PropUp</h2>
                    <p>Our app is available on any device! Download now to get started!</p>
                    <div className="btns">
                        <ul>
                            <li>
                                <Link to={process.env.REACT_APP_START_URL + "sign-up"} title="Play Online">
                                    <img src={playOnline} alt="Online" />
                                    <span>Play Online</span>
                                </Link>
                            </li>
                            <li>
                                <a href="https://apps.apple.com/us/app/thrivefantasy/id1240062484?ls=1" title="Apple Store" target="_blank" rel="noopener noreferrer">
                                    <img src={appleStore} alt="Apple Store" />
                                    <span>
                                        <small>Download on</small>
                                        App Store
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.appster.p2f&hl=en_US" title="Google Play" target="_blank" rel="noopener noreferrer">
                                    <img src={googlePlay} alt="Google Play" />
                                    <span>
                                        <small>Download on</small>
                                        Google Play
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FJLanding;

