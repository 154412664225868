import React, { useState, useEffect } from 'react';

import './how-to-play.scss';

import row1Left from '../../../assets/images/how-to-play/row-1-left.jpeg';
import row1Right from '../../../assets/images/how-to-play/row-1-right.jpeg';
import row2Left from '../../../assets/images/how-to-play/row-2-left.png';
import row2Right from '../../../assets/images/how-to-play/row-2-right.PNG';
import row3Left from '../../../assets/images/how-to-play/row-3-left.jpeg';
import row3Right from '../../../assets/images/how-to-play/row-3-right.jpeg';
import row4Left from '../../../assets/images/how-to-play/row-4-left.jpeg';
import row4Right from '../../../assets/images/how-to-play/row-4-right.jpeg';
import row5 from '../../../assets/images/how-to-play/row-5.png';
import { Mixpanel } from '../../mixpanel/Mixpanel';


const HowToPlay = () => {
  useEffect(() => {
    Mixpanel.track('web:how_to_play_opened');
  }, [])

  return(
    <div className="how-to-play info-page">
      <div className="how-to-play-header info-page-header">
        <h2>How To Play</h2>
      </div>
      {/* <div className="info-page-body-wrapper"> */}
        <div id="how-to-play-body" className="info-page-body">
          <strong>Determining the Right Contest for You</strong>
          <p>
            Start by choosing a contest in the Contest Lobby. ThriveFantasy offers contests in NFL, NBA,
            MLB, Cricket, PGA and Esports (CS:GO, Dota, LoL, Valorant). Our contests are broken down into two contest
            types.
          </p>
          <p>
            <strong>Traditional: </strong> Select a player’s individual stat-line or a combination of stat-lines to win a
            predetermined amount of fantasy points. 
          </p>
          <p>
            <strong>Versus: </strong> Select which of two players will perform better in the pre-selected stat category. The
            player less likely to win will earn more fantasy points. 
          </p>
          <p>
            From there, the contests are broken down in the lobby by how many other opponents you’ll be
            playing against. 
          </p>
          <ul>
            <li>
              <strong>Featured</strong> – These contests have the “star” logo. They have the biggest prize pools and the
              largest payouts.
            </li>
            <li>
              <strong>Tournaments/Leagues</strong> – Small & large field contests with huge prizes
            </li>
            <li>
              <strong>Head to Head</strong> – Face-off against one opponent; winner takes all.
            </li>
          </ul>
          <div className="image-row">
            <div className="image-row-col">
              <strong id="image-row-col-header">Featured Contests</strong>
              <img src={row1Left}></img>
            </div>
            <div className="image-row-col">
              <strong id="image-row-col-header">Head To Head</strong>
              <img src={row1Right}></img>
            </div>
          </div>
          <strong>Selecting Your Picks:</strong>
          <p>
            Every contest will give you the opportunity to pick certain props from a predetermined list. Each
            unique prop has an “Over” and “Under” point value assigned to it and you will be rewarded that
            point value if that prop is correct. The higher the point value, the less probable it is to occur. 
          </p>
          <p>
            It is important to understand that the Over and Under for each prop always adds up to 200 points.
            This means that if the Over is worth 105 points, the Under will be worth 95 points. 
          </p>
          <p>
            Your team is made up of 10 picks that you select out of an available list of 20. You’ll also select
            two In-Case-of-Emergency (ICE) picks. ICE Picks automatically replace any player/team that
            doesn't play for any unforeseen circumstances (i.e late scratches, weather, PPD).
          </p>
          <div className="image-row">
            <div className="image-row-col">
              <strong id="image-row-col-header">Featured Contests</strong>
              <img src={row2Left} id="tall-img"></img>
            </div>
            <div className="image-row-col">
              <strong id="image-row-col-header">Head To Head</strong>
              <img src={row2Right} id="tall-img"></img>
            </div>
          </div>
          <div className="image-row">
            <div className="image-row-col">
              <strong id="image-row-col-header">Featured Contests</strong>
              <img src={row3Left}></img>
            </div>
            <div className="image-row-col">
              <strong id="image-row-col-header">Head To Head</strong>
              <img src={row3Right}></img>
            </div>
          </div>

          <p>
            Your team is made up of 10 picks that you select out of an available list of 20. You’ll also select
            two In-Case-of-Emergency (ICE) picks. ICE Picks automatically replace any player/team that
            doesn't play for any unforeseen circumstances (i.e late scratches, weather, PPD). 
          </p>

          <div className="image-row">
            <div className="image-row-col">
              <strong id="image-row-col-header">Featured Contests</strong>
              <img src={row4Left}></img>
            </div>
            <div className="image-row-col">
              <strong id="image-row-col-header">Head To Head</strong>
              <img src={row4Right}></img>
            </div>
          </div>

          <p>
            After you choose your main 10 picks and 2 ICE picks, enter the contest and enjoy the games! 
          </p>
          <strong>Follow Live:</strong>

          <p>
            ThriveFantasy features live updates to tell you in real-time how close your picks are to hitting.
            Follow along while you watch the games and see how your team is stacking up against your
            competition.
          </p>

          <p>
            <strong>Prop Lobby:</strong> Select <strong>two</strong>, <strong>three</strong> or <strong>four</strong> players to create a lineup from the list of player
            projections. Players may be selected across different sports leagues. (Website Only)
          </p>

          <strong>Choose your entry amount between $1 - $200.</strong>

          <p>Pick the correct sides of all the selected predictions and you win! </p>

          <strong>Choose 2/2 correctly win 3.6x your entry amount ($10 entry wins $36).</strong>
          <strong>Choose 3/3 correctly win 6.2x your entry amount. </strong>
          <strong>Choose 4/4 correctly win 11x your entry amount. </strong>

          <div className="full-row-image">
            <img src={row5}></img>
          </div>

          <strong>Check Results:</strong>
          <p>
            If you’ve placed in a paying position, the amount you’ve won, as determined by the prize
            structure of the contest, will be credited to your ThriveFantasy account.
          </p>

          <p>
            You can use your winnings to play more contests, try other sports, or withdraw the amount to
            your PayPal account. For more information on how to withdraw or deposit or any other
            inquiries, please contact admin@thrivefantasy.com. 
          </p>

          <strong>Responsible Gaming </strong>

          <p>
            ThriveFantasy contests ensures every user can play within their financial means. We are committed
            to integrity, fairness and reliability. We do everything possible to prevent gaming-related problems.
            We’ve developed measures to maintain a responsible, safe and reliable place for online gaming.
          </p>

          <p>
            ThriveFantasy offers the options to self-exclude and self limit. If, at any point, you think you're
            spending too much time or money on ThriveFantasy, you have the option of taking a break entirely.
            Self-limiting allows you to still play, however, within the parameters that you set for yourself. 
          </p>

          <p>Options to Limit Play: </p>

          <ul>
            <li>Self-Exclusion </li>
            <li>Self-Limitation</li>
          </ul>

          <p>
            ThriveFantasy also provides options to limit the amount of funds you can deposit into your account
            by choosing an amount over a daily, weekly and monthly period. 
          </p>

        </div>
      {/* </div> */}
    </div>
  )
}

export default HowToPlay;