import React from "react";
import PokerGo from "../../../../../src/assets/images/pokergo-sweepstakes.png";
import "./pokergo-sweepstakes.scss";

function Sweepstakes() {
  (function () {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

    let today = new Date(),
      dd = String(today.getDate()).padStart(2, "0"),
      mm = String(today.getMonth() + 1).padStart(2, "0"),
      yyyy = today.getFullYear(),
      nextYear = yyyy + 1,
      dayMonth = "02/07/",
      totalTime = dayMonth + yyyy;

    today = mm + "/" + dd + "/" + yyyy;
    if (today > totalTime) {
      totalTime = dayMonth + nextYear;
    }

    const countdownDate = new Date("Feb 6, 2022 23:59:59").getTime(),
      decrementer = setInterval(function () {
        const now = new Date().getTime(),
          distance = countdownDate - now;

        document.getElementById("days").innerText = Math.floor(distance / day);
        document.getElementById("hours").innerText = Math.floor(
          (distance % day) / hour
        );
        document.getElementById("minutes").innerText = Math.floor(
          (distance % hour) / minute
        );
        document.getElementById("seconds").innerText = Math.floor(
          (distance % minute) / second
        );

        if (distance < 0) {
          document.getElementById("headline").innerText = "It's my birthday!";
          document.getElementById("countdown").style.display = "none";
          document.getElementById("content").style.display = "block";
          clearInterval(decrementer);
        }
      }, 0);
  })();
  return (
    <body className="sweepstakes-page">
      <div className="wrapper">
        <div className="outer-container">
          <div className="inner-container">
            <img src={PokerGo} />
            <h1>THRIVEFANTASY</h1>
            <h1>VEGAS VIP BIG GAME</h1>
            <h1>SWEEPSTAKES</h1>
            <ul>
              <h3>WHAT YOU WIN:</h3>
              <li>TWO NIGHTS AT THE ARIA (SATURDAY 2/12 & SUNDAY 2/13)</li>
              <li>ROUNDTRIP AIRFARE FOR TWO PEOPLE</li>
              <li>
                DINNER FOR TWO SATURDAY AT WINNERS CHOICE OF (CATCH OR JAVIER'S)
              </li>
              <li>
                SUNDAY EXCLUSIVE VIP SUPER BOWL VIEWING PARTY AT CAESAR'S <br />{" "}
                PALACE SPORTSBOOK FOR TWO PEOPLE
              </li>
              <h5>• RESERVED STADIUM SEATING</h5>
              <h5>
                • INCLUDES UNLIMITED DRINKS AND EXPEDITED VIP BETTING LINE
              </h5>
              <h3>HOW TO ENTER:</h3>
              <li>
                DURING THE SWEEPSTAKES PERIOD, VISIT THRIVEFANTASY.COM/SIGNUP
              </li>
              <li>
                FOLLOW THE ONLINE INSTRUCTIONS TO SIGN-UP AND USE THE CODE
                "POKERGO"
              </li>
              <li>THEN YOU'RE AUTOMATICALLY ENTERED</li>
            </ul>
            <ul className="socials">
              <a href="https://www.thrivefantasy.com/contest-lobby">
                <button className="play"></button>
              </a>
              <a href="https://twitter.com/ThriveFantasy">
                <button className="twitter"></button>
              </a>
              <a href="https://www.instagram.com/thrivefantasy/?hl=en">
                <button className="instagram"></button>
              </a>
              <a href="https://www.tiktok.com/@thrivefantasyapp?lang=en">
                <button className="tiktok"></button>
              </a>
            </ul>
            <ul className="sweepstakes">
              <a href="https://documentcloud.adobe.com/link/track?uri=urn:aaid:scds:US:7f25e6c0-8dfb-4406-a69f-4ee5410a5887#pageNum=1">
                <button>Official Rules</button>
              </a>
              <a href="https://www.thrivefantasy.com/?promo=pokergo">
                <button>Enter Now</button>
              </a>
            </ul>
            <div class="container">
              <h1 id="headline">Time Left To Enter</h1>
              <div id="countdown">
                <ul className="nav">
                  <li>
                    <span id="days"></span>days
                  </li>
                  <li>
                    <span id="hours"></span>Hours
                  </li>
                  <li>
                    <span id="minutes"></span>Minutes
                  </li>
                  <li>
                    <span id="seconds"></span>Seconds
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Sweepstakes;
