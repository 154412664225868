import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Mixpanel } from '../../../mixpanel/Mixpanel';
import './template-b.scss';
import { useEffect } from 'react';

import playOnline from '../../../../assets/images/online.png';
import appleStore from '../../../../assets/images/apple store.png';
import googlePlay from '../../../../assets/images/google play.png';
import howToPlay1 from '../../../../assets/images/svg/1.png';
import howToPlay2 from '../../../../assets/images/svg/2.png';
import howToPlay3 from '../../../../assets/images/svg/3.png';


class TemplateBLanding extends React.Component {
    lpImage = null;
    lpImageLink = null;

    componentDidMount() {
        Mixpanel.track('web:landing_page_opened', {
            'Type': 'B',
            'Name': this.props.lp.name,
        });
    }

    //<img src={this.lpImageLink} alt=''></img>/
    render() {
        this.lpImage = this.props.lp.image;
        this.lpImageLink = 'https://lp-partner-logos.s3.amazonaws.com/' + this.lpImage;

        return (
            <div>
                <div class="landing-title-templateB">
                    <div className="lp-vert-logos-mini" style={{ backgroundImage: "url(" + this.lpImageLink + ")" }}>

                    </div>
                </div>
                <section className="landing-section-1-templateB">
                    <div className="inner-templateB">
                        <div className="subheader">
                            {this.props.lp.textbox1 !== null && this.props.lp.textbox1 !== '' ?
                                <h1>{this.props.lp.textbox1}</h1>
                                : ''}
                            {this.props.lp.textbox2 !== null && this.props.lp.textbox2 !== '' ?
                                <p id="deposit-match-message">{this.props.lp.textbox2}</p>
                                : ''}
                            {this.props.lp.textbox3 !== null && this.props.lp.textbox3 !== '' ?
                                <p id='thrive-tagline' style={{ color: 'white' }}>{this.props.lp.textbox3}</p>
                                : ''}
                        </div>
                        <div className="btns">
                            <ul>
                                <li>
                                    {this.props.lp.playNowColor !== null && this.props.lp.playNowColor !== '' ?
                                        <Link style={{ backgroundColor: this.props.lp.playNowColor }} to={process.env.REACT_APP_START_URL + "sign-up"} title="Play Online" redirect={this.props.redirectLink}>

                                            {this.props.lp.playNowText !== null && this.props.lp.playNowText !== '' ?
                                                <span>{this.props.lp.playNowText}</span> :
                                                <span>PLAY NOW</span>
                                            }
                                        </Link>
                                        :
                                        <Link to={process.env.REACT_APP_START_URL + "sign-up"} title="Play Online" redirect={this.props.redirectLink}>

                                            {this.props.lp.playNowText !== null && this.props.lp.playNowText !== '' ?
                                                <span>{this.props.lp.playNowText}</span> :
                                                <span>PLAY NOW</span>
                                            }
                                        </Link>}
                                    {this.props.lp.disclaimerText !== null && this.props.lp.disclaimerText !== undefined && this.props.lp.disclaimerText !== '' ?
                                        <p id="templateB-warning">{this.props.lp.disclaimerText}</p> : ''}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="landing-slider-templateB">
                        <div>

                            <div className="lp-vert-logos" style={{ backgroundImage: "url(" + this.lpImageLink + ")" }}>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="landing-section-2">
                    <div className="inner">
                        <h2 id="how-to-play">How To Play!</h2>
                        <iframe width="920" height="518" src="https://www.youtube.com/embed/VALDCo6wn68" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <ul>
                            <li>
                                <div>
                                    <img src={howToPlay1} alt="Choose a Contest" />
                                </div>
                                <h3>Choose a Contest!</h3>
                                <p>We Offer NFL, NBA, MLB, PGA, Cricket and Esports (CS:GO, Dota, LoL)</p>
                            </li>
                            <li>
                                <div>
                                    <img src={howToPlay2} alt="Pick Your Side" />
                                </div>
                                <h3>Pick Your Props!</h3>
                                <p>Make A Lineup In Minutes.</p>
                            </li>
                            <li>
                                <div>
                                    <img src={howToPlay3} alt="Get Your Points" />
                                </div>
                                <h3>Win Cash Prizes!</h3>
                                <p>Watch Your Team and Rack Up Points!</p>
                            </li>
                        </ul>
                        <p>
                            By focusing solely on top-tier athletes while streamlining the drafting process, we've created a unique DFS experience that gives the casual sports and Esports fan an opportunity to compete and succeed at a high level.
                        </p>
                    </div>
                </section>
                <section className="landing-section-3">
                    <div className="inner">
                        <h2>#PropUp</h2>
                        <p>Our app is available on any device! Download now to get started!</p>
                        <div className="btns">
                            <ul>
                                <li>
                                    <Link to={process.env.REACT_APP_START_URL + "sign-up"} title="Play Online">
                                        <img src={playOnline} alt="Online" />
                                        <span>Play Online</span>
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://apps.apple.com/us/app/thrivefantasy/id1240062484?ls=1" title="Apple Store" target="_blank" rel="noopener noreferrer">
                                        <img src={appleStore} alt="Apple Store" />
                                        <span>
                                            <small>Download on</small>
                                            App Store
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://play.google.com/store/apps/details?id=com.appster.p2f&hl=en_US" title="Google Play" target="_blank" rel="noopener noreferrer">
                                        <img src={googlePlay} alt="Google Play" />
                                        <span>
                                            <small>Download on</small>
                                            Google Play
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}

export default TemplateBLanding;