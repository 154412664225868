import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from '../header/header';
import PrivacyPolicyPage from './privacy-policy-page';
import TermsAndConditionsPage from './terms-and-conditions-page';
import HowToPlay from './how-to-play/how-to-play';
import FrequentlyAskedQuestions from './faq/faq';

import './info-page.scss';


const InfoPage = () => {
  return(
    <div className="info-page-container">
      <Header onLandingPage={false} />
      <Switch>
        <Route exact path={process.env.REACT_APP_START_URL + 'privacy-policy'} component={PrivacyPolicyPage}/>
        <Route exact path={process.env.REACT_APP_START_URL + 'terms-and-conditions'} component={TermsAndConditionsPage}/>
        <Route exact path={process.env.REACT_APP_START_URL + 'how-to-play'} component={HowToPlay}/>
        <Route exact path={process.env.REACT_APP_START_URL + 'frequently-asked-questions'} component={FrequentlyAskedQuestions}/>
      </Switch>
    </div>
  )
}

export default InfoPage;