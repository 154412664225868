import React from 'react';
import {Link} from "react-router-dom";
import './my-contests-live.scss';
import LoggedInHeader from '../Logged-in-header/Logged-in-header';
import $ from 'jquery';
import {isAuthenticated} from "../isAuthenticated";
import Moment from "react-moment";
import Footer from "../footer/footer";
import { Mixpanel } from '../mixpanel/Mixpanel';

let liveList = [];

let requestLiveBody = {
    "leagueType": null,
    "contestType": null,
    "currentPage": 1,
    "currentSize": 50,
    'sortType': '0'
};

const requestLiveOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    },
    body: JSON.stringify(requestLiveBody)
};

class MyContestsLive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            liveList: liveList,
            call: false
        };
        $('.loader').addClass('show');
        this.fetchLiveData = this.fetchLiveData.bind(this);
        this.handleEventSort = this.handleEventSort.bind(this);
        this.sortLive = this.sortLive.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    fetchLiveData(requestLiveOptions) {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'contests/live', requestLiveOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    if (requestLiveBody['currentPage'] > 1) {
                        for (let i = 0; i < data['response']['data'].length; i++) {
                            liveList.push(data['response']['data'][i]);
                        }
                    } else {
                        liveList = data['response']['data'];
                    }
                    this.setState({liveList: liveList, call: true});

                    if (liveList.length >= requestLiveBody['currentSize']) {
                        $('.load-more').addClass('show');
                    }
                    $('.loader').removeClass('show');
                } else {
                    $('.loader').removeClass('show');
                }
            });
    }

    componentDidMount() {
        isAuthenticated('login', '/my-contests/live');
        this.fetchLiveData(requestLiveOptions);

        $('.sort-events').on('click', function () {
            $('.sort-events').find('div').toggleClass('open');
        });

        $('.mobile-select-sport').on('click', '.selected', function () {
            $('.mobile-select-sport').find('.selection').toggleClass('open');
        });

        Mixpanel.track('web:my_contests_opened');

    }

    loadMore() {
        requestLiveBody['currentPage'] = requestLiveBody['currentPage'] + 1;
        requestLiveOptions['body'] = JSON.stringify(requestLiveBody);
        this.fetchLiveData(requestLiveOptions);
    }

    handleEventSort(event) {
        $('.sort-events').find('li').removeClass('active');
        event.target.classList.add('active');
        $('.selection').removeClass('open').prev('.selected').html(event.target.innerText).removeAttr('class').addClass('selected ' + event.target.getAttribute('data-class'));

    }

    sortLive(event) {
        requestLiveBody['sortType'] = parseInt(event.target.getAttribute('rel'));
        requestLiveOptions['body'] = JSON.stringify(requestLiveBody);
        this.fetchLiveData(requestLiveOptions);
        $('.sort-events').find('li').removeClass('active');
        event.target.classList.add('active');
        $('.sort-events').find('div').removeClass('open');

        Mixpanel.track('web:user_sorted_my_contests', {
            'Sort By':event.target.getAttribute('rel')
        })

    }

    render() {
        console.log(this.state.liveList);
        return (
            <main className="my-contest-live">
                <LoggedInHeader/>
                <div className="inner">
                    <nav className="filters">
                        <button>Date</button>
                        <button>Entry Fee</button>
                        <button>Prize Pool</button>
                        <button>Number of Entries</button>
                    </nav>
                    <aside>
                        <h2>Select Category</h2>
                        <nav className="my-contest-nav">
                            <Link className="live active" to={process.env.REACT_APP_START_URL + "my-contests/live"} title="live">Live</Link>
                            <Link className="upcoming" to={process.env.REACT_APP_START_URL + "my-contests/upcoming"} title="Upcoming">Upcoming</Link>
                            <Link className="History" to={process.env.REACT_APP_START_URL + "my-contests/history"} title="History">History</Link>

                        </nav>
                    </aside>
                    <section className="events">
                        <div className="mobile-select-sport">
                            <h2>Select Category</h2>
                            <div className="selected">
                                Live
                            </div>
                            <div className="selection">
                                <nav className="my-contest-nav">
                                    <Link className="live active" to={process.env.REACT_APP_START_URL + "my-contests/live"} title="live">Live</Link>
                                    <Link className="upcoming" to={process.env.REACT_APP_START_URL + "my-contests/upcoming"} title="Upcoming">Upcoming</Link>
                                    <Link className="History" to={process.env.REACT_APP_START_URL + "my-contests/history"} title="History">History</Link>
                                </nav>
                            </div>
                        </div>
                        <h2>Live Events</h2>
                        <div className="sort-listing">
                            <div className="sort-events">
                                <label>Sort</label>
                                <div>
                                    <ul>
                                        <li onClick={this.sortLive} rel="0" className="active">Default-Entry Fee</li>
                                        <li onClick={this.sortLive} rel="1">Alphabetically</li>
                                        <li onClick={this.sortLive} rel="2">Contests Ending Soonest</li>
                                        <li onClick={this.sortLive} rel="3">Points away from first position</li>
                                        <li onClick={this.sortLive} rel="4">Points away from last paid position</li>
                                        <li onClick={this.sortLive} rel="5">Current winnings descending order</li>
                                        <li onClick={this.sortLive} rel="6">Current winnings ascending order</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {this.state.call ?
                            <div className="events-listing">
                                <ul>
                                    {this.state.liveList.length ? this.state.liveList.map(item => (
                                            <li key={item['userContestId']}>
                                                <Link to={process.env.REACT_APP_START_URL + "my-contests/live/" + item['contestId'] + "/" + item['userContestId']}
                                                      title="View Contest">
                                                    <div className="desktop">
                                                        <div className="top">
                                                            <img src={item['gameImage']} alt={item['name']}/>
                                                            <div className="headings">
                                                                <h3>
                                                                    {item['name']} {item['featured'] ? <span>Featured</span> : ''}
                                                                    {item['totalEntries'] === item['maxEntriesAllowed'] ? <span className="contest-filled">Contest Filled</span> : ''}
                                                                </h3>
                                                                <h4>
                                                                    Entry fees: {formatNumber(item['entryFee'])}
                                                                </h4>
                                                            </div>
                                                            <div className="info">
                                                                <h3>
                                                                    <span>Live Points</span>
                                                                    {item['livePoints']}
                                                                </h3>
                                                                <h3>
                                                                    <span>Current Ranking</span>
                                                                    {item['ranking']}/{item['totalParticipents']}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="bottom">
                                                            <div className="left">
                                                                <p>
                                                                    For 1st Position: {item['pointsAwayFromFirstPostion']} Points Needed <br/>
                                                                    For Last Win Position: {item['pointAwayFromLastPaidPosition']} Points Needed
                                                                </p>
                                                            </div>
                                                            <div className="right">

                                                                <button itle="View Contest">View Contest</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mobile">
                                                        <div className="top">
                                                            <img src={item['gameImage']} alt={item['name']}/>
                                                            <h3>
                                                                {item['name']} {item['featured'] ? <span>Featured</span> : ''}
                                                                {item['totalEntries'] === item['maxEntriesAllowed'] ? <span className="contest-filled">Contest Filled</span> : ''}
                                                            </h3>
                                                            <h4>
                                                                <span>Entry fees</span>
                                                                {formatNumber(item['entryFee'])}
                                                            </h4>
                                                            <h4>
                                                                <span>Prize Pool</span>
                                                                {formatNumber(item['guaranteedPrizeMoney'])}
                                                            </h4>
                                                        </div>
                                                        <div className="bottom">
                                                            <p>
                                                                Date: <span><Moment utc local format="dddd, MMM Do, YYYY">{item['startTime']}</Moment></span>
                                                            </p>
                                                            <p>
                                                                For 1st Position: {item['pointsAwayFromFirstPostion']} Points Needed
                                                            </p>
                                                            <p>
                                                                For Last Win Position: {item['pointAwayFromLastPaidPosition']} Points Needed
                                                            </p>
                                                            <p>
                                                                Live Points: <span>{item['livePoints']}</span>
                                                            </p>
                                                            <p>
                                                                Current Ranking:  <span>{item['ranking']}/{item['totalParticipents']}</span>
                                                            </p>
                                                            <button title="View Contest">View Contest</button>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        )) :
                                        <li className="no-results">
                                            <div></div>
                                            <h3>No Events Found</h3>
                                            {/* <span>Contests Usually Posted at 10 AM EST</span> */}
                                            <Link to={process.env.REACT_APP_START_URL + "contest-lobby"}>Go Back</Link>
                                        </li>
                                    }
                                </ul>
                                <button onClick={this.loadMore} className="load-more">Load More</button>
                            </div>
                            : ''}
                    </section>
                </div>
                <Footer/>
            </main>
        );
    }
}

function formatNumber(num) {
    return '$' + (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export default MyContestsLive;
