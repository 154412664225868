import React from 'react';
import {Link} from "react-router-dom";
import './forgot-password.scss';
import logo from "../../assets/images/loginlogo.png";
import $ from "jquery";

function validateEmail($email) {
    var emailReg = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return emailReg.test($email);
}


class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        $('.loader').addClass('show');
        event.preventDefault();

        const email = this.state.email;

        let error = false;
        $('input').removeClass('error').next('.error').hide().html('');

        if (!validateEmail(email)) {
            $('input[name="email"]').addClass('error').next('.error').show().html('Invalid email.');
            error = true;
        }

        if (email === '') {
            $('input[name="email"]').addClass('error').next('.error').show().html('Email is required.');
            error = true;
        }

        if (error) {
            $('.loader').removeClass('show');
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: JSON.stringify(this.state)
        };
        fetch(process.env.REACT_APP_SERVER_URL + 'send-reset-password-link', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    $('.forgot-password').hide();
                    $('.forgot-password-success').show();
                    $('.loader').removeClass('show');
                } else {
                    $('.server-error').show().html(data['message']);
                    $('.loader').removeClass('show');
                }
            });
    }


    render() {
        return (
            <main className="Login-page">
                <section className="left">
                    <Link to={process.env.REACT_APP_START_URL} title="Thrive Fantasy">
                        <img src={logo} alt="Thrive Fantasy"/>
                    </Link>
                </section>
                <section className="right">
                    <div>
                        <h1>Forgot Password</h1>
                        <div className="forgot-password">
                            <form onSubmit={this.handleSubmit}>
                                <ul>
                                    <li>
                                        <input className="field-input" type="text" name="email" placeholder="Email" onChange={this.handleInputChange}/>
                                        <span className="error"></span>
                                    </li>

                                    <li>
                                        <input type="submit" value="Forgot Password"/>
                                    </li>
                                </ul>
                            </form>
                            <p className="server-error">

                            </p>
                            <p>
                                Don't worry. It can happen to anyone.<br/> Just enter your email and we will help you.
                            </p>
                        </div>
                        <div className="forgot-password-success">
                            <p>
                                Reset Password Link has been sent<br/> to your registered email ID.<br/> Please click on it to change your password.
                            </p>
                        </div>
                        <p className="text-nav">
                            Already a member? <Link to={process.env.REACT_APP_START_URL +"login"} >Sign In</Link>
                        </p>
                    </div>
                </section>

            </main>
        );
    }
}

export default ForgotPassword;
