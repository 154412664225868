import React from 'react';
import './player-stats-popup.scss';
import { messages } from "../messages";
import $ from "jquery";
import Moment from 'react-moment';


class PlayerStatsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            player: {},
            playerSeasonStat: false,
            playerLast5Stats: false,

        };
        this.openPlayerStatsPopup = this.openPlayerStatsPopup.bind(this);
        this.closePlayerStatsPopup = this.closePlayerStatsPopup.bind(this);
    }

    openPlayerStatsPopup(player) {
        if (player) {
            let a = 1;
            this.setState({ player: player });
            $('.loader').addClass('show');
            let playerBody = {
                leagueType: player.leagueType,
                playerId: player.playerId,
            };

            const requestPlayerOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
                },
                body: JSON.stringify(playerBody)
            };


            fetch(process.env.REACT_APP_SERVER_URL + 'userContests/playerEventsStats', requestPlayerOptions)
                .then(response => response.json())
                .then(data => {
                    if (data['success']) {
                        this.setState({ playerLast5Stats: data['response'] });
                        $('.loader').removeClass('show');
                    } else {
                        this.setState({ playerLast5Stats: false });
                        messages('error', data['message']);
                        $('.loader').removeClass('show');
                    }
                });

            fetch(process.env.REACT_APP_SERVER_URL + 'userContests/playerSeasonStats', requestPlayerOptions)
                .then(response => response.json())
                .then(data => {
                    if (data['success']) {
                        this.setState({ playerSeasonStat: data['response'] });
                        $('.loader').removeClass('show');
                    } else {
                        this.setState({ playerSeasonStat: false });
                        messages('error', data['message']);
                        $('.loader').removeClass('show');
                    }
                });
            $('.loader').removeClass('show');
            $('.player-stats-popup').addClass('show');
        }
    }

    closePlayerStatsPopup() {
        $('.player-stats-popup').removeClass('show');
        this.setState({ player: {}, playerSeasonStat: false, playerLast5Stats: false });
    }

    render() {
        const player = this.state.player;
        const playerSeasonStat = this.state.playerSeasonStat;
        const playerLast5Stats = this.state.playerLast5Stats;
        return (
            <div className="player-stats-popup popup">
                <div className="inner">
                    <button className="close-player-popup close" onClick={this.closePlayerStatsPopup}>x</button>
                    <div className="head">
                        <img src={player.image} alt="" />
                        <h2>{player.firstName} {player.lastName}</h2>
                        <h3>#{player.uniform} {player.positionAbbreviation} {inchesIntoFeet(player.height)}, {player.weight} lbs<br /> {player.teamAbbr}</h3>
                    </div>
                    <div className="body">
                        {
                            playerSeasonStat ?
                                playerSeasonStat.playerStats ?
                                    <div className="stats">
                                        <h2>{playerSeasonStat.name} <span>Games Played: {playerSeasonStat.totalGame}</span></h2>
                                        <ul>
                                            {playerSeasonStat.playerStats.map(playerStat => (
                                                <li key={playerStat.paramKey}>
                                                    <span>{playerStat.paramKey}:</span>
                                                    <span>{playerStat.paramValue}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    : '' : ''
                        }
                        {
                            playerLast5Stats ?
                                playerLast5Stats.playerStatsWrapper ?
                                    <div className="last5games">
                                        <h2>{playerLast5Stats.name}</h2>
                                        <table>
                                            <tr>
                                                <th>Stats</th>
                                                {playerLast5Stats.playerStatsWrapper[0].stats.map(ps => (
                                                    <th key={ps.paramKey}>{ps.paramKey}</th>
                                                ))}
                                            </tr>
                                            {playerLast5Stats.playerStatsWrapper.map(playerStat => (
                                                <tr key={playerStat.opponentTeam}>
                                                    <td>{playerStat.team} vs {playerStat.opponentTeam} <br /><Moment utc local format="ddd, MMM Do, YYYY">{playerStat.eventDate}</Moment></td>
                                                    {
                                                        playerStat.stats.map(ps => (
                                                            <td key={ps.paramKey}>{ps?.paramValue}</td>
                                                        ))
                                                    }
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                    : '' : ''
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function inchesIntoFeet(str) {
    let value = parseFloat(str);
    let feet = Math.floor(value / 30.48);
    let inches = Math.round((value / 2.54) - (feet * 12));
    return feet + "'" + inches + '"';
}

export default PlayerStatsPopup;
