import React from "react";
import { withRouter } from "react-router";
import "./contest-traditional-picks.scss";
import $ from "jquery";
import Moment from "react-moment";
import PlayerStatsPopup from "../player-stats-popup/player-stats-popup";

import { messages } from "../messages";
import { useJsonToCsv } from "react-json-csv";
import { Mixpanel } from "../mixpanel/Mixpanel";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';


const defaultPlayerPic = require("../../assets/images/defaultPic.png");

const axios = require('axios')

const beltImage = require('../../assets/images/belt_render.jpg')


let picks = [];

let picksBody = {
  contestId: 0,
  currentPage: 1,
  currentSize: 40,
  userContestId: 0,
};

const ticketRequestOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-User-Id": window.localStorage.getItem("userData")
        ? JSON.parse(window.localStorage.getItem("userData")).userDto.userid
        : false,
    Token: window.localStorage.getItem("userData")
        ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
        : false,
  },
};

const requestOptions = {
  method: "post",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Token: window.localStorage.getItem("userData")
        ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
        : false,
  },
  body: JSON.stringify(picksBody),
};

const getOptions = {
  method: "get",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Token: window.localStorage.getItem("userData")
        ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
        : false,
  },
};

class ContestTraditionalPicks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitDisabled: true,
      ice: false,
      edit: false,
      pickObj: {},
      // picksArray: [],
      icePlayerArray: [],
      icePrimaryPicked: false,
      picked: 0,
      minimumPick: 0,
      icePick: 0,
      maximumPoint: 0,
      picks: [],
      tooltip: "",
      payData: {},
      contestId: this.props.match.params.contestId,
      userContestId: this.props.match.params.userContestId,
      numberOfEntries: 1,
      importPicks: {},
      ticket: null,
      hasEligibleTicket: false,
      numExportedLineups: [],
      csvPickData: null,

      exportPickData: null,
      numberOfExportTemplates: 1
    };
    this.fetchNewPicksData = this.fetchNewPicksData.bind(this);
    this.getImportPicksData = this.getImportPicksData.bind(this);
    this.checkForSelection = this.checkForSelection.bind(this);
    this.addPicks = this.addPicks.bind(this);
    this.pointsSelect = this.pointsSelect.bind(this);
    this.highlight = this.highlight.bind(this);
    this.pushArray = this.pushArray.bind(this);
    this.popArray = this.popArray.bind(this);
    this.checkForPoints = this.checkForPoints.bind(this);
    this.clearAllSelection = this.clearAllSelection.bind(this);
    this.checkLocation = this.checkLocation.bind(this);
    this.checkReasonCodes = this.checkReasonCodes.bind(this);
    this.submitPicks = this.submitPicks.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.pay = this.pay.bind(this);
    this.checkForPicksObj = this.checkForPicksObj.bind(this);
    this.fetchImportPicksData = this.fetchImportPicksData.bind(this);
    this.openImportPicks = this.openImportPicks.bind(this);
    this.applyImportPicks = this.applyImportPicks.bind(this);
    this.inviteFriend = this.inviteFriend.bind(this);
    this.getUserContestTicket = this.getUserContestTicket.bind(this);
    this.ticketSubmissionSection = this.ticketSubmissionSection.bind(this);
    this.enterWithContestTicket = this.enterWithContestTicket.bind(this);
    this.closeLoaderModal = this.closeLoaderModal.bind(this);
    this.exportCsvSubmit = this.exportCsvSubmit.bind(this)
    this.submitCsvPicks = this.submitCsvPicks.bind(this)

    this.playerStats = React.createRef();
  }

  // Select picks
  pointsSelect(id, type) {
    console.log("ID HERE: " + id);
    if (type === "over") {
      Mixpanel.track("web:user_selected_over", {
        "Prop Id": id,
      });
    } else {
      Mixpanel.track("web:user_selected_under", {
        "Prop Id": id,
      });
    }
    this.setState({ submitDisabled: true });
    if (this.state.ice && !this.state.pickObj[id].selected) {
      messages("error", "You have exceeded maximum props for this contest");
    } else {
      this.highlight(id, type);
      this.checkForPoints(id);
    }
  }

  highlight(id, type) {
    let pickObj = this.state.pickObj;
    if (type === "over") {
      pickObj[id].isOverSelected = !pickObj[id].isOverSelected;
      pickObj[id].isUnderSelected = false;
      pickObj[id].isOverSelected
          ? (pickObj[id].selectedPoints = pickObj[id].overPoints)
          : (pickObj[id].selectedPoints = 0);
    } else if (type === "under") {
      pickObj[id].isUnderSelected = !pickObj[id].isUnderSelected;
      pickObj[id].isOverSelected = false;
      if (pickObj[id].isUnderSelected) {
        pickObj[id].selectedPoints = pickObj[id].underPoints;
      } else {
        pickObj[id].selectedPoints = 0;
      }
    }
    if (pickObj[id].isUnderSelected || pickObj[id].isOverSelected) {
      pickObj[id].selected = true;
      this.pushArray(id);
    } else {
      pickObj[id].selected = false;
      pickObj[id].icePick = false;
      this.popArray(id);
    }
    this.setState({ pickObj: pickObj });
  }

  pushArray(id) {
    let icePlayerArray = this.state.icePlayerArray;
    let pickObj = this.state.pickObj;
    if (icePlayerArray.indexOf(id) === -1) {
      icePlayerArray.push(id);
      if (
          this.state.picked >= this.state.minimumPick &&
          this.state.icePick !== 2
      ) {
        pickObj[id].icePick = true;
      } else {
        pickObj[id].icePick = false;
      }
    }
    this.setState({ icePlayerArray: icePlayerArray, pickObj: pickObj });
  }

  popArray(id) {
    let icePlayerArray = this.state.icePlayerArray;
    let pickObj = this.state.pickObj;
    pickObj[id].icePick = false;
    pickObj[id].icePrimary = false;
    icePlayerArray.splice(icePlayerArray.indexOf(id), 1);
    if (icePlayerArray.length === 0) {
      this.clearAllSelection();
    }
    this.setState({ icePlayerArray: icePlayerArray, pickObj: pickObj });
  }

  checkForPoints(id) {
    let icePlayerArray = this.state.icePlayerArray;
    let pickObj = this.state.pickObj;
    let icePick = 0;
    let picked = 0;
    let maximumPoint = 0;
    let icePrimaryPicked = this.state.icePrimaryPicked;
    let ice = false;
    let submitDisabled = this.state.submitDisabled;
    let minimumPick = this.state.minimumPick;
    for (let i = 0; i < icePlayerArray.length; i++) {
      if (pickObj[icePlayerArray[i]].icePick === true) {
        if (pickObj[icePlayerArray[i]].icePick === true) {
          icePick++;
          if (icePick === 1) {
            if (pickObj[icePlayerArray[i]].iceSecondary) {
              pickObj[icePlayerArray[i]].icePrimary = false;
            } else {
              pickObj[icePlayerArray[i]].icePrimary = true;
              icePrimaryPicked = true;
            }
          } else {
            if (this.edit === "true") {
              if (icePrimaryPicked) {
                pickObj[icePlayerArray[i]].icePrimary = false;
              } else {
                pickObj[icePlayerArray[i]].icePrimary = true;
              }
            } else {
              pickObj[icePlayerArray[i]].icePrimary = false;
            }
          }
        } else {
          pickObj[icePlayerArray[i]].icePrimary = false;
          maximumPoint += pickObj[icePlayerArray[i]].selectedPoints;
          picked++;
        }
      } else {
        pickObj[icePlayerArray[i]].icePick = false;
        maximumPoint += pickObj[icePlayerArray[i]].selectedPoints;
        picked++;
      }
    }
    if (icePick === 2 && picked === minimumPick) {
      ice = true;
    } else {
      ice = false;
    }
    icePick === 0 && picked === 0
        ? (submitDisabled = true)
        : (submitDisabled = false);
    if (icePick === 0) {
      for (let i in pickObj) {
        if (pickObj.hasOwnProperty(i)) {
          pickObj[i].iceSecondary = false;
          pickObj[i].icePrimary = false;
        }
      }
    }
    this.setState({
      icePlayerArray: icePlayerArray,
      pickObj: pickObj,
      icePick: icePick,
      picked: picked,
      maximumPoint: maximumPoint,
      icePrimaryPicked: icePrimaryPicked,
      ice: ice,
      submitDisabled: submitDisabled,
      minimumPick: minimumPick,
    });
  }

  /*clear all selected picks*/
  clearAllSelection() {
    let pickObj = this.state.pickObj;
    for (let i in pickObj) {
      if (pickObj.hasOwnProperty(i)) {
        pickObj[i].selected = false;
        pickObj[i].icePick = false;
        pickObj[i].selectedPoints = 0;
        pickObj[i].isUnderSelected = false;
        pickObj[i].isOverSelected = false;
        pickObj[i].iceSecondary = false;
        pickObj[i].icePrimary = false;
      }
    }

    this.setState({
      icePlayerArray: [],
      pickObj: pickObj,
      icePick: 0,
      picked: 0,
      maximumPoint: 0,
      icePrimaryPicked: false,
      ice: false,
      submitDisabled: true,
    });

    Mixpanel.track("web:user_cleared_selection");
  }

  // Check if contest league is = to any ticket leagues.
  // Check if the ticket $ value is >= contest entry price.
  ticketSubmissionSection() {
    if (this.state.hasEligibleTicket == false) {
      return "";
    } else {
      return (
          <span className="ticketSubmissionSection">
          <p> or </p>
          <div className="ticket">
            <h2>Redeem Ticket for Free Entry</h2>
            <button
                onClick={this.enterWithContestTicket}
                className="use-ticket"
                title="Use Ticket"
            >
              Redeem Ticket
            </button>
            <span>(one time use)</span>{" "}
          </div>
        </span>
      );
    }
  }

  /*get picks and format array*/
  fetchNewPicksData(requestOptions, propsCall) {
    $(".loader").addClass("show");
    picks = [];
    let pickObj = this.state.pickObj;
    let userPropPick;
    // let url = process.env.REACT_APP_LAMBDA_COMBO_URL;
    let url = process.env.REACT_APP_SERVER_URL;
    this.setPropData(url, propsCall, requestOptions, userPropPick, pickObj);
  }

  getImportPicksData(requestOptions, propsCall) {

    $(".loader").addClass("show");
    picks = [];
    let pickObj = this.state.pickObj;
    let userPropPick;
    
    // let url = process.env.REACT_APP_LAMBDA_COMBO_URL;
    let url = process.env.REACT_APP_SERVER_URL;
    // let url = process.env.REACT_APP_SERVER_URL + "/"


    this.setPropData(url, propsCall, requestOptions, userPropPick, pickObj, true
    );
  }

  setPropData(url, propsCall, requestOptions, userPropPick, pickObj, importPicks = false) {
    picks = [];
    fetch(url + propsCall, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data["success"]) {
            data["response"]["data"].forEach((singleData) => {
              if (data["response"]["exportPicks"] !== null) {
                this.setState({
                  exportPicksData: data["response"]["exportPicks"],
                });
              }

              let contestProp = singleData["contestProp"];

              if (importPicks === true) {
                userPropPick = singleData["userPropPick"];
              }
              // let imageExists = this.imageExists(contestProp['player1'].image);

              picks.push({ //marker1
                contestId: contestProp["contestId"],
                overPoints: contestProp["overPoints"],
                minPropsToPick: contestProp["minPropsToPick"],
                player1: {
                  firstName: contestProp["player1"].firstName,
                  height: contestProp["player1"].height,
                  // image: contestProp['player1'].leagueType === 'HOCKEY' ? (imageExists ? contestProp['player1'].image : defaultPlayerPic) : contestProp['player1'].image,
                  image: contestProp["player1"].image,
                  lastName: contestProp["player1"].lastName,
                  leagueType: contestProp["player1"].leagueType,
                  playerId: contestProp["player1"].playerId,
                  positionAbbreviation:
                  contestProp["player1"].positionAbbreviation,
                  uniform: contestProp["player1"].uniform,
                  weight: contestProp["player1"].weight,
                  id: contestProp["player1"].id,
                  teamAbbr: contestProp["player1"].teamAbbr,
                  propParameters: contestProp["player1"].propParameters,
                },
                player1TeamId: contestProp.player1TeamId,
                propId: contestProp.propId,
                propValue: contestProp.propValue,
                startTime: contestProp.startTime,
                startDate: contestProp.startTime,
                statEventId: contestProp.statEventId,
                team1Name: contestProp.team1Name,
                team1Abbr: contestProp.team1Abbr,
                team2Abbr: contestProp.team2Abbr,
                underPoints: contestProp.underPoints,
                venueState: contestProp.venueState,
                team1locationType: contestProp.team1locationType,
                golfRound: contestProp.golfRound,
                golfTeeTime: contestProp.golfTeeTime,
                rangeLower: contestProp.rangeLower,
                rangeUpper: contestProp.rangeUpper,
                selected: null,
              });
              pickObj[contestProp.propId] = {} || pickObj[contestProp.propId];
              pickObj[contestProp.propId] = {
                selected: false,
                icePick: false,
                overPoints: contestProp.overPoints,
                underPoints: contestProp.underPoints,
                selectedPoints: 0,
                isUnderSelected: false,
                isOverSelected: false,
                icePrimary: false,
                propDisabled: contestProp.propDisabled,
                playerId: contestProp.player1.id,
              };
              this.setState({
                pickObj: pickObj,
              });
              if (importPicks === true) {
                this.checkForSelection(
                    contestProp.propId,
                    userPropPick,
                    contestProp.player1.id
                );
              }
            });
            this.setState({ picks: picks, minimumPick: picks[0].minPropsToPick });
            this.addPicks();
            $(".loader").removeClass("show");
          } else {
            messages("error", data["message"]);
            $(".loader").removeClass("show");
          }
        });

    fetch(
        process.env.REACT_APP_SERVER_URL +
        "adminContest/get-ice-prop-description",
        getOptions
    )
        .then((response) => response.json())
        .then((data) => {
          if (data["success"]) {
            this.setState({ tooltip: data["response"]["description"] });
          }
        });
  }

  imageExists(source) {
    let retVal = false;
    const img = new Image();
    img.src = source;

    if (img.complete) {
      retVal = true;
    } else {
      img.onload = () => {
        retVal = true;
      };

      img.onerror = () => {
        retVal = false;
      };
    }

    return retVal;
  }

  // to check the selections params id & userPropPick
  checkForSelection(id, userPropPick, playerId) {
    let pickObj = this.state.pickObj;
    let icePlayerArray = this.state.icePlayerArray;

    if (userPropPick !== null) {
      pickObj[id].icePick = userPropPick.isICE;
      pickObj[id].icePrimary = userPropPick.icePrimary;
      pickObj[id].isOverSelected = userPropPick.isOver;
      pickObj[id].isUnderSelected = !userPropPick.isOver;
      pickObj[id].selected = true;
      pickObj[id].selectedPoints = userPropPick.userPoints;
      pickObj[id].iceSecondary = !userPropPick.icePrimary;
      pickObj[id].playerId = playerId;
      icePlayerArray.push(id);

      this.setState({ pickObj: pickObj, icePlayerArray: icePlayerArray });
    }
  }

  /* function for calculations of pick */
  addPicks() {
    let pickObj = this.state.pickObj;
    let ice = this.state.ice;
    let icePick = this.state.icePick;
    let picked = this.state.picked;
    let maximumPoint = this.state.maximumPoint;
    for (let id in pickObj) {
      if (pickObj.hasOwnProperty(id)) {
        if (pickObj[id].selected) {
          if (pickObj[id].icePick) {
            icePick++;
          } else {
            picked++;
            maximumPoint += pickObj[id].selectedPoints;
          }
        }
      }
    }
    if (icePick === 2 && picked === this.state.minimumPick) {
      ice = true;
    } else {
      ice = false;
    }
    this.setState({
      pickObj: pickObj,
      ice: ice,
      icePick: icePick,
      picked: picked,
      maximumPoint: maximumPoint,
    });
  }

  componentDidMount() {
    // console.log(this.props.contest)
    picksBody["contestId"] = this.props.match.params.contestId;
    picksBody["userContestId"] = this.props.match.params.userContestId;
    requestOptions["body"] = JSON.stringify(picksBody);
    this.fetchNewPicksData(requestOptions, "contests/availableProps");
    this.checkEligibleTicket(); //nelson
  }

  checkLocation() {
    $(".loader").addClass("show");

    Mixpanel.track("web:user_clicked_submit_props");

    navigator.geolocation.getCurrentPosition(
        (position) => {
          let locationCheckBody = {
            Latitude: position.coords.latitude,
            Longitude: position.coords.longitude,
          };

          const locationCheckOptions = {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Token: window.localStorage.getItem("userData")
                  ? JSON.parse(window.localStorage.getItem("userData"))[
                      "accessToken"
                      ]
                  : false,
            },
            body: JSON.stringify(locationCheckBody),
          };

          fetch(
              process.env.REACT_APP_SERVER_URL + "users/customer-identity-service",
              locationCheckOptions
          )
              .then((response) => response.json())
              .then((data) => {
                if (data["success"]) {
                  this.checkReasonCodes(
                      data["response"]["responseMessage"],
                      data["response"]["locationDetail"]["ReasonCodes"]
                  );
                } else {
                  $(".loader").removeClass("show");
                  messages("error", data["message"]);
                }
              });
        },
        (error) => {
          $(".loader").removeClass("show");
          messages("error", "Permission Denied: Unable to Determine Location.");
        },
        { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
    );
  }

  checkReasonCodes(responseMessage, reasonCodes) {
    if (responseMessage === "No error.") {
      if (
          reasonCodes.indexOf("LL-GEO-US-NE") > -1 &&
          reasonCodes.indexOf("ID-UA-19") > -1
      ) {
        $(".loader").removeClass("show");
        messages(
            "error",
            "Permission Denied Because You Are In An Illegal State."
        );
        return false;
      }
      if (
          reasonCodes.indexOf("LL-GEO-US-MA") > -1 &&
          reasonCodes.indexOf("ID-UA-21") > -1
      ) {
        $(".loader").removeClass("show");
        messages(
            "error",
            "Permission Denied Because You Are In An Illegal State."
        );
        return false;
      }

      for (var i = 0; i < reasonCodes.length; i++) {
        if (
            reasonCodes[i].indexOf("LL-BLOCK") > -1 ||
            reasonCodes[i].indexOf("LL-FAIL") > -1 ||
            reasonCodes[i].indexOf("ID-UNKN") > -1 ||
            reasonCodes[i].indexOf("ID-FAIL") > -1 ||
            reasonCodes[i].indexOf("ID-UA18") > -1 ||
            reasonCodes[i].indexOf("LL-UNKN") > -1
        ) {
          $(".loader").removeClass("show");
          messages(
              "error",
              "Permission Denied Because You Are In An Illegal State."
          );
          return false;
        }
      }

      Mixpanel.track("web:location_verified");

      this.submitPicks();
    }
  }

  /*Validate picks and proceed to pay */
  submitPicks() {
    let payData = {
      numberOfPicks: this.state.picked,
      numberOfEntries: 1,
      entryFee: this.props.contest.entryFee,
      maxEntriesPerUser: this.props.contest.maxEntriesPerUser,
      maximumPoints: this.state.maximumPoint,
    };
    this.setState({ payData: payData });

    if (this.state.picked < this.state.minimumPick) {
      $(".loader").removeClass("show");
      messages(
          "error",
          "Please select " +
          (this.state.minimumPick - this.state.picked) +
          " more props"
      );
    } else if (this.state.icePick < 2) {
      if (this.state.icePick < 1) {
        $(".loader").removeClass("show");
        messages(
            "error",
            "Please select " + (2 - this.state.icePick) + " ICE props"
        );
      } else {
        $(".loader").removeClass("show");
        messages(
            "error",
            "Please select " + (2 - this.state.icePick) + " more ICE props"
        );
      }
    } else {
      if (this.state.userContestId) {
        this.pay();
      } else {
        $(".loader").removeClass("show");
        $(".pay-modal").addClass("show");
        $(".number-of-entries").val("1");
      }
    }
  }

  checkEligibleTicket() {
    const eligibleTicketData = {
      contestId: this.state.contestId,
    };
    const eligibleTicketOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Token: window.localStorage.getItem("userData")
            ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
            : false,
      },
      body: JSON.stringify(eligibleTicketData),
    };
    fetch(
        process.env.REACT_APP_SERVER_URL + "tickets/getEligibleTicketForContest",
        eligibleTicketOptions
    )
        .then((response) => response.json())
        .then((data) => {
          if (data["success"]) {
            this.setState({ ticket: data["response"] });
            if (data["response"] != null) {
              this.setState({ hasEligibleTicket: true });
            }
          } else {
            this.setState({ ticket: null });
          }
        })
        .catch((error) => {
          messages("error", error);
        });
  }

  getUserContestTicket() {
    fetch(
        process.env.REACT_APP_SERVER_URL + "tickets/getUsersTicket",
        ticketRequestOptions
    )
        .then((response) => response.json())
        .then((data) => {
          if (data["response"]["success"]) {
            this.setState({ ticket: data["response"]["response"] });
          } else {
            this.setState({ ticket: null });
          }
        })
        .catch((error) => {
          messages("error", error);
        });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    value = value.replace(/[^0-9]+/g, "");
    if (value / 1 >= this.state.payData.maxEntriesPerUser) {
      value = this.state.payData.maxEntriesPerUser;
    }

    $(".number-of-entries").val(value);

    this.setState({
      [name]: value,
    });
  }

  /*to get picks array */
  checkForPicksObj() {
    let pickObj = this.state.pickObj;
    let picksArray = [];
    for (let i in pickObj) {
      if (pickObj.hasOwnProperty(i)) {
        if (pickObj[i].selected) {
          picksArray.push({
            adminContestPropsId: i,
            ice: pickObj[i].icePick,
            over: pickObj[i].isOverSelected,
            icePrimary: pickObj[i].icePrimary,
            playerId: pickObj[i].playerId,
          });
        }
      }
    }
    return picksArray;
  }

  // checkForPicksObjOLD() {
  //     let pickObj = this.state.pickObj;
  //     let picksArray = this.state.picksArray;
  //     for (let i in pickObj) {
  //         if (pickObj.hasOwnProperty(i)) {
  //             if (pickObj[i].selected) {
  //                 picksArray.push({
  //                     adminContestPropsId: i,
  //                     ice: pickObj[i].icePick,
  //                     over: pickObj[i].isOverSelected,
  //                     icePrimary: pickObj[i].icePrimary,
  //                     playerId: pickObj[i].playerId
  //                 })
  //             }
  //         }
  //     }
  //     this.setState({picksArray: picksArray});
  // }

  pay() {
    $(".loader").addClass("show");

    Mixpanel.track("web:user_clicked_pay_now");

    if (this.state.numberOfEntries > this.state.payData.maxEntriesPerUser) {
      $(".loader").removeClass("show");
      messages(
          "error",
          "Maximum entries allowed for this contest are " + this.maxEntriesPerUser
      );
    } else if (
        this.state.numberOfEntries === 0 ||
        this.state.numberOfEntries === null
    ) {
      $(".loader").removeClass("show");
      messages(
          "error",
          "Please provide at least 1 entry " + this.maxEntriesPerUser
      );
    } else {
      let propPickList = this.checkForPicksObj();

      let requestPaymentObject = {};
      let state = 0;

      if (this.state.userContestId) {
        requestPaymentObject = {
          adminContestsId: this.state.contestId,
          propPickList: propPickList,
          maxPoints: this.state.maximumPoints,
          userContestId: this.state.userContestId,
        };
        state = 2;
      } else {
        requestPaymentObject = {
          adminContestsId: this.state.contestId,
          entryCount: this.state.numberOfEntries,
          amountPaid: this.state.payData.entryFee * this.state.numberOfEntries,
          propPickList: propPickList,
          maxPoints: this.state.maximumPoints,
          userContestId: this.state.userContestId,
        };
        state = 0;
      }

      const getPaymentOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Token: window.localStorage.getItem("userData")
              ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
              : false,
        },
        body: JSON.stringify(requestPaymentObject),
      };

        // fetch(`${process.env.REACT_APP_LAMBDA_COMBO_URL}userContests/enterContest/` + state, getPaymentOptions)
        fetch(`${process.env.REACT_APP_SERVER_URL}userContests/enterContest/` + state, getPaymentOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data["success"]) {
              //nelsonga - contest
              if (window.dataLayer) {
                window.dataLayer.push({
                  'event': 'Contest_Entry_Success'
                });
              }

              try {
                window.contestEntryScript(document, "script");
              } catch (error) {
                console.error(error);
              }
              $(".loader").removeClass("show");
              messages("success", data["message"]);
              if (this.state.userContestId) {
                this.props.history.push(
                    process.env.REACT_APP_START_URL + "my-contests/upcoming"
                );
              } else {
                this.props.history.push(
                    process.env.REACT_APP_START_URL + "contest-lobby"
                );
              }
            } else {
              $(".loader").removeClass("show");
              messages("error", data["message"]);
            }
          });

      // const getWalletBalanceOptions = {
      //     method: 'GET',
      //     headers: {
      //         'Content-Type': 'application/json',
      //         'Access-Control-Allow-Origin': '*',
      //         'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
      //     }
      // };

      // fetch(process.env.REACT_APP_SERVER_URL + 'users/get-wallet-balance', getWalletBalanceOptions)
      //     .then(response => response.json())
      //     .then(data => {
      //         if (data['success']) {
      //             if (data['response']['walletBalance'] < this.state.payData.entryFee) {
      //                 $('.loader').removeClass('show');
      //                 messages('error', 'You have insufficienttttt balance to withdraw your balance');
      //             } else {
      //                 const getPaymentOptions = {
      //                     method: 'post',
      //                     headers: {
      //                         'Content-Type': 'application/json',
      //                         'Access-Control-Allow-Origin': '*',
      //                         'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
      //                     },
      //                     body: JSON.stringify(requestPaymentObject)
      //                 };

      //                 fetch(process.env.REACT_APP_SERVER_URL + 'contests/enter/' + state, getPaymentOptions)
      //                     .then(response => response.json())
      //                     .then(data => {
      //                         if (data['success']) {
      //                             try {
      //                                 window.contestEntryScript(document, "script")
      //                             } catch (error) {
      //                                 console.error(error);
      //                             }
      //                             $('.loader').removeClass('show');
      //                             messages('success', data['message']);
      //                             if (this.state.userContestId) {
      //                                 this.props.history.push(process.env.REACT_APP_START_URL + 'my-contests/upcoming');
      //                             } else {
      //                                 this.props.history.push(process.env.REACT_APP_START_URL + 'contest-lobby');
      //                             }
      //                         } else {
      //                             $('.loader').removeClass('show');
      //                             messages('error', data['message']);
      //                         }
      //                     });
      //             }
      //         } else {
      //             $('.loader').removeClass('show');
      //             messages('error', data['message']);
      //         }
      //     });
    }
  }

  /*Validate picks and use ticket */
  enterWithContestTicket() {
    $(".loader").addClass("show");
    if (this.state.numberOfEntries > this.state.payData.maxEntriesPerUser) {
      messages(
          "error",
          "Maximum entries allowed for this contest are " + this.maxEntriesPerUser
      );
      $(".loader").removeClass("show");
    } else if (
        this.state.numberOfEntries === 0 ||
        this.state.numberOfEntries === null
    ) {
      messages(
          "error",
          "Please provide at least 1 entry " + this.maxEntriesPerUser
      );
      $(".loader").removeClass("show");
    } else {
      let propPickList = this.checkForPicksObj();

      let requestPaymentObject = {};
      let state = 0;

      if (this.state.userContestId) {
        requestPaymentObject = {
          userContestTicketId: this.state.ticket.id,
          adminContestsId: this.state.contestId,
          propPickList: propPickList,
          maxPoints: this.state.maximumPoints,
          userContestId: this.state.userContestId,
        };
        state = 2;
      } else {
        requestPaymentObject = {
          userContestTicketId: this.state.ticket.id,
          adminContestsId: this.state.contestId,
          entryCount: this.state.numberOfEntries,
          amountPaid: this.state.payData.entryFee * this.state.numberOfEntries,
          propPickList: propPickList,
          maxPoints: this.state.maximumPoints,
          userContestId: this.state.userContestId,
        };
        state = 0;
      }

      const getPaymentOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Token: window.localStorage.getItem("userData")
              ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
              : false,
        },
        body: JSON.stringify(requestPaymentObject),
      };


      // fetch(`${process.env.REACT_APP_LAMBDA_COMBO_URL}userContests/enterContestWithTicket/` + state, getPaymentOptions)
      fetch(`${process.env.REACT_APP_SERVER_URL}userContests/enterContestWithTicket/` + state, getPaymentOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data["success"]) {
              //nelsonga - contest
              if(window.dataLayer) {
                window.dataLayer.push({
                  'event': 'Contest_Entry_Success'
                });
              }

              try {
                window.contestEntryScript(document, "script");
              } catch (error) {
                console.error(error);
              }
              messages("success", data["message"]);
              if (this.state.userContestId) {
                this.props.history.push(
                    process.env.REACT_APP_START_URL + "my-contests/upcoming"
                );
              } else {
                this.props.history.push(
                    process.env.REACT_APP_START_URL + "contest-lobby"
                );
              }
            } else {
              messages("error", data["message"]);
              $(".loader").removeClass("show");
            }
            $(".loader").removeClass("show");
          });
    }
  }

  /*Close Pay popup */
  closePayModal() {
    $(".pay-modal").removeClass("show");
  }

  clickClosePayModal() {
    $(".pay-modal").removeClass("show");

    Mixpanel.track("web:user_closed_pay_modal");
  }

  /*to get Import Picks Data */
  fetchImportPicksData() {
    $(".loader").addClass("show");

    Mixpanel.track("web:user_clicked_import_picks");

    const importPicksBody = {
      contestId: this.state.contestId,
      userContestId: this.state.userContestId,
    };

    const getImportPicksOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Token: window.localStorage.getItem("userData")
            ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
            : false,
      },
      body: JSON.stringify(importPicksBody),
    };


    // fetch(`${process.env.REACT_APP_LAMBDA_COMBO_URL}import-export-picks/importPicks`, getImportPicksOptions)
    fetch(`${process.env.REACT_APP_SERVER_URL}import-export-picks/importPicks`, getImportPicksOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data["success"]) {
            console.log(data["response"]["data"]);
            this.setState({ importPicks: data["response"]["data"] });
          } else {
            messages("error", data["message"]);
          }
          $(".loader").removeClass("show");
        });
  }

  /*open import picks popup and run function to get Import Picks Data */
  openImportPicks() {
    this.fetchImportPicksData();
    $(".import-picks-modal").addClass("show");
  }

  /*Close import popup */
  closeImportPicksModal() {
    $(".import-picks-modal").removeClass("show");
  }

  /*Import selected contest*/
  applyImportPicks() {
    const userContestId = $('input[name="import-pick"]:checked').val();
    const userImportedContest = $('input[name="import-pick"]:checked').attr(
        "rel"
    );

    if (userContestId && userImportedContest) {
      picksBody["contestId"] = this.state.contestId;
      picksBody["userContestId"] = userContestId;
      picksBody["userImportedContest"] = userImportedContest;
      requestOptions["body"] = JSON.stringify(picksBody);
      this.clearAllSelection();

      this.getImportPicksData(
          requestOptions,
          "userContests/userImportedContests",
          true
      );
      this.closeImportPicksModal();
    }
  }

  /*Open Invite friend popup */
  openInviteFriendModal() {
    $(".invite-friend-popup").addClass("show");

    Mixpanel.track("web:user_clicked_invite_friends");
  }

  /*Close Invite friend popup */
  closeInviteFriendModal() {
    $(".invite-friend-popup").removeClass("show");

    Mixpanel.track("web:invite_friends_modal_closed");
  }

  inviteFriend() {
    $(".invite-friend-popup").find(".error").hide();
    const email = $(".friend-email").val();

    if (!validateEmail(email)) {
      $(".invite-friend-popup").find(".error").show();
      return;
    }

    $(".loader").addClass("show");
    const inviteFriendBody = {
      inviteeEmail: email,
      contestId: this.state.contestId,
    };

    const getInviteFriendOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Token: window.localStorage.getItem("userData")
            ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
            : false,
      },
      body: JSON.stringify(inviteFriendBody),
    };
    fetch(
        process.env.REACT_APP_SERVER_URL + "invitee/user-invitee",
        getInviteFriendOptions
    )
        .then((response) => response.json())
        .then((data) => {
          if (data["success"]) {
            messages("success", data["message"]);
            this.closeInviteFriendModal();
          } else {
            messages("error", data["message"]);
          }
          $(".loader").removeClass("show");
        });
  }

  exportPicks() {
    $(".loader").addClass("show");
    $(".export-picks-modal").addClass("show");

    Mixpanel.track("web:user_clicked_export_picks");


    $(".loader").removeClass("show");
  }

  async submitCsvPicks() {
    let config =  {
      headers: {Token: window.localStorage.getItem("userData")
        ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
        : false}
    }
    let body = this.state.csvPickData;

    $(".loader").addClass("show");

    // await axios.post(`${process.env.REACT_APP_LAMBDA_COMBO_URL}userContests/enterContestWithCsv`, body, config).then(res => {
    await axios.post(`${process.env.REACT_APP_SERVER_URL}userContests/enterContestWithCsv`, body, config).then(res => {
    //     console.log(res);
      $(".loader").removeClass("show")
      if(res.data.statusCode !== 200) {
        messages("error", res.data.message);
      }
      else {
        //nelsonga - contest
        if (window.dataLayer) {
          window.dataLayer.push({
            'event': 'Contest_Entry_Success'
          });
        }

        this.props.history.push(
            process.env.REACT_APP_START_URL + "my-contests/upcoming"
        );
      }
    })
  }

  exportCsvSubmit() {
      $(".loader").addClass("show");

      Mixpanel.track("web:user_clicked_export_picks");

      const { saveAsCsv } = useJsonToCsv();

      if (this.state.exportPicksData !== null) {

        let props = this.state.exportPicksData["model"]["csvData"];
        let numberOfPropLinesRequested = this.state.numberOfExportTemplates

        let  data = [
          {
            "propId": '',
            "playerId": '',
            "playerName": '',
            "propValue": '',
            "propParameter": '',
            "overPoints": '',
            "underPoints": '',
            "overUnder": '',
            "icePick": '',
            "empty1": '',
            "empty2": '',
            // "numPicks": 'numPicks',
            // "summary": 'summary'
          }
        ]

        // data.push(generateRow(data[0], ['info','infostuff']))
        // data.push(generateRow(data[0], [this.props.contest.name]))
        // data.push(generateRow(data[0], ['tutorial']))
        // data.push(generateRow(data[0], []))

        // console.log(this.props.contest);

        let contestName = this.props.contest.name
        console.log(contestName.includes(','));
        if(contestName.includes(',')) {
          contestName = contestName.replaceAll(',', '')
        }
        // console.log(contestName);

        for (let i = 0; i < numberOfPropLinesRequested; i++) {
          data.push(generateRow(data[0], ['Prop Id', 'Player Id', 'Player Name', 'Prop Value', 'Prop Parameter', 'Over Points', 'Under Points', 'Over/Under', 'ICE 1/ICE 2']))
          props.forEach(prop => {
            data.push(generateRow(data[0], [prop['propId'], prop['playerId'], prop['playerName'], prop['propValue'], prop['propParameter'], prop['overPoints'], prop['underPoints']]))
          });
          data.push(generateRow(data[0], []))
        }
        const fields = toObject(data, [contestName, "Pick " + this.props.contest.minPropsToPick + " Props and 2 ICE PICKS",'Ensure there is a blank line between entries','','','','','Enter O or U',"Enter 'ICE 1' or 'ICE 2'"]);
        const filename = "Picks";

        saveAsCsv({ fields, data, filename });
      } else {
        messages("error", "No Data Available!");
      }
      $(".loader").removeClass("show");

  }

  triggerPlayerStatsPopup(player) {
    this.playerStats.current.openPlayerStatsPopup(player);
  }

  closeLoaderModal() {
    $(".loader").removeClass("show");
  }

  showExportPicksModal() {
    $('.export-picks-modal').addClass('show');
  }

  closeExportPicksModal() {
    $(".export-picks-modal").removeClass("show");
  }

  showExportPicksConfirmationModal() {
    $('.export-picks-confirmation-modal').addClass('show');
  }

  closeExportPicksConfirmationModal() {
    $(".export-picks-confirmation-modal").removeClass("show");
  }

  handleExportModalChange = event => {
    const target = event.target;
    let value = target.value;

    value = value.replace(/[^0-9]+/g, "");
    if (value / 1 >= this.props.details.maxEntriesPerUser) {
      value = this.props.details.maxEntriesPerUser;
    }
    $(".export-number-pick").val(value);

    this.setState({
      numberOfExportTemplates: value
    })
  };

  getMatchupStylingHome(locationType, leagueType, pick) {
    let uniform = pick.player1.uniform !== null ? parseInt(pick.player1.uniform) : null
    if(leagueType === "PUBG"){
        return <span>{pick.team1Name}</span>
    }
    if(locationType === 'away' || leagueType === "GOLF" || leagueType === 'LOTTERY') {
      return ''
    }
    else {
      if(uniform !== null && uniform !== 0) {
        return <span>#{pick.player1.uniform} {pick.player1.positionAbbreviation} | {pick.team1Abbr} vs. {pick.team2Abbr}</span>
      }
      else if(uniform === null) {
        return <span>{pick.player1.positionAbbreviation} | {pick.team1Abbr} vs. {pick.team2Abbr}</span>
      }
      else if(uniform === 0) {
        return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.player1.positionAbbreviation} | {pick.team1Abbr} vs. {pick.team2Abbr}</span>
      }
    }
  }
  
  getMatchupStylingAway(locationType, leagueType, pick) {
    let uniform = pick.player1.uniform !== null ? parseInt(pick.player1.uniform) : null
    if(locationType === 'home' || leagueType === "GOLF" || leagueType === 'LOTTERY') {
      return ''
    }
    else {
      if(uniform !== null && uniform !== 0) {
        return <span>#{pick.player1.uniform} {pick.player1.positionAbbreviation} | {pick.team1Abbr} vs. {pick.team2Abbr}</span>
      }
      else if(uniform === null) {
        return <span>{pick.player1.positionAbbreviation} | {pick.team1Abbr} vs. {pick.team2Abbr}</span>
      }
      else if(uniform === 0) {
        return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.player1.positionAbbreviation} | {pick.team1Abbr} vs. {pick.team2Abbr}</span>
      }
    }
  }

  handleOnChange = (e) => {
    console.log("handleOnChange22222-=-=-=-")
    console.log(e.target.files[0])
    this.setState({ file: e.target.files[0] });

    console.log("handleOnChange22222-=-=-=-")
  }

  handleOnSubmit(e) {
    // console.log(e);
    const file = this.state.file
    e.preventDefault()//this prevents the page from reloading

    if(file) {
      console.log('found file----',file)

      const fileReader = new FileReader()
      fileReader.onload = async (event) => {
        const text = event.target.result;
        const csvRows = text.slice(text.indexOf("\n") + 1).split("\n");
        // let newRows = []
        // csvRows.forEach(row => {
        //   // let splitRow = row.split(',')
        //   // newRows.push(splitRow)
        // })
        let justPropRows = csvRows.slice(1,)
        // justPropRows.forEach(row => {
        // })
        let body = {
          propRows: justPropRows,
          adminContestId: this.state.contestId
        }
        let config = {
          headers: {
            Token: window.localStorage.getItem("userData")
                ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
                : false,
          }
        }
        // console.log(justPropRows);

        $(".loader").addClass("show");
        // await axios.post(`${process.env.REACT_APP_LAMBDA_COMBO_URL}import-export-picks/importCsv`, body, config).then(res => {
        await axios.post(`${process.env.REACT_APP_SERVER_URL}import-export-picks/importCsv`, body, config).then(res => {
          $(".loader").removeClass("show");
          console.log("HERE: " + res.data.statusCode);
          if(res.data.statusCode !== 200) {
            messages("error", res.data.message);
          }
          else {
            let contestEntries = res.data.response
            let numEntries = res.data.response.entryCount
            this.setState({
              numExportedLineups: [numEntries, numEntries * this.props.contest.entryFee],
              csvPickData: res.data.response
            })
            this.state.csvPickData.propPicksList.forEach(item => {
              item.forEach(obj => {
                // console.log(obj);
              })
            })
            this.showExportPicksConfirmationModal()
          }
        })


      }
      fileReader.readAsText(file);
    }
  }

  csvFileToArray (string)  {

    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    this.setState({ csvRowsArray: array });

    // setArray(array);
  };


  render() {
    const pickObj = this.state.pickObj;
    return (
        <div className="tab-container traditional active" rel="making-picks">


          {/*<div style={{ textAlign: "center" }}>*/}
          {/*  <h1>REACTJS CSV IMPORT EXAMPLE 1</h1>*/}
          {/*  <form>*/}
          {/*    <input type={"file"} accept={".csv"} onChange={this.handleOnChange} />*/}
          {/*    <button onClick={(e) => {*/}
          {/*      this.handleOnSubmit(e);*/}
          {/*    }}>IMPORT CSV</button>*/}
          {/*  </form>*/}
          {/*</div>*/}

          <div className="actions">
            <div className="left">
              <button onClick={() => this.openImportPicks()} className="import-picks" title="Import Picks">
                Import Picks
              </button>
              <button onClick={() => this.exportPicks()} className="export-picks" title="Export Picks">
                Export Picks
              </button>
            </div>
            <div className="right">
              <button onClick={() => this.clearAllSelection()} className="clear-selection" title="Clear Selection">
                Clear Selection
              </button>
              <button onClick={() => this.openInviteFriendModal()} className="invite-friends" title="Invite Friends">
                Invite Friends
              </button>
            </div>
            <form className="importCsv">
              <input style={{width: '220px', marginRight: '10px'}} type={"file"} accept={".csv"} onChange={this.handleOnChange} />
              <button className="importCsv" onClick={(e) => {
                this.handleOnSubmit(e);
              }}>Import CSV</button>
            </form>
          </div>
          <div className="making-picks-listing">
            <div className="head">
              <span>Player</span>
              <span>Props</span>
              <span>Points</span>
            </div>
            <ul className="listing">
              {this.state.picks.length
                  ? this.state.picks.map((pick) => (
                      <li key={pick.propId} className={pickObj[pick.propId].propDisabled ? "disabled" : ""}>
                        <div className="player" onClick={() => this.triggerPlayerStatsPopup(pick.player1)}>
                          <img src={pick.player1.image} alt={pick.player1.firstName}/>
                          <h3>
                            {/* {JSON.stringify(pick)} */}
                            {/* {<Moment utc local format="hh:mm a">{pick.startTime}</Moment>} */}
                            {/* {pick.startDate} */}
                            {/* {typeof pick.startDate} */}
                            {pick.player1.firstName} {pick.player1.lastName}
                            {pick.team1locationType === 'home' && pick.player1.leagueType !== "UFC" && pick.player1.leagueType !== "PUBG"
                                ? (<span>{pick.player1.positionAbbreviation}-{pick.player1.teamAbbr}| {pick.team2Abbr} @ {pick.team1Abbr}</span>)
                                : this.getMatchupStylingHome(pick.team1locationType, pick.player1.leagueType, pick, pick.player1.number)
                            }
                            {pick.team1locationType === 'away' && pick.player1.leagueType !== "UFC"
                                ? (<span>{pick.player1.positionAbbreviation}-{pick.player1.teamAbbr}| {pick.team1Abbr} @ {pick.team2Abbr}</span>)
                                : this.getMatchupStylingAway(pick.team1locationType, pick.player1.leagueType, pick, pick.player1.number)
                            }
                          </h3>
                          {pick.player1.leagueType === "GOLF" ? (
                              <h4>
                                {/*<Moment utc local format="dddd">
                            {pick.startDate}
                          </Moment>
                          <Moment utc local format="hh:mm a">
                          {pick.startTime}
                          </Moment>*/}
                                Round {pick.golfRound}
                              </h4>
                          ) : (
                              <h4>
                                <Moment utc local format="hh:mm a">
                                  {pick.startTime}
                                </Moment>
                                ,{" "}
                                <Moment utc local format="ddd, MMM Do, YYYY">
                                  {pick.startDate}
                                </Moment>
                              </h4>
                          )}
                        </div>
                        <div className="props">
                          {pick.player1.propParameters[0] === "P" ? (
                              <h3>Prime or Not Prime</h3>
                          ) : (
                              ""
                          )}
                          {pick.player1.propParameters[0] === "OOE" ? (
                              <h3>Odd or Even</h3>
                          ) : (
                              ""
                          )}
                          {pick.player1.propParameters[0] === "IOO" ? (
                              <h3>In or Out <br/>{pick.rangeLower} - {pick.rangeUpper}</h3>
                          ) : (
                              ""
                          )}
                          {pick.player1.propParameters[0] !== "P" &&
                          pick.player1.propParameters[0] !== "OOE" &&
                          pick.player1.propParameters[0] !== "IOO" ? (
                              <h3>
                                <span className="prop-value">{pick.propValue}</span>{" "}
                                Total <br />
                                {pick.player1.propParameters.length ? (
                                    <span>
                              (
                                      {pick.player1.propParameters.map(
                                          (prop, index) => (
                                              <span className="prop" key={prop}>
                                    {index > 0 ? " + " : ""}
                                                {prop}
                                  </span>
                                          )
                                      )}
                                      )
                            </span>
                                ) : (
                                    ""
                                )}
                              </h3>
                          ) : (
                              ""
                          )}
                        </div>
                        <div className="points">
                          {/*LOTTERY*/}
                          {pick.player1.propParameters[0] === "P" ? (
                              <button
                                  className={
                                    pickObj[pick.propId].isOverSelected === true
                                        ? "active"
                                        : ""
                                  }
                                  onClick={() => this.pointsSelect(pick.propId, "over")}
                              >
                                Prime = {pick.overPoints} PTS
                              </button>
                          ) : (
                              ""
                          )}
                          {pick.player1.propParameters[0] === "P" ? (
                              <button
                                  className={
                                    pickObj[pick.propId].isUnderSelected === true
                                        ? "active"
                                        : ""
                                  }
                                  onClick={() =>
                                      this.pointsSelect(pick.propId, "under")
                                  }
                              >
                                Not Prime = {pick.underPoints} PTS
                              </button>
                          ) : (
                              ""
                          )}
                          {pick.player1.propParameters[0] === "VAL" ? (
                              <button
                                  className={
                                    pickObj[pick.propId].isOverSelected === true
                                        ? "active"
                                        : ""
                                  }
                                  onClick={() => this.pointsSelect(pick.propId, "over")}
                              >
                                More = {pick.overPoints} PTS
                              </button>
                          ) : (
                              ""
                          )}
                          {pick.player1.propParameters[0] === "VAL" ? (
                              <button
                                  className={
                                    pickObj[pick.propId].isUnderSelected === true
                                        ? "active"
                                        : ""
                                  }
                                  onClick={() =>
                                      this.pointsSelect(pick.propId, "under")
                                  }
                              >
                                Less = {pick.underPoints} PTS
                              </button>
                          ) : (
                              ""
                          )}
                          {pick.player1.propParameters[0] === "OOE" ? (
                              <button
                                  className={
                                    pickObj[pick.propId].isOverSelected === true
                                        ? "active"
                                        : ""
                                  }
                                  onClick={() => this.pointsSelect(pick.propId, "over")}
                              >
                                Even = {pick.overPoints} PTS
                              </button>
                          ) : (
                              ""
                          )}
                          {pick.player1.propParameters[0] === "OOE" ? (
                              <button
                                  className={
                                    pickObj[pick.propId].isUnderSelected === true
                                        ? "active"
                                        : ""
                                  }
                                  onClick={() =>
                                      this.pointsSelect(pick.propId, "under")
                                  }
                              >
                                Not Even = {pick.underPoints} PTS
                              </button>
                          ) : (
                              ""
                          )}
                          {pick.player1.propParameters[0] === "IOO" ? (
                              <button
                                  className={
                                    pickObj[pick.propId].isOverSelected === true
                                        ? "active"
                                        : ""
                                  }
                                  onClick={() => this.pointsSelect(pick.propId, "over")}
                              >
                                In Range = {pick.overPoints} PTS
                              </button>
                          ) : (
                              ""
                          )}
                          {pick.player1.propParameters[0] === "IOO" ? (
                              <button
                                  className={
                                    pickObj[pick.propId].isUnderSelected === true
                                        ? "active"
                                        : ""
                                  }
                                  onClick={() =>
                                      this.pointsSelect(pick.propId, "under")
                                  }
                              >
                                Not In Range = {pick.underPoints} PTS
                              </button>
                          ) : (
                              ""
                          )}

                          {pick.player1.leagueType !== "LOTTERY" ? (
                              <button
                                  className={
                                    pickObj[pick.propId].isOverSelected === true
                                        ? "active"
                                        : ""
                                  }
                                  onClick={() => this.pointsSelect(pick.propId, "over")}
                              >
                                More = {pick.overPoints} PTS
                              </button>
                          ) : (
                              ""
                          )}
                          {pick.player1.leagueType !== "LOTTERY" ? (
                              <button
                                  className={
                                    pickObj[pick.propId].isUnderSelected === true
                                        ? "active"
                                        : ""
                                  }
                                  onClick={() =>
                                      this.pointsSelect(pick.propId, "under")
                                  }
                              >
                                Less = {pick.underPoints} PTS
                              </button>
                          ) : (
                              ""
                          )}
                        </div>
                        {pickObj[pick.propId].icePick ? (
                            <div className="ice-pick">
                              {pickObj[pick.propId].icePrimary ? (
                                  <span>*ICE PICK 1</span>
                              ) : (
                                  <span>*ICE PICK 2</span>
                              )}
                            </div>
                        ) : (
                            ""
                        )}
                      </li>
                  ))
                  : ""}
            </ul>
          </div>
          <div className="totals">
            <div className="inner-totals">
              <div>
                <div>
                  Positions Filled:{" "}
                  <span>
                  {this.state.picked}/{this.props.contest.minPropsToPick}
                </span>
                </div>
                <div>
                  ICE Pick: <span>{this.state.icePick}</span>
                </div>
                <div>
                  Maximum Points: <span>{this.state.maximumPoint}</span>
                </div>
              </div>
              <p>
                *ICE prop = In case of Emergency Prop
                <span>
                <span>{this.state.tooltip}</span>
              </span>
              </p>
              <button onClick={this.checkLocation}>Submit</button>
            </div>
          </div>
          <div className="pay-modal">
            <div className="inner">
              <button
                  onClick={this.clickClosePayModal}
                  className="close-pay-modal close"
                  title="Close"
              ></button>
              <h2>Enter Contest</h2>
              <div>
                <div>
                  <label>No. of Entries</label>
                  <input
                      onChange={this.handleInputChange}
                      name="numberOfEntries" className="number-of-entries"
                      readOnly={this.state.payData.maxEntriesPerUser === 1 ? true : false}
                      step="1" type="tel" min="1" max={this.state.payData.maxEntriesPerUser}
                  />
                </div>
                <div>
                  <label>Cost per Entry</label>
                  <span>{formatNumber(this.state.payData.entryFee)}</span>
                </div>
                <div>
                  <label>Total Payments</label>
                  <span>
                  {formatNumber(
                      this.state.payData.entryFee * this.state.numberOfEntries
                  )}
                </span>
                </div>
              </div>
              <button onClick={this.pay} className="pay-now" title="Pay Now">
                Pay Now
              </button>
              {this.ticketSubmissionSection()}
            </div>
          </div>
          <div className="import-picks-modal popup">
            <div className="inner">
              <button
                  onClick={this.closeImportPicksModal}
                  className="close-import-picks-modal close"
                  title="Close"
              ></button>
              <h2>Import Picks</h2>
              <div>
                <ul>
                  {this.state.importPicks.length ? (
                      this.state.importPicks.map((importPick) => (
                          <li key={importPick.userContestId}>
                            <input
                                type="radio"
                                name="import-pick"
                                rel={importPick.contestId}
                                value={importPick.userContestId}
                            />
                            <div>
                              <img src={importPick.gameImage} alt={importPick.name} />
                              <h4>{importPick.name}</h4>
                              <h5>
                                Date{" "}
                                <Moment utc local format="ddd, MMM Do, YYYY">
                                  {importPick.startDate}
                                </Moment>{" "}
                                |{" "}
                                <Moment utc local format="hh:mm a">
                                  {importPick.startTime}
                                </Moment>
                              </h5>
                              <span>
                          Entry Fees {formatNumber(importPick.entryFee)}
                        </span>
                            </div>
                          </li>
                      ))
                  ) : (
                      <p>No Contest Found</p>
                  )}
                </ul>
                {this.state.importPicks.length != 0 ? (
                    <button
                        onClick={this.applyImportPicks}
                        className="apply-picks"
                        title="Done"
                    >
                      Done
                    </button>
                ) : (
                    <button
                        onClick={this.closeImportPicksModal}
                        className="apply-picks"
                        title="Done"
                    >
                      Done
                    </button>
                )}
              </div>
            </div>
          </div>

          <div className="export-picks-modal">
            <div className="inner">
              <button onClick={this.closeExportPicksModal} className="close-export-picks-modal close" title="Close"></button>
              <h2>Export CSV</h2>
              <div>
                <div>
                  <label>Please enter the number of contest entries</label>
                  <input name="exportNumberOfContests" onChange={this.handleExportModalChange} className="export-number-pick" style={{ width: 300 }}
                         readOnly={this.state.payData.maxEntriesPerUser === 1 ? true : false}
                         step="1" type="tel" min="1" max={this.props.details.maxEntriesPerUser}/>
                </div>
              </div>
              <button onClick={this.exportCsvSubmit} className="submit-export-number-now" title="Export">Export</button>
            </div>
          </div>

          <div className="export-picks-confirmation-modal">
            <div className="inner">
              <button onClick={this.closeExportPicksConfirmationModal} className="close-export-picks-modal close" title="Close"></button>
              <h2>Export CSV</h2>
              <div>
                <div>
                  <label style={{fontSize: '15px', color: 'white'}}>Are you sure you want to submit {this.state.numExportedLineups[0]} Entries for ${this.state.numExportedLineups[1]}?</label>
                  <button onClick={this.submitCsvPicks} className="submit-export-number-now" title="Export">Submit CSV</button>
                </div>
                <Accordion allowZeroExpanded={true}>
                  {this.state.csvPickData !== null ? this.state.csvPickData.propPicksList.map((item, index) => (
                      <AccordionItem key={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Entry {index + 1}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {item.map((pick, index) => (
                              <p key={index}>{pick.firstName} {pick.lastName}, {pick.over === true ? 'o' : 'u'}{pick.propValue} {pick.propParams}, {pick.over === true ? pick.overPoints : 200 - pick.overPoints} Points{pick.ice === true ? (pick.icePrimary === true ? ' (ICE 1)' : ' (ICE 2)') : ''}</p>
                          ))}
                        </AccordionItemPanel>
                      </AccordionItem>
                  )) : null}
                </Accordion>
              {/*<button onClick={this.submitCsvPicks} className="submit-export-number-now" title="Export">Submit</button>*/}
              </div>
            </div>
          </div>

          <div className="invite-friend-popup popup">
            <div className="inner">
              <button
                  onClick={this.closeInviteFriendModal}
                  className="close-invite-friend-modal close"
                  title="Close"
              ></button>
              <h2>Invite Friend</h2>
              <div>
                <input
                    className="friend-email"
                    type="email"
                    placeholder="Email"
                />
                <button onClick={this.inviteFriend} title="Submit">
                  Submit
                </button>
                <span className="error">Valid Email Required</span>
              </div>
            </div>
          </div>
          <PlayerStatsPopup ref={this.playerStats} />
        </div>
    );
  }
}


function toObject(data, arr) {
  let count = 0;
  let fields = {};
  for (var key of Object.keys(data[0])) {
    fields[key] = arr[count];
    count++;
  }
  return fields;
}

function generateRow(exampleRow, rowData) {
  let count = 0;
  let row = {};
  for (var key of Object.keys(exampleRow)) {
    row[key] = rowData[count];
    count++;
  }
  return row;
}

function formatNumber(num) {
  return "$" + (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function validateEmail($email) {
  var emailReg = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return emailReg.test($email);
}

export default withRouter(ContestTraditionalPicks);