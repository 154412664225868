import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './colin-landing.scss';

import playOnline from '../../../../assets/images/online.png';
import appleStore from '../../../../assets/images/apple store.png';
import googlePlay from '../../../../assets/images/google play.png';
import howToPlay1 from '../../../../assets/images/svg/1.png';
import howToPlay2 from '../../../../assets/images/svg/2.png';
import howToPlay3 from '../../../../assets/images/svg/3.png';

const ColinLanding = (props) => {
    // const subheaderText = ( props.history.location.pathname.split('/')[2] === "1" )
    //     ? <p>Sign Up Now for a FREE $5 Credit!</p>
    //     : <p>Enter the FREE $1,000 Envy Only Valorant Contest</p>;
    // let subheaderText = null;
    // const subpath = props.history.location.pathname.split('/')[props.history.location.pathname.split('/').length - 1];
    // if (subpath === '1') {
    //     subheaderText = <p>Sign Up Now for a FREE $5 Credit!</p>;
    // } else if (subpath === '2') {
    //     subheaderText = <p>Enter the FREE $1,000 Envy Only Valorant Contest</p>;
    // }
    return (
        <>
            <div class="landing-title-colin">
                <div className="colin-vert-logos-mini"></div>
            </div>
            <section className="landing-section-1-colin">
                <div className="inner-colin">
                    <div className="subheader">
                        <h1>Daily Fantasy Sports And <br /> Esports For Player Props</h1>
                        <p id="deposit-match-message">Instant 100% Deposit Match Up to $50*</p>
                        <p id="thrive-tagline" style={{ color: 'white' }}>#PropUp</p>
                    </div>
                    <div className="btns">
                        <ul>
                            <li>
                                <Link to={process.env.REACT_APP_START_URL + "colin-sign-up"} title="Play Online">
                                    <span>PLAY NOW</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="landing-slider-colin">
                    {/* <div>
                        <ul> */}
                    <div className="colin-vert-logos"></div>
                    {/* </ul>
                    </div> */}
                </div>
            </section>
            <section className="landing-section-2">
                <div className="inner">
                    <h2 id="how-to-play">How To Play!</h2>
                    <iframe width="920" height="518" src="https://www.youtube.com/embed/VALDCo6wn68" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <ul>
                        <li>
                            <div>
                                <img src={howToPlay1} alt="Choose a Contest" />
                            </div>
                            <h3>Choose a Contest!</h3>
                            <p>We Offer NFL, NBA, MLB, PGA, Cricket and Esports (CS:GO, Dota, LoL)</p>
                        </li>
                        <li>
                            <div>
                                <img src={howToPlay2} alt="Pick Your Side" />
                            </div>
                            <h3>Pick Your Props!</h3>
                            <p>Make A Lineup In Minutes.</p>
                        </li>
                        <li>
                            <div>
                                <img src={howToPlay3} alt="Get Your Points" />
                            </div>
                            <h3>Win Cash Prizes!</h3>
                            <p>Watch Your Team and Rack Up Points!</p>
                        </li>
                    </ul>
                    <p>
                        By focusing solely on top-tier athletes while streamlining the drafting process, we've created a unique DFS experience that gives the casual sports and Esports fan an opportunity to compete and succeed at a high level.
                    </p>
                </div>
            </section>
            <section className="landing-section-3">
                <div className="inner">
                    <h2>#PropUp </h2>
                    <p>Our app is available on any device! Download now to get started!</p>
                    <div className="btns">
                        <ul>
                            <li>
                                <Link to={process.env.REACT_APP_START_URL + "sign-up"} title="Play Online">
                                    <img src={playOnline} alt="Online" />
                                    <span>Play Online</span>
                                </Link>
                            </li>
                            <li>
                                <a href="https://apps.apple.com/us/app/thrivefantasy/id1240062484?ls=1" title="Apple Store" target="_blank" rel="noopener noreferrer">
                                    <img src={appleStore} alt="Apple Store" />
                                    <span>
                                        <small>Download on</small>
                                        App Store
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.appster.p2f&hl=en_US" title="Google Play" target="_blank" rel="noopener noreferrer">
                                    <img src={googlePlay} alt="Google Play" />
                                    <span>
                                        <small>Download on</small>
                                        Google Play
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default withRouter(ColinLanding);

