import React from 'react';
import {Link} from "react-router-dom";
import './my-props-live.scss';
import LoggedInHeader from '../Logged-in-header/Logged-in-header';
import $ from 'jquery';
import {isAuthenticated} from "../isAuthenticated";
import Footer from "../footer/footer";
import {messages} from "../messages";
import Moment from "react-moment";
import PlayerStatsPopup from "../player-stats-popup/player-stats-popup";
import { Mixpanel } from '../mixpanel/Mixpanel';

const requestPropsLiveOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    }
};


class MyPropsLive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            houseUserContests: [],
            call: false
        };
        $('.loader').addClass('show');
        this.fetchPropsLiveData = this.fetchPropsLiveData.bind(this);
        this.processData = this.processData.bind(this);
        this.playerStats = React.createRef();
    }

    fetchPropsLiveData(requestPropsLiveOptions) {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'houseProp/liveHouseUserContestsByUser', requestPropsLiveOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    console.log(data);
                    this.processData(data);
                    this.setState({call: true});
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            });
    }

    componentDidMount() {
        isAuthenticated('login', '/my-props/live');
        this.fetchPropsLiveData(requestPropsLiveOptions);

        $('.mobile-select-sport').on('click', '.selected', function () {
            $('.mobile-select-sport').find('.selection').toggleClass('open');
        });

        Mixpanel.track('web:my_props_opened');

    }

    processData(data) {
        let houseUserContests = [];
        data['response'].forEach((houseUserContestData) => {
            let houseUserContestProps = [];
            houseUserContestData['houseUserContestProps'].forEach(houseUserContestProp => {
                let houseProp = houseUserContestProp['houseProp'];
                let event = houseProp['adminEvent'];
                let player = houseProp['adminPlayer'];
                let housePropParameters = [];
                let totalLivePoints = 0;
                houseProp['housePropParameters'].forEach(housePropParameter => {
                    housePropParameters.push(housePropParameter['adminPropParameter']['abbreviation']);
                    totalLivePoints += housePropParameter['statValue'];
                });
                houseUserContestProps.push({
                    isOver: houseUserContestProp['isOver'],
                    id: houseProp['id'],//redundent
                    propValue: houseProp['propValue'],
                    startTime: houseProp['startTime'],
                    totalLivePoints: totalLivePoints,
                    event: {
                        inning: event['inning'],
                        eventCompleted: event['eventCompleted'],
                        startTime: event['startTime'],
                        team1Abbr: event['team1Abbr'],
                        team2Abbr: event['team2Abbr'],
                        team1Score: event['team1Score'],
                        team2Score: event['team2Score'],
                        team1Name: event['team1Name'],
                        team2Name: event['team2Name']
                    },
                    player: {
                        firstName: player['firstName'],
                        height: player['height'],
                        image: player['image'],
                        lastName: player['lastName'],
                        leagueType: player['leagueType'],
                        playerId: player['playerId'],
                        positionAbbreviation: player['positionAbbreviation'],
                        uniform: player['uniform'],
                        weight: player['weight'],
                        id: player['id'],
                        teamAbbr: player['teamAbbr']
                    },
                    golfRound:houseProp['golfRound'],
                    golfTeeTime:houseProp['golfTeeTime'],
                    golfThru:houseProp['golfThru'],
                    housePropParameters: housePropParameters,
                    contestId: 'contestId',
                    minPropsToPick: 50,
                    player1: {
                        firstName: 'firstName',
                        height: 5,
                        image: 'image',
                        lastName: 'lastName',
                        leagueType: 'leagueType',
                        playerId: 'playerId',
                        positionAbbreviation: 'positionAbbreviation',
                        uniform: 'uniform',
                        weight: 6,
                        id: 2,
                        teamAbbr: 'teamAbbr',
                        propParameters: ['firstpropparam', 'secondpropparam'],
                    },
                    player1TeamId: 3,
                    propId: houseProp['id'],//redundent
                    startDate: 'startTimeShortDate',
                    statEventId: 'statEventId',
                    team1Abbr: 'team1Abbr',
                    team2Abbr: 'team2Abbr',
                    venueState: 'venueState',
                    team1locationType: 'home',
                    selected: null,
                    half: houseProp['half']
                });
            });

            houseUserContests.push({
                id: houseUserContestData['id'],
                entryAmount: houseUserContestData['entryAmount'],
                possibleWinnings: houseUserContestData['possibleWinnings'],
                dateCreated: houseUserContestData['dateCreated'],
                houseUserContestProps: houseUserContestProps
            })
        });
        this.setState({houseUserContests: houseUserContests});
    }

    numSuffix(n) {
        n=parseInt(n)
        if (isNaN(n)){
            n=0
        }
        const suffix = ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th";
        return n + suffix;
    }

    triggerPlayerStatsPopup(player) {
        this.playerStats.current.openPlayerStatsPopup(player);

        Mixpanel.track('web:user_triggered_player_stats_popup', {
            'Player Id':player.id
        });

    }

    isHalf(half){
        if(half === 1){
            return "1st Half";
        } else if (half === 2){
            return "2nd Half";
        } else {
            return "Total"
        }
    }


    render() {
        return (
            <main className="my-props-live">
                <LoggedInHeader/>
                <div className="inner">
                    <nav className="filters">
                        <button>Date</button>
                        <button>Entry Fee</button>
                        <button>Prize Pool</button>
                        <button>Number of Entries</button>
                    </nav>
                    <aside>
                        <h2>Select Category</h2>
                        <nav className="my-contest-nav">
                            <Link className="live active" to={process.env.REACT_APP_START_URL + "my-props/live"} title="live">Live</Link>
                            <Link className="upcoming" to={process.env.REACT_APP_START_URL + "my-props/upcoming"} title="Upcoming">Upcoming</Link>
                            <Link className="History " to={process.env.REACT_APP_START_URL + "my-props/history"} title="History">History</Link>
                        </nav>
                    </aside>
                    <section className="events">
                        <div className="mobile-select-sport">
                            <h2>Select the Sports</h2>
                            <div className="selected">
                                Live
                            </div>
                            <div className="selection">
                                <nav className="my-contest-nav">
                                    <Link className="live active" to={process.env.REACT_APP_START_URL + "my-props/live"} title="live">Live</Link>
                                    <Link className="upcoming" to={process.env.REACT_APP_START_URL + "my-props/upcoming"} title="Upcoming">Upcoming</Link>
                                    <Link className="History " to={process.env.REACT_APP_START_URL + "my-props/history"} title="History">History</Link>
                                </nav>
                            </div>
                        </div>
                        {this.state.call ?
                            <div className="props-listing">
                                {this.state.houseUserContests.length ? this.state.houseUserContests.map(houseUserContest => (
                                        <div key={houseUserContest.id}>
                                            <h2>Entry Amount: ${houseUserContest.entryAmount} Possible Winnings: ${houseUserContest.possibleWinnings}</h2>
                                            <div className="head">
                                                <span className="player">Player</span>
                                                <span className="props">Props</span>
                                                <span className="points">Live Points</span>
                                                <span className="over-under">More / Less</span>
                                            </div>
                                            <ul>
                                                {houseUserContest.houseUserContestProps.length ? houseUserContest.houseUserContestProps.map(houseProp => (
                                                    <li key={houseProp.id}>
                                                        <div className="player"  onClick={() => this.triggerPlayerStatsPopup(houseProp.player)}>
                                                            <img src={houseProp.player.image} alt=""/>
                                                            {houseProp.player.leagueType === 'GOLF' || houseProp.player.leagueType === "UFC" ?
                                                                <h2>{houseProp.player.firstName} {houseProp.player.lastName}</h2>
                                                                :
                                                                <h2>{houseProp.player.firstName} {houseProp.player.lastName} <span> | {houseProp.player.positionAbbreviation}-{houseProp.player.teamAbbr}</span></h2>
                                                            }
                                                            {houseProp.player.leagueType === 'MLB' ?
                                                                <h3>
                                                                    {this.numSuffix(houseProp.event.inning ? houseProp.event.inning : 0)} INN | {houseProp.event.team1Abbr}  {houseProp.event.team1Score} - {houseProp.event.team2Abbr} {houseProp.event.team2Score}
                                                                </h3>
                                                                :
                                                                ''
                                                            }
                                                            {houseProp.player.leagueType === 'NFL' ?
                                                                <h3>
                                                                    {this.numSuffix(houseProp.event.inning ? houseProp.event.inning : 0)} QTR | {houseProp.event.team1Abbr}  {houseProp.event.team1Score} - {houseProp.event.team2Abbr} {houseProp.event.team2Score}
                                                                </h3>
                                                                :
                                                                ''
                                                            }
                                                            {houseProp.player.leagueType === 'NBA' ?
                                                                <h3>
                                                                    {this.numSuffix(houseProp.event.inning ? houseProp.event.inning : 0)} QTR | {houseProp.event.team1Abbr}  {houseProp.event.team1Score} - {houseProp.event.team2Abbr} {houseProp.event.team2Score}
                                                                </h3>
                                                                :
                                                                ''
                                                            }
                                                            {houseProp.player.leagueType === 'GOLF' ?
                                                                <h3>
                                                                    {this.numSuffix(houseProp.golfThru ? houseProp.golfThru : 0)} HOLE | {this.numSuffix(houseProp.golfRound ? houseProp.golfRound : 0)} RND
                                                                </h3>
                                                                :
                                                                ''
                                                            }
                                                            {houseProp.player.leagueType === 'CRICKET' ?
                                                                <h3>
                                                                    {this.numSuffix(houseProp.event.inning ? houseProp.event.inning : 0)} INN | {houseProp.event.team1Abbr}  {houseProp.event.team1Score} - {houseProp.event.team2Abbr} {houseProp.event.team2Score}
                                                                </h3>
                                                                :
                                                                ''
                                                            }
                                                            {houseProp.player.leagueType === 'CSGO2' ?
                                                                <h3>
                                                                    {houseProp.event.team1Abbr} {houseProp.event.team1Score} - {houseProp.event.team2Abbr} {houseProp.event.team2Score}
                                                                </h3>
                                                                :
                                                                ''
                                                            }
                                                            {houseProp.player.leagueType === 'LOL' ?
                                                                <h3>
                                                                    {this.numSuffix(houseProp.event.inning ? houseProp.event.inning : 0)} RND | {houseProp.event.team1Abbr}  {houseProp.event.team1Score} - {houseProp.event.team2Abbr} {houseProp.event.team2Score}
                                                                </h3>
                                                                :
                                                                ''
                                                            }
                                                            {houseProp.player.leagueType === 'DOTA2' ?
                                                                <h3>
                                                                    {this.numSuffix(houseProp.event.inning ? houseProp.event.inning : 0)} RND | {houseProp.event.team1Abbr}  {houseProp.event.team1Score} - {houseProp.event.team2Abbr} {houseProp.event.team2Score}
                                                                </h3>
                                                                :
                                                                ''
                                                            }
                                                            {houseProp.player.leagueType === 'SOCCER' ?
                                                                <h3>
                                                                    {this.numSuffix(houseProp.event.inning ? houseProp.event.inning : 0)} HALF | {houseProp.event.team1Abbr}  {houseProp.event.team1Score} - {houseProp.event.team2Abbr} {houseProp.event.team2Score}
                                                                </h3>
                                                                :
                                                                ''
                                                            }
                                                            {houseProp.player.leagueType === 'AUDL' ?
                                                                <h3>
                                                                    {this.numSuffix(houseProp.event.inning ? houseProp.event.inning : 0)} QTR | {houseProp.event.team1Abbr}  {houseProp.event.team1Score} - {houseProp.event.team2Abbr} {houseProp.event.team2Score}
                                                                </h3>
                                                                :
                                                                ''
                                                            }
                                                            {houseProp.player.leagueType === 'VAL' ?
                                                                <h3>
                                                                    {this.numSuffix(houseProp.event.inning ? houseProp.event.inning : 0)} RND | {houseProp.event.team1Abbr}  {houseProp.event.team1Score} - {houseProp.event.team2Abbr} {houseProp.event.team2Score}
                                                                </h3>
                                                                :
                                                                ''
                                                            }
                                                            {houseProp.player.leagueType === 'GOLF' ?
                                                                <h4>Tee Time <Moment utc local format="MM-DD HH:mm">{houseProp.golfTeeTime}</Moment></h4>
                                                                :
                                                                <h4><Moment utc local format="YYYY-MM-DD HH:mm">{houseProp.event.startTime}</Moment></h4>
                                                            }
                                                            {
                                                                houseProp.player.leagueType === 'UFC' ?
                                                                    <h3>
                                                                       {houseProp.event.inning === null || houseProp.event.inning === 0 ? <span><Moment utc local format="hh:mm a">{houseProp.startTime}</Moment> | {houseProp.event.team1Name} vs. {houseProp.event.team2Name} </span> : ''}
                                                                        {houseProp.event.inning >= 1 && !houseProp.event.eventCompleted ? <span> {houseProp.event.team1Name} vs {houseProp.event.team2Name} | {this.numSuffix(houseProp.event.inning)} Round </span> : ''}
                                                                        {houseProp.event.eventCompleted ? <span> Final |  {houseProp.event.team1Name} vs. {houseProp.event.team2Name}</span> : ''}
                                                                    </h3>
                                                                    : ''
                                                            }
                                                        </div>
                                                        <div className="props">
                                                            <h3>{houseProp.propValue} <span>Total {this.isHalf(houseProp.half)}</span> <br/>
                                                                {houseProp.housePropParameters ?
                                                                    <span>({houseProp.housePropParameters.map((prop, index) => (<span className="prop" key={prop}>{index > 0 ? ' + ' : ''}{prop}</span>))})</span>
                                                                    : ''}
                                                            </h3>
                                                    </div>
                                                        <div className="points">
                                                            <span>{houseProp.totalLivePoints}</span>
                                                        </div>
                                                        <div className="over-under">
                                                            {
                                                                houseProp.isOver === true ?
                                                                    <span className={houseProp.propValue > houseProp.totalLivePoints ?  (houseProp.event.eventCompleted ? 'red' : 'blue') : 'green'}>More</span>
                                                                    :
                                                                    <span className={houseProp.totalLivePoints > houseProp.propValue ? (houseProp.event.eventCompleted ? 'red' : 'blue') : 'green'}>Less</span>

                                                            }
                                                        </div>
                                                    </li>
                                                )) : ''}
                                            </ul>
                                        </div>
                                    )) :
                                    <div className="no-results">
                                        <div></div>
                                        <h3>No Live Props Found</h3>
                                        <Link to={process.env.REACT_APP_START_URL + "props-lobby"}>Go Back</Link>
                                    </div>
                                }
                            </div>
                            : ''}
                    </section>
                </div>
                <PlayerStatsPopup ref={this.playerStats}/>
                <Footer/>
            </main>
        );
    }
}


export default MyPropsLive;
