import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';

const tagManagerArgs = {
    gtmId: 'GTM-59XHQQK',
    dataLayerName: 'PageDataLayer'
};


TagManager.initialize(tagManagerArgs);

ReactGA.initialize('UA-116518115-1');

mixpanel.init('37bcd3d006bcab31766a97024fa3fd4a');


ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();