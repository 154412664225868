import $ from 'jquery';

export function messages (type , message) {
    if(type && message) {
        $('.site-messages').addClass('show').html(message).addClass(type);

        window.setTimeout(function () {
            $('.site-messages').removeClass('show').html('').removeClass(type);
        },3000);
    }
}



