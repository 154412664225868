import React from 'react';
import { withRouter } from "react-router";
import './contest-versus-picks.scss';
import $ from 'jquery';
import Moment from 'react-moment';
import PlayerStatsPopup from "../player-stats-popup/player-stats-popup";

import { messages } from "../messages";
import { useJsonToCsv } from "react-json-csv";
import {Mixpanel} from "../mixpanel/Mixpanel";
import beltImage from "../../assets/images/belt_render.jpg";

let picks = [];

let picksBody = {
    contestId: 0,
    currentPage: 1,
    currentSize: 40,
    userContestId: 0
};

const requestOptions = {
    method: 'post',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    },
    body: JSON.stringify(picksBody)
};

const getOptions = {
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    }
};

class ContestVersusPicks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitDisabled: true,
            ice: false,
            edit: false,
            pickObj: {},
            picksArray: [],
            icePlayerArray: [],
            icePrimaryPicked: false,
            picked: 0,
            minimumPick: 0,
            icePick: 0,
            maximumPoint: 0,
            picks: [],
            tooltip: '',
            payData: {},
            contestId: this.props.match.params.contestId,
            userContestId: this.props.match.params.userContestId,
            numberOfEntries: 1,
            importPicks: {},
            exportPicksData: null
        };
        this.fetchNewPicksData = this.fetchNewPicksData.bind(this);
        this.checkForSelection = this.checkForSelection.bind(this);
        this.addPicks = this.addPicks.bind(this);
        this.pointsSelect = this.pointsSelect.bind(this);
        this.highlight = this.highlight.bind(this);
        this.pushArray = this.pushArray.bind(this);
        this.popArray = this.popArray.bind(this);
        this.checkForPoints = this.checkForPoints.bind(this);
        this.clearAllSelection = this.clearAllSelection.bind(this);
        this.checkLocation = this.checkLocation.bind(this);
        this.checkReasonCodes = this.checkReasonCodes.bind(this);
        this.submitPicks = this.submitPicks.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.pay = this.pay.bind(this);
        this.checkForPicksObj = this.checkForPicksObj.bind(this);
        this.fetchImportPicksData = this.fetchImportPicksData.bind(this);
        this.openImportPicks = this.openImportPicks.bind(this);
        this.applyImportPicks = this.applyImportPicks.bind(this);
        this.inviteFriend = this.inviteFriend.bind(this);

        this.playerStats = React.createRef();
    }

    // Select picks
    pointsSelect(id, type, playerId) {
        this.setState({ submitDisabled: true });
        if (this.state.ice && !this.state.pickObj[id].selected) {
            messages('error', 'You have exceeded maximum props for this contest');

        } else {
            this.highlight(id, type, playerId);
            this.checkForPoints(id);
        }
    }

    highlight(id, type, playerId) {
        let pickObj = this.state.pickObj;
        if (type === 'over') {
            pickObj[id].isOverSelected = !pickObj[id].isOverSelected;
            pickObj[id].isUnderSelected = false;
            pickObj[id].isOverSelected ? pickObj[id].selectedPoints = pickObj[id].overPoints : pickObj[id].selectedPoints = 0;
            pickObj[id].playerId = playerId;
        } else if (type === 'under') {
            pickObj[id].isUnderSelected = !pickObj[id].isUnderSelected;
            pickObj[id].isOverSelected = false;
            pickObj[id].playerId = playerId;
            if (pickObj[id].isUnderSelected) {
                pickObj[id].selectedPoints = pickObj[id].underPoints;
            } else {
                pickObj[id].selectedPoints = 0;
            }
        }
        if (pickObj[id].isUnderSelected || pickObj[id].isOverSelected) {
            pickObj[id].selected = true;
            this.pushArray(id);
        } else {
            pickObj[id].selected = false;
            pickObj[id].icePick = false;
            this.popArray(id);
        }
        this.setState({ pickObj: pickObj });
    }

    pushArray(id) {
        let icePlayerArray = this.state.icePlayerArray;
        let pickObj = this.state.pickObj;
        if (icePlayerArray.indexOf(id) === -1) {
            icePlayerArray.push(id);
            if (this.state.picked >= this.state.minimumPick && this.state.icePick !== 2) {
                pickObj[id].icePick = true;
            } else {
                pickObj[id].icePick = false;
            }
        }
        this.setState({ icePlayerArray: icePlayerArray, pickObj: pickObj });
    }

    popArray(id) {
        let icePlayerArray = this.state.icePlayerArray;
        let pickObj = this.state.pickObj;
        pickObj[id].icePick = false;
        pickObj[id].icePrimary = false;
        icePlayerArray.splice(icePlayerArray.indexOf(id), 1);
        if (icePlayerArray.length === 0) {
            this.clearAllSelection();
        }
        this.setState({ icePlayerArray: icePlayerArray, pickObj: pickObj });
    }

    checkForPoints(id) {
        let icePlayerArray = this.state.icePlayerArray;
        let pickObj = this.state.pickObj;
        let icePick = 0;
        let picked = 0;
        let maximumPoint = 0;
        let icePrimaryPicked = this.state.icePrimaryPicked;
        let ice = false;
        let submitDisabled = this.state.submitDisabled;
        let minimumPick = this.state.minimumPick;
        for (let i = 0; i < icePlayerArray.length; i++) {
            if (pickObj[icePlayerArray[i]].icePick === true) {
                if (pickObj[icePlayerArray[i]].icePick === true) {
                    icePick++;
                    if (icePick === 1) {
                        if (pickObj[icePlayerArray[i]].iceSecondary) {
                            pickObj[icePlayerArray[i]].icePrimary = false;
                        } else {
                            pickObj[icePlayerArray[i]].icePrimary = true;
                            icePrimaryPicked = true;
                        }
                    } else {
                        if (this.edit === 'true') {
                            if (icePrimaryPicked) {
                                pickObj[icePlayerArray[i]].icePrimary = false;
                            } else {
                                pickObj[icePlayerArray[i]].icePrimary = true;
                            }
                        } else {
                            pickObj[icePlayerArray[i]].icePrimary = false;
                        }
                    }
                } else {
                    pickObj[icePlayerArray[i]].icePrimary = false;
                    maximumPoint += pickObj[icePlayerArray[i]].selectedPoints;
                    picked++;
                }
            } else {
                pickObj[icePlayerArray[i]].icePick = false;
                maximumPoint += pickObj[icePlayerArray[i]].selectedPoints;
                picked++
            }
        }
        if (icePick === 2 && picked === minimumPick) {
            ice = true;
        } else {
            ice = false;
        }
        (icePick === 0 && picked === 0) ?
            submitDisabled = true :
            submitDisabled = false;
        if (icePick === 0) {
            for (let i in pickObj) {
                if (pickObj.hasOwnProperty(i)) {
                    pickObj[i].iceSecondary = false;
                    pickObj[i].icePrimary = false;
                }
            }
        }
        this.setState({
            icePlayerArray: icePlayerArray,
            pickObj: pickObj,
            icePick: icePick,
            picked: picked,
            maximumPoint: maximumPoint,
            icePrimaryPicked: icePrimaryPicked,
            ice: ice,
            submitDisabled: submitDisabled,
            minimumPick: minimumPick
        });

    }

    /*clear all selected picks*/
    clearAllSelection() {
        let pickObj = this.state.pickObj;
        for (let i in pickObj) {
            if (pickObj.hasOwnProperty(i)) {
                pickObj[i].selected = false;
                pickObj[i].icePick = false;
                pickObj[i].selectedPoints = 0;
                pickObj[i].isUnderSelected = false;
                pickObj[i].isOverSelected = false;
                pickObj[i].iceSecondary = false;
                pickObj[i].icePrimary = false;
            }
        }

        this.setState({
            icePlayerArray: [],
            pickObj: pickObj,
            icePick: 0,
            picked: 0,
            maximumPoint: 0,
            icePrimaryPicked: false,
            ice: false,
            submitDisabled: true
        });
    }

    /*get picks and format array*/
    fetchNewPicksData(requestOptions, propsCall) {
        $('.loader').addClass('show');
        picks = [];
        let pickObj = this.state.pickObj;
        let userPropPick;

        // let url = process.env.REACT_APP_LAMBDA_COMBO_URL;
        let url = process.env.REACT_APP_SERVER_URL;

        this.setPropData(url, propsCall, requestOptions, userPropPick, pickObj);
    }

    getImportPicksData(requestOptions, propsCall) {
        $('.loader').addClass('show');
        picks = [];
        let pickObj = this.state.pickObj;
        let userPropPick;

        // let url = process.env.REACT_APP_LAMBDA_COMBO_URL;
        let url = process.env.REACT_APP_SERVER_URL;
        this.setPropData(url, propsCall, requestOptions, userPropPick, pickObj, true);
    }

    setPropData(url, propsCall, requestOptions, userPropPick, pickObj, importPicks=false) {
        $('.loader').addClass('show');
        picks = [];

        fetch(url + propsCall, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    data['response']['data'].forEach((singleData) => {
                        if(data['response']['exportPicks'] !== null) {
                            this.setState({
                                exportPicksData: data['response']['exportPicks']
                            })
                        }
                        let contestProp = singleData['contestProp'];

                        if(importPicks === true) {
                            userPropPick = singleData['userPropPick'];
                        }
                        picks.push({
                            contestId: contestProp['contestId'],
                            player1Points: contestProp['player1Points'],
                            player1: {
                                firstName: contestProp['player1']['firstName'],
                                height: contestProp['player1']['height'],
                                id: contestProp['player1']['id'],
                                image: contestProp['player1']['image'],
                                lastName: contestProp['player1']['lastName'],
                                leagueType: contestProp['player1']['leagueType'],
                                playerId: contestProp['player1']['playerId'],
                                positionAbbreviation: contestProp['player1']['positionAbbreviation'], // SG
                                propParameters: contestProp['player1']['propParameters'],
                                teamAbbr: contestProp['player1']['teamAbbr'],
                                uniform: contestProp['player1']['uniform'],
                                weight: contestProp['player1']['weight'],
                                startTime: contestProp['startTime']

                            },
                            player1TeamId: contestProp['player1TeamId'],
                            player1Advantage: contestProp['player1Advantage'],
                            player2Points: contestProp['player2Points'],
                            //player1TeamAbbvr: string;//team -HOU
                            player2: {
                                firstName: contestProp['player2']['firstName'],
                                height: contestProp['player2']['height'],
                                id: contestProp['player2']['id'],
                                image: contestProp['player2']['image'],
                                lastName: contestProp['player2']['lastName'],
                                leagueType: contestProp['player2']['leagueType'],
                                playerId: contestProp['player2']['playerId'],
                                positionAbbreviation: contestProp['player2']['positionAbbreviation'], // SG
                                propParameters: contestProp['player2']['propParameters'],
                                teamAbbr: contestProp['player2']['teamAbbr'],
                                uniform: contestProp['player2']['uniform'],
                                weight: contestProp['player2']['weight'],
                                startTime: contestProp['startTimePlayer2']
                            },
                            player2TeamId: contestProp['player2TeamId'],
                            player2Advantage: contestProp['player2Advantage'],
                            propId: contestProp['propId'],
                            propValue: contestProp['propValue'],
                            startDate: contestProp['startTime'],
                            startTime: contestProp['startTime'],
                            startTimePlayer2: contestProp['startTimePlayer2'],
                            statEventId: contestProp['statEventId'],
                            team1Abbr: contestProp['team1Abbr'],//NYK
                            team2Abbr: contestProp['team2Abbr'],//HOU

                            event1Team1Name: contestProp['event1Team1Name'],
                            event1Team2Name: contestProp['event1Team2Name'],
                            event2Team1Name: contestProp['event2Team1Name'],
                            event2Team2Name: contestProp['event2Team2Name'],

                            team1locationType: contestProp['team1locationType'],
                            team2locationType: contestProp['team2locationType'],
                            team1locationTypePlayer2: contestProp['team1locationTypePlayer2'],
                            team1AbbrPlayer2: contestProp['team1AbbrPlayer2'],
                            team2AbbrPlayer2: contestProp['team2AbbrPlayer2'],
                            underPoints: contestProp['underPoints'],
                            venueState: contestProp['venueState'],
                            selected: false,
                            minPropsToPick: contestProp['minPropsToPick']
                        });

                        pickObj[contestProp.propId] = {} || pickObj[contestProp.propId];
                        pickObj[contestProp.propId] = {
                            selected: null,
                            icePick: false,
                            overPoints: contestProp.player1Points,
                            underPoints: contestProp.player2Points,
                            selectedPoints: 0,
                            isUnderSelected: false,
                            isOverSelected: false,
                            icePrimary: false,
                            propDisabled: contestProp.propDisabled,
                            playerId: 0
                        };
                        this.setState({
                            pickObj: pickObj
                        });
                        if (importPicks === true) {
                            this.checkForSelection(contestProp.propId, userPropPick, contestProp);
                        }
                    });

                    this.setState({ picks: picks, minimumPick: picks[0].minPropsToPick });
                    this.addPicks();
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            });

        fetch(process.env.REACT_APP_SERVER_URL + 'adminContest/get-ice-prop-description', getOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.setState({ tooltip: data['response']['description'] });

                }
            });
    }

    // to check the selections params id & userPropPick
    checkForSelection(id, userPropPick, picks) {
        let pickObj = this.state.pickObj;
        let icePlayerArray = this.state.icePlayerArray;

        if (userPropPick !== null) {
            pickObj[id].icePick = userPropPick.isICE;
            pickObj[id].icePrimary = userPropPick.icePrimary;
            pickObj[id].isOverSelected = userPropPick.isOver;
            pickObj[id].isUnderSelected = !userPropPick.isOver;
            pickObj[id].selected = true;
            pickObj[id].selectedPoints = userPropPick.userPoints;
            pickObj[id].iceSecondary = !userPropPick.icePrimary;
            pickObj[id].playerId = userPropPick.isOver ? picks.player1.id : picks.player2.id;
            icePlayerArray.push(id);

            this.setState({ pickObj: pickObj, icePlayerArray: icePlayerArray });
        }
    }

    /* function for calculations of pick */
    addPicks() {
        let pickObj = this.state.pickObj;
        let ice = this.state.ice;
        let icePick = this.state.icePick;
        let picked = this.state.picked;
        let maximumPoint = this.state.maximumPoint;
        let minimumPick = this.state.minimumPick;
        for (let id in pickObj) {
            if (pickObj.hasOwnProperty(id)) {
                if (pickObj[id].selected) {
                    if (pickObj[id].icePick) {
                        icePick++
                    } else {
                        picked++;
                        maximumPoint += pickObj[id].selectedPoints;
                    }
                }

            }
        }
        if (icePick === 2 && picked === minimumPick) {
            ice = true;
        } else {
            ice = false;
        }
        this.setState({
            pickObj: pickObj,
            ice: ice,
            icePick: icePick,
            picked: picked,
            maximumPoint: maximumPoint
        });
    }


    componentDidMount() {
        picksBody['contestId'] = this.props.match.params.contestId;
        picksBody['userContestId'] = this.props.match.params.userContestId;
        requestOptions['body'] = JSON.stringify(picksBody);
        this.fetchNewPicksData(requestOptions, 'contests/availableProps');
    }

    checkLocation() {
        $('.loader').addClass('show');
        navigator.geolocation.getCurrentPosition(position => {
            let locationCheckBody = {
                Latitude: position.coords.latitude,
                Longitude: position.coords.longitude
            };

            const locationCheckOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
                },
                body: JSON.stringify(locationCheckBody)
            };

            fetch(process.env.REACT_APP_SERVER_URL + 'users/customer-identity-service', locationCheckOptions)
                .then(response => response.json())
                .then(data => {
                    this.checkReasonCodes(data['response']['responseMessage'], data['response']['locationDetail']['ReasonCodes']);
                });
        }, error => {
            $('.loader').removeClass('show');
            messages('error', 'Permission Denied: Unable to Determine Location.');
        }, { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true });
    }

    checkReasonCodes(responseMessage, reasonCodes) {
        if (responseMessage === "No error.") {
            if ((reasonCodes.indexOf("LL-GEO-US-NE") > -1) && (reasonCodes.indexOf("ID-UA-19") > -1)) {
                messages('error', 'Permission Denied Because You Are In An Illegal State.');
                return false;
            }
            if ((reasonCodes.indexOf("LL-GEO-US-MA") > -1) && (reasonCodes.indexOf("ID-UA-21") > -1)) {
                messages('error', 'Permission Denied Because You Are In An Illegal State.');
                return false;
            }

            for (var i = 0; i < reasonCodes.length; i++) {
                if ((reasonCodes[i].indexOf("LL-BLOCK") > -1) || (reasonCodes[i].indexOf("LL-FAIL") > -1) || (reasonCodes[i].indexOf("ID-UNKN") > -1) ||
                    (reasonCodes[i].indexOf("ID-FAIL") > -1) || (reasonCodes[i].indexOf("ID-UA18") > -1) || (reasonCodes[i].indexOf("LL-UNKN") > -1)) {
                    messages('error', 'Permission Denied Because You Are In An Illegal State.');
                    return false;
                }
            }
        }
        this.submitPicks();
    }

    /*Validate picks and proceed to pay */
    submitPicks() {
        let payData = {
            numberOfPicks: this.state.picked,
            numberOfEntries: 1,
            entryFee: this.props.contest.entryFee,
            maxEntriesPerUser: this.props.contest.maxEntriesPerUser,
            maximumPoints: this.state.maximumPoint
        };
        this.setState({ payData: payData });

        if (this.state.picked < this.state.minimumPick) {
            messages('error', 'Please select ' + (this.state.minimumPick - this.state.picked) + ' more props');

        } else if (this.state.icePick < 2) {
            if (this.state.icePick < 1) {
                messages('error', 'Please select ' + (2 - this.state.icePick) + ' ICE props');
            } else {
                messages('error', 'Please select ' + (2 - this.state.icePick) + ' more ICE props');
            }

        } else {
            $('.pay-modal').addClass('show');
            $('.number-of-entries').val('1');
        }
        $('.loader').removeClass('show');
    }


    handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        value = value.replace(/[^0-9]+/g, '');
        if ((value / 1) >= this.state.payData.maxEntriesPerUser) {
            value = this.state.payData.maxEntriesPerUser;
        }

        $('.number-of-entries').val(value);

        this.setState({
            [name]: value
        });
        this.setState({
            [name]: value
        });
    }

    /*to get picks array */
    checkForPicksObj() {
        let pickObj = this.state.pickObj;
        let picksArray = [];
        for (let i in pickObj) {
            if (pickObj.hasOwnProperty(i)) {
                if (pickObj[i].selected) {
                    picksArray.push({
                        adminContestPropsId: i,
                        ice: pickObj[i].icePick,
                        over: pickObj[i].isOverSelected,
                        icePrimary: pickObj[i].icePrimary,
                        playerId: pickObj[i].playerId
                    })
                }
            }
        }
        return picksArray
    }

    // checkForPicksObjOLD() {
    //     let pickObj = this.state.pickObj;
    //     let picksArray = this.state.picksArray;
    //     for (let i in pickObj) {
    //         if (pickObj.hasOwnProperty(i)) {
    //             if (pickObj[i].selected) {
    //                 picksArray.push({
    //                     adminContestPropsId: i,
    //                     ice: pickObj[i].icePick,
    //                     over: pickObj[i].isOverSelected,
    //                     icePrimary: pickObj[i].icePrimary,
    //                     playerId: pickObj[i].playerId
    //                 })
    //             }
    //         }
    //     }
    //     this.setState({picksArray: picksArray});
    // }

    pay() {
        $('.loader').addClass('show');


        
        if (this.state.numberOfEntries > this.state.payData.maxEntriesPerUser) {
            $('.loader').removeClass('show');
            messages('error', 'Maximum entries allowed for this contest are ' + this.maxEntriesPerUser);
        } else if (this.state.numberOfEntries === 0 || this.state.numberOfEntries === null) {
            $('.loader').removeClass('show');
            messages('error', 'Please provide at least 1 entry ' + this.maxEntriesPerUser);
        } else {
            let propPickList = this.checkForPicksObj();

            let requestPaymentObject = {};
            let state = 0;

            if (this.state.userContestId) {
                requestPaymentObject = {
                    adminContestsId: this.state.contestId,
                    propPickList: propPickList,
                    maxPoints: this.state.maximumPoints,
                    userContestId: this.state.userContestId
                };
                state = 2;
            } else {
                requestPaymentObject = {
                    adminContestsId: this.state.contestId,
                    entryCount: this.state.numberOfEntries,
                    amountPaid: this.state.payData.entryFee * this.state.numberOfEntries,
                    propPickList: propPickList,
                    maxPoints: this.state.maximumPoints,
                    userContestId: this.state.userContestId
                };
                state = 0;
            }

            const getPaymentOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
                },
                body: JSON.stringify(requestPaymentObject)
            };


            // fetch(`${process.env.REACT_APP_LAMBDA_COMBO_URL}userContests/enterContest/` + state, getPaymentOptions)
            fetch(`${process.env.REACT_APP_SERVER_URL}userContests/enterContest/` + state, getPaymentOptions)
                .then(response => response.json())
                .then(data => {
                    if (data['success']) {
                        //nelsonga - contest
                        if(window.dataLayer) {
                            window.dataLayer.push({
                                'event': 'Contest_Entry_Success'
                            })
                        }
            
                        try {
                            window.contestEntryScript(document, "script")
                        } catch (error) {
                            console.error(error);
                        }
                        $('.loader').removeClass('show');
                        messages('success', data['message']);
                        if (this.state.userContestId) {
                            this.props.history.push(process.env.REACT_APP_START_URL + 'my-contests/upcoming');
                        } else {
                            this.props.history.push(process.env.REACT_APP_START_URL + 'contest-lobby');
                        }
                    } else {
                        $('.loader').removeClass('show');
                        messages('error', data['message']);
                    }
                });

            // const getWalletBalanceOptions = {
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Access-Control-Allow-Origin': '*',
            //         'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            //     }
            // };

            // fetch(process.env.REACT_APP_SERVER_URL + 'users/get-wallet-balance', getWalletBalanceOptions)
            //     .then(response => response.json())
            //     .then(data => {
            //         if (data['success']) {
            //             if (data['response']['walletBalance'] < this.state.payData.entryFee) {
            //                 $('.loader').removeClass('show');
            //                 messages('error', 'You have insufficient balance to withdraw your balance');
            //             } else {
            // const getPaymentOptions = {
            //     method: 'post',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Access-Control-Allow-Origin': '*',
            //         'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            //     },
            //     body: JSON.stringify(requestPaymentObject)
            // };

            // fetch(process.env.REACT_APP_SERVER_URL + 'contests/enter/' + state, getPaymentOptions)
            //     .then(response => response.json())
            //     .then(data => {
            //         if (data['success']) {
            //             try {
            //                 window.contestEntryScript(document,"script")
            //             } catch (error) {
            //                 console.error(error);
            //             }                    
            //             messages('success', data['message']);
            //             if (this.state.userContestId) {
            //                 this.props.history.push(process.env.REACT_APP_START_URL + 'my-contests/upcoming');
            //             } else {
            //                 this.props.history.push(process.env.REACT_APP_START_URL + 'contest-lobby');
            //             }
            //         } else {
            //             messages('error', data['message']);
            //             $('.loader').removeClass('show');
            //         }
            //         $('.loader').removeClass('show');
            //     });
            // }
            //     } else {
            //         messages('error', data['message']);
            //         $('.loader').removeClass('show');
            //     }
            // });
        }
    }

    /*Close Pay popup */
    closePayModal() {
        $('.pay-modal').removeClass('show');
    }

    /*to get Import Picks Data */
    fetchImportPicksData() {
        $('.loader').addClass('show');
        const importPicksBody = {
            contestId: this.state.contestId,
            userContestId: this.state.userContestId
        };

        const getImportPicksOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            },
            body: JSON.stringify(importPicksBody)
        };

        // fetch(`${process.env.REACT_APP_LAMBDA_COMBO_URL}import-export-picks/importPicks`, getImportPicksOptions)
        fetch(`${process.env.REACT_APP_SERVER_URL}import-export-picks/importPicks`, getImportPicksOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.setState({ importPicks: data['response']['data'] });
                } else {
                    messages('error', data['message']);
                }
                $('.loader').removeClass('show');
            });
    }

    /*open import picks popup and run function to get Import Picks Data */
    openImportPicks() {
        this.fetchImportPicksData();
        $('.import-picks-modal').addClass('show');
    }

    /*Close import popup */
    closeImportPicksModal() {
        $('.import-picks-modal').removeClass('show');
    }

    /*Import selected contest*/
    applyImportPicks() {
        const userContestId = $('input[name="import-pick"]:checked').val();
        const userImportedContest = $('input[name="import-pick"]:checked').attr('rel');

        if (userContestId && userImportedContest) {
            picksBody['contestId'] = this.state.contestId;
            picksBody['userContestId'] = userContestId;
            picksBody['userImportedContest'] = userImportedContest;
            requestOptions['body'] = JSON.stringify(picksBody);
            this.clearAllSelection();
            this.getImportPicksData(requestOptions, 'userContests/userImportedContests', true);
            this.closeImportPicksModal();
        }
    }

    /*Open Invite friend popup */
    openInviteFriendModal() {
        $('.invite-friend-popup').addClass('show');
    }

    /*Close Invite friend popup */
    closeInviteFriendModal() {
        $('.invite-friend-popup').removeClass('show');
    }

    inviteFriend() {
        $('.invite-friend-popup').find('.error').hide();
        const email = $('.friend-email').val();

        if (!validateEmail(email)) {
            $('.invite-friend-popup').find('.error').show();
            return
        }

        $('.loader').addClass('show');
        const inviteFriendBody = {
            inviteeEmail: email,
            contestId: this.state.contestId
        };

        const getInviteFriendOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            },
            body: JSON.stringify(inviteFriendBody)
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'invitee/user-invitee', getInviteFriendOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    messages('success', data['message']);
                    this.closeInviteFriendModal();
                } else {
                    messages('error', data['message']);
                }
                $('.loader').removeClass('show');
            });


    }

    exportPicks() {

        $('.loader').addClass('show');

        Mixpanel.track('web:user_clicked_export_picks');

        const {saveAsCsv} = useJsonToCsv();
        if (this.state.exportPicksData !== null) {
            const data = this.state.exportPicksData['model']['csvData'];
            const fields = toObject(data, this.state.exportPicksData['model']['csvHeader']);
            const filename = 'Picks';
            saveAsCsv({fields, data, filename});
        } else {
            messages('error', 'No Data Available!');
        }
        $('.loader').removeClass('show');

    }

    triggerPlayerStatsPopup(player) {
        this.playerStats.current.openPlayerStatsPopup(player);
    }

    getMatchupStylingHomePlayer1(locationType, leagueType, pick) {
        let uniform = pick.player1.uniform !== null ? parseInt(pick.player1.uniform) : null
        if (leagueType === "PUBG") {
          return <span>{pick.team1Name}</span>
        }
        if(locationType !== 'home') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                return <span>#{pick.player1.uniform} {pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === null) {
                return <span>{pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === 0) {
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
        }

    }
    getMatchupStylingAwayPlayer1(locationType, leagueType, pick) {
        let uniform = pick.player1.uniform !== null ? parseInt(pick.player1.uniform) : null
        if(locationType !== 'away') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                return <span>#{pick.player1.uniform} {pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === null) {
                return <span>{pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === 0) {
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
        }
    }

    getMatchupStylingHomePlayer2(locationType, leagueType, pick) {
        // console.log(pick)
        if (leagueType === "PUBG") {
            return <span>{pick.team1Name}</span>
        }
        let uniform = pick.player2.uniform !== null ? parseInt(pick.player2.uniform) : null
        if(locationType !== 'home') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                return <span>#{pick.player2.uniform} {pick.player2.positionAbbreviation} | {pick.team1Name} vs. {pick.team2Name}</span>
            }
            else if(uniform === null) {
                return <span>{pick.player2.positionAbbreviation} | {pick.team1Name} vs. {pick.team2Name}</span>
            }
            else if(uniform === 0) {
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.player2.positionAbbreviation} | {pick.team1Name} vs. {pick.team2Name}</span>
            }
        }

    }
    getMatchupStylingAwayPlayer2(locationType, leagueType, pick) {
        console.log(pick)
        let uniform = pick.player2.uniform !== null ? parseInt(pick.player2.uniform) : null
        if(locationType !== 'away') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                return <span>#{pick.player2.uniform} {pick.player2.positionAbbreviation} | {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
            else if(uniform === null) {
                return <span>{pick.player2.positionAbbreviation} | {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
            else if(uniform === 0) {
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.player2.positionAbbreviation} | {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
        }
    }

    render() {
        const pickObj = this.state.pickObj;
        console.log(this.state.picks);
        return (
            <div className="tab-container versus active" rel="making-picks">
                <div className="actions">
                    <div className="left">
                        <button onClick={() => this.openImportPicks()} className="import-picks" title="Import Picks">Import Picks</button>
                        <button onClick={() => this.exportPicks()} className="export-picks" title="Export Picks">Export Picks</button>
                    </div>
                    <div className="right">
                        <button onClick={() => this.clearAllSelection()} className="clear-selection" title="Clear Selection">Clear Selection</button>
                        <button onClick={() => this.openInviteFriendModal()} className="invite-friends" title="Invite Friends">Invite Friends</button>
                    </div>
                </div>
                <div className="making-picks-listing">
                    <div className="head">
                        <span>Player 1</span>
                        <span>VS</span>
                        <span>Player 2</span>
                    </div>
                    <ul className="listing">
                        {this.state.picks.length ? this.state.picks.map(pick => (
                            <li key={pick.propId} className={pickObj[pick.propId].propDisabled ? "disabled" : ""}>
                                <div className="player">
                                    <span>
                                        <Moment utc local format="hh:mm a">{pick.startTime}</Moment>, <Moment utc local format="ddd, MMM Do, YYYY">{pick.startTime}</Moment>
                                    </span>
                                    <img onClick={() => this.triggerPlayerStatsPopup(pick.player1)} src={pick.player1.image} alt={pick.player1.firstName} />
                                    <h3 onClick={() => this.triggerPlayerStatsPopup(pick.player1)}>
                                        {pick.player1.firstName} {pick.player1.lastName}
                                        {pick.team1locationType === 'home' && pick.player1.leagueType !== "UFC" && pick.player1.leagueType !== "PUBG"
                                            ? (<span>{pick.player1.positionAbbreviation}-{pick.player1.teamAbbr}| {pick.team2Abbr} @ {pick.team1Abbr}</span>)
                                            : this.getMatchupStylingHomePlayer1(pick.team1locationType, pick.player1.leagueType, pick)
                                        }
                                        {pick.team1locationType === 'away' && pick.player1.leagueType !== "UFC"
                                            ? (<span>{pick.player1.positionAbbreviation}-{pick.player1.teamAbbr}| {pick.team1Abbr} @ {pick.team2Abbr}</span>)
                                            : this.getMatchupStylingAwayPlayer1(pick.team1locationType, pick.player1.leagueType, pick)
                                        }
                                    </h3>
                                    <h4>
                                        <span>Total | </span>
                                        {pick.player1.propParameters.length ?
                                            <span>({pick.player1.propParameters.map((prop, index) => (<span className="prop" key={prop}>{index > 0 ? ' + ' : ''}{prop}</span>))})</span>
                                            : ''}
                                        <span> +{pick.player1Advantage}</span>
                                    </h4>
                                    <button className={pickObj[pick.propId].isOverSelected === true ? "active" : ''}
                                        onClick={() => this.pointsSelect(pick.propId, 'over', pick.player1.id)}>{pick.player1Points} PTS
                                    </button>
                                </div>
                                <div className="vs">
                                    <span>VS</span>
                                </div>
                                <div className="player">
                                    <span>
                                        <Moment utc local format="hh:mm a">{pick.startTimePlayer2}</Moment>, <Moment utc local format="ddd, MMM Do, YYYY">{pick.startTimePlayer2}</Moment>
                                    </span>
                                    <img onClick={() => this.triggerPlayerStatsPopup(pick.player2)} src={pick.player2.image} alt={pick.player2.firstName} />
                                    <h3 onClick={() => this.triggerPlayerStatsPopup(pick.player2)}>
                                        {pick.player2.firstName} {pick.player2.lastName}
                                        {pick.team1locationTypePlayer2 === 'home' && pick.player2.leagueType !== "UFC"
                                            ? (<span>{pick.player2.positionAbbreviation}-{pick.player2.teamAbbr}| {pick.team2AbbrPlayer2} @ {pick.team1AbbrPlayer2}</span>)
                                            : this.getMatchupStylingHomePlayer2(pick.team1locationTypePlayer2, pick.player2.leagueType, pick, pick.player2.number)
                                        }
                                        {pick.team1locationTypePlayer2 === 'away' && pick.player2.leagueType !== "UFC"
                                            ? (<span>{pick.player2.positionAbbreviation}-{pick.player2.teamAbbr}| {pick.team1AbbrPlayer2} @ {pick.team2AbbrPlayer2}</span>)
                                            : this.getMatchupStylingAwayPlayer2(pick.team1locationTypePlayer2, pick.player2.leagueType, pick, pick.player2.number)
                                        }
                                    </h3>
                                    <h4>
                                        <span>Total | </span>
                                        {pick.player2.propParameters.length ?
                                            <span>({pick.player2.propParameters.map((prop, index) => (<span className="prop" key={prop}>{index > 0 ? ' + ' : ''}{prop}</span>))})</span>
                                            : ''}
                                    </h4>
                                    <button className={pickObj[pick.propId].isUnderSelected === true ? "active" : ''}
                                        onClick={() => this.pointsSelect(pick.propId, 'under', pick.player2.id)}>{pick.player2Points} PTS
                                    </button>
                                </div>

                                {pickObj[pick.propId].icePick ?
                                    <div className="ice-pick">
                                        {pickObj[pick.propId].icePrimary ?
                                            <span>*ICE PICK 1</span> :
                                            <span>*ICE PICK 2</span>
                                        }
                                    </div>
                                    : ''
                                }
                            </li>
                        )) : ''}
                    </ul>
                </div>
                <div className="totals">
                    <div className="inner-totals">
                        <div>
                            <div>Positions Filled: <span>{this.state.picked}/{this.props.contest.minPropsToPick}</span></div>
                            <div>ICE Pick: <span>{this.state.icePick}</span></div>
                            <div>Maximum Points: <span>{this.state.maximumPoint}</span></div>
                        </div>
                        <p>
                            *ICE prop = In case of Emergency Prop
                            <span>
                                <span>
                                    {this.state.tooltip}
                                </span>
                            </span>
                        </p>
                        <button onClick={this.checkLocation}>Submit</button>
                    </div>
                </div>
                <div className="pay-modal popup">
                    <div className="inner">
                        <button onClick={this.closePayModal} className="close-pay-modal close" title="Close"></button>
                        <h2>Enter Contest</h2>
                        <div>
                            <div>
                                <label>No. of Entries</label>
                                <input onChange={this.handleInputChange} name="numberOfEntries" className="number-of-entries"
                                    readOnly={this.state.payData.maxEntriesPerUser === 1 ? true : false} step="1" type="tel" min="1"
                                    max={this.state.payData.maxEntriesPerUser} />
                            </div>
                            <div>
                                <label>Entry Fee</label>
                                <span>${this.state.payData.entryFee}.00</span>
                            </div>
                            <div>
                                <label>Total Payments</label>
                                <span>${this.state.payData.entryFee * this.state.numberOfEntries}.00</span>
                            </div>
                        </div>
                        <button onClick={this.pay} className="pay-now" title="Pay Now">Pay Now</button>
                    </div>
                </div>
                <div className="import-picks-modal popup">
                    <div className="inner">
                        <button onClick={this.closeImportPicksModal} className="close-import-picks-modal close" title="Close"></button>
                        <h2>Import Picks</h2>
                        <div>
                            <ul>
                                {this.state.importPicks.length ? this.state.importPicks.map(importPick => (
                                    <li key={importPick.userContestId}>
                                        <input type="radio" name="import-pick" rel={importPick.contestId} value={importPick.userContestId} />
                                        <div>
                                            <img src={importPick.gameImage} alt={importPick.name} />
                                            <h4>{importPick.name}</h4>
                                            <h5>Date <Moment utc local format="ddd, MMM Do, YYYY">{importPick.startDate}</Moment> | <Moment utc local
                                                format="hh:mm a">{importPick.startTime}</Moment>
                                            </h5>
                                            <span>Entry FeesL ${importPick.entryFee}.00</span>
                                        </div>
                                    </li>
                                )) : <p>No Contest Found</p>}
                            </ul>
                            <button onClick={this.applyImportPicks} className="apply-picks" title="Done">Done</button>
                        </div>
                    </div>
                </div>
                <div className="invite-friend-popup popup">
                    <div className="inner">
                        <button onClick={this.closeInviteFriendModal} className="close-invite-friend-modal close" title="Close"></button>
                        <h2>Invite Friend</h2>
                        <div>
                            <input className="friend-email" type="email" placeholder="Email" />
                            <button onClick={this.inviteFriend} title="Submit">Submit</button>
                            <span className="error">Valid Email Required</span>
                        </div>
                    </div>
                </div>
                <PlayerStatsPopup ref={this.playerStats} />
            </div>
        );
    }
}

function toObject(data, arr) {
    let count = 0;
    let fields = {};
    for (var key of Object.keys(data[0])) {
        fields[key] = arr[count];
        count++;
    }
    return fields;
}

function validateEmail($email) {
    var emailReg = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return emailReg.test($email);
}

export default withRouter(ContestVersusPicks);
