import React from 'react';
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import './notifications.scss';
import LoggedInHeader from '../Logged-in-header/Logged-in-header';
import $ from 'jquery';
import {isAuthenticated} from "../isAuthenticated";
import Footer from "../footer/footer";
import {messages} from "../messages";
import Moment from "react-moment";
import { Mixpanel } from '../mixpanel/Mixpanel';

let list = [];

let requestNotificationsBody = {
    currentPage: 1,
    currentSize: 10
};

const requestNotificationsOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    },
    body: JSON.stringify(requestNotificationsBody)
};

class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationsList: {}
        };
        this.fetchNotificationsData = this.fetchNotificationsData.bind(this);
        this.notificationRead = this.notificationRead.bind(this);
        this.checkForNotificationType = this.checkForNotificationType.bind(this);
        this.getContestDetails = this.getContestDetails.bind(this);
        this.getContestDetails1 = this.getContestDetails1.bind(this);
    }

    fetchNotificationsData(requestNotificationsOptions) {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'notification/notification-listing', requestNotificationsOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    if (requestNotificationsBody['currentPage'] > 1) {
                        for(let i = 0; i < data['response']['data'].length; i++) {
                            list.push(data['response']['data'][i]);
                        }
                    } else {
                        list = data['response']['data'];
                    }
                    this.setState({notificationsList: list});
                    if(list.length >= requestNotificationsBody['currentSize']) {
                        $('.load-more').addClass('show');
                    }
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            });
    }

    componentDidMount() {
        isAuthenticated('login', '/notifications');
        this.fetchNotificationsData(requestNotificationsOptions);

        Mixpanel.track('web:notifications_opened');
    }
    loadMore() {
        requestNotificationsBody['currentPage'] =  requestNotificationsBody['currentPage'] + 1;
        requestNotificationsOptions['body'] = JSON.stringify(requestNotificationsBody);
        this.fetchNotificationsData(requestNotificationsOptions);
    }

    notificationRead(index) {
        let requestNotificationReadsBody = {
            notificationId: this.state.notificationsList[index]['notificationId'],
        };

        const requestNotificationsReadOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            },
            body: JSON.stringify(requestNotificationReadsBody)
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'notification/read-notification', requestNotificationsReadOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    let notificationsList = this.state.notificationsList;
                    notificationsList[index]["read"] = false;
                    this.setState({notificationsList: notificationsList});

                    this.checkForNotificationType(index);
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            });
    }

    checkForNotificationType(index) {
        const notificationsList = this.state.notificationsList[index];
        if (notificationsList['notificationType'] === 2) {
            this.getContestDetails(notificationsList['contestId']);
        } else if (notificationsList['notificationType'] === 8) {
            this.props.history.push(process.env.REACT_APP_START_URL + 'contest-lobby/'+notificationsList['contestId']);
        } else if (notificationsList['notificationType'] === 11) {
            this.props.history.push(process.env.REACT_APP_START_URL + 'contest-lobby/'+notificationsList['contestId']);
        } else if (notificationsList['notificationType'] === 9) {
            this.props.history.push(process.env.REACT_APP_START_URL + 'contest-lobby/'+notificationsList['contestId']);
        } else if (notificationsList['notificationType'] === 5) {
            messages('error', 'This contest has been cancelled');
        } else if (notificationsList['notificationType'] === 13 || notificationsList['notificationType'] === 16) {
            this.getContestDetails1(notificationsList['contestId'], notificationsList['userContestId']);
        } else if (notificationsList['notificationType'] === 12 || notificationsList['notificationType'] === 4) {
            this.getContestDetails(notificationsList['contestId']);
        } else if (notificationsList['notificationType'] === 17 || notificationsList['notificationType'] === 18 || notificationsList['notificationType'] === 19 || notificationsList['notificationType'] === 20) {
            this.props.history.push(process.env.REACT_APP_START_URL + 'wallet');
        }
    }

    getContestDetails(id){
        $('.loader').addClass('show');
        let getContestDetailsBody = {
            contestId: id
        };

        const getContestDetailsOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            },
            body: JSON.stringify(getContestDetailsBody)
        };


        fetch(process.env.REACT_APP_SERVER_URL + 'contests/contestDetails', getContestDetailsOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.checkForContestStatus(data['response']['contestDetails']);
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            });
    }

    getContestDetails1(id,userContestId){
        $('.loader').addClass('show');
        let getContestDetailsBody = {
            contestId: id,
            userContestId: userContestId
        };

        const getContestDetailsOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            },
            body: JSON.stringify(getContestDetailsBody)
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'contests/contestDetails', getContestDetailsOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.checkForContestStatus(data['response']['contestDetails']);
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            });
    }

    checkForContestStatus(response) {
        if(response.live){
            this.props.history.push(process.env.REACT_APP_START_URL + 'my-contests/live');
            messages('success', 'This contest is live now');
        }else if(response.contestOver){
            this.props.history.push(process.env.REACT_APP_START_URL + 'my-contests/history');
            messages('success', 'This contest is over');
        }else {
            this.props.history.push(process.env.REACT_APP_START_URL + 'contest-lobby');
        }
    }



    render() {
        return (
            <main className="notifications-pages">
                <LoggedInHeader/>
                <div className="inner">
                    <h1>Notifications</h1>
                    <ul>
                        {this.state.notificationsList.length ? this.state.notificationsList.map((item, index) => (
                                <li key={index} className={item.read ? 'read' : ''} onClick={() => this.notificationRead(index)}>
                                    <h2>{item.title}</h2>
                                    <p>{item.message}</p>
                                    <Moment fromNow utc local >{item.timestamp}</Moment>
                                </li>
                            )) :
                            <li className="no-results">
                                <div></div>
                                <h3>No notifications found</h3>
                                <Link to={process.env.REACT_APP_START_URL + "contest-lobby"}>Go Back</Link>
                            </li>
                        }

                    </ul>
                    <button onClick={this.loadMore} className="load-more">Load More</button>
                </div>
                <Footer/>
            </main>
        );
    }
}

export default withRouter(Notifications);
