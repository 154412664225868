import React from "react";
import { Link } from "react-router-dom";
import "./my-contests-history.scss";
import LoggedInHeader from "../Logged-in-header/Logged-in-header";
import $ from "jquery";
import { isAuthenticated } from "../isAuthenticated";
import Moment from "react-moment";
import Footer from "../footer/footer";
import { messages } from "../messages";
import { Mixpanel } from "../mixpanel/Mixpanel";

let historyList = [];

let requestHistoryBody = {
  leagueType: null,
  contestType: null,
  currentPage: 1,
  currentSize: 10,
  sortType: 0,
};

const requestHistoryOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Token: window.localStorage.getItem("userData")
      ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
      : false,
  },
  body: JSON.stringify(requestHistoryBody),
};

class MyContestsHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historyList: historyList,
      call: false,
    };
    $(".loader").addClass("show");
    this.fetchHistoryData = this.fetchHistoryData.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.sortHistory = this.sortHistory.bind(this);
  }

  fetchHistoryData(requestHistoryOptions) {
    $(".loader").addClass("show");
    fetch(
      process.env.REACT_APP_SERVER_URL + "contests/get-my-contest-history",
      requestHistoryOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          if (requestHistoryBody["currentPage"] > 1) {
            for (let i = 0; i < data["response"]["data"].length; i++) {
              historyList.push(data["response"]["data"][i]);
            }
          } else {
            historyList = data["response"]["data"];
          }
          this.setState({ historyList: historyList, call: true });

          if (
            data["response"]["data"].length >= requestHistoryBody["currentSize"]
          ) {
            $(".load-more").addClass("show");
          } else {
            $(".load-more").removeClass("show");
          }
          $(".loader").removeClass("show");
        } else {
          messages("error", data["message"]);
          $(".loader").removeClass("show");
        }
      });
  }

  componentDidMount() {
    isAuthenticated("login", "/my-contests/history");
    this.fetchHistoryData(requestHistoryOptions);

    $(".sort-events").on("click", function () {
      $(".sort-events").find("div").toggleClass("open");
    });

    $(".mobile-select-sport").on("click", ".selected", function () {
      $(".mobile-select-sport").find(".selection").toggleClass("open");
    });

    Mixpanel.track("web:my_contests_history_opened");
  }

  loadMore() {
    requestHistoryBody["currentPage"] = requestHistoryBody["currentPage"] + 1;
    requestHistoryOptions["body"] = JSON.stringify(requestHistoryBody);
    this.fetchHistoryData(requestHistoryOptions);
  }

  sortHistory(event) {
    requestHistoryBody["sortType"] = parseInt(event.target.getAttribute("rel"));
    requestHistoryOptions["body"] = JSON.stringify(requestHistoryBody);
    this.fetchHistoryData(requestHistoryOptions);
    $(".sort-events").find("li").removeClass("active");
    event.target.classList.add("active");
    $(".sort-events").find("div").removeClass("open");

    Mixpanel.track("web:user_sorted_my_contests_history", {
      "Sort By": event.target.getAttribute("rel"),
    });
  }

  render() {
    return (
      <main className="my-contest-history">
        <LoggedInHeader />
        <div className="inner">
          <nav className="filters">
            <button>Date</button>
            <button>Entry Fee</button>
            <button>Prize Pool</button>
            <button>Number of Entries</button>
          </nav>
          <aside>
            <h2>Select Category</h2>
            <nav className="my-contest-nav">
              <Link
                className="live "
                to={process.env.REACT_APP_START_URL + "my-contests/live"}
                title="live"
              >
                Live
              </Link>
              <Link
                className="upcoming"
                to={process.env.REACT_APP_START_URL + "my-contests/upcoming"}
                title="Upcoming"
              >
                Upcoming
              </Link>
              <Link
                className="History active"
                to={process.env.REACT_APP_START_URL + "my-contests/history"}
                title="History"
              >
                History
              </Link>
            </nav>
          </aside>
          <section className="events">
            <div className="mobile-select-sport">
              <h2>Select Category</h2>
              <div className="selected">History</div>
              <div className="selection">
                <nav className="my-contest-nav">
                  <Link
                    className="live"
                    to={process.env.REACT_APP_START_URL + "my-contests/live"}
                    title="live"
                  >
                    Live
                  </Link>
                  <Link
                    className="upcoming"
                    to={
                      process.env.REACT_APP_START_URL + "my-contests/upcoming"
                    }
                    title="Upcoming"
                  >
                    Upcoming
                  </Link>
                  <Link
                    className="History active"
                    to={process.env.REACT_APP_START_URL + "my-contests/history"}
                    title="History"
                  >
                    History
                  </Link>
                </nav>
              </div>
            </div>
            <h2>History Events</h2>
            <div className="sort-listing">
              <div className="sort-events">
                <label>Sort</label>
                <div>
                  <ul>
                    <li onClick={this.sortHistory} className="active" rel="0">
                      Contest Ended Last
                    </li>
                    <li onClick={this.sortHistory} rel="1">
                      Alphabetically
                    </li>
                    <li onClick={this.sortHistory} rel="5">
                      Maximum Prize
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {this.state.call ? (
              <div className="events-listing">
                <ul>
                  {this.state.historyList.length ? (
                    this.state.historyList.map((item) => (
                      <li key={item["userContestId"]}>
                        <Link
                          to={
                            process.env.REACT_APP_START_URL +
                            "my-contests/history/" +
                            item["contestId"] +
                            "/" +
                            item["userContestId"]
                          }
                          title="View Contest"
                        >
                          <div className="desktop">
                            <div className="top">
                              <img src={item["gameImage"]} alt={item["name"]} />
                              <div className="headings">
                                <h3>
                                  {item["name"]}{" "}
                                  {item["featured"] ? (
                                    <span>Featured</span>
                                  ) : (
                                    ""
                                  )}
                                  {item["totalEntries"] ===
                                  item["maxEntriesAllowed"] ? (
                                    <span className="contest-filled">
                                      Contest Filled
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </h3>
                                <h4>
                                  Total Prize:{" "}
                                  {formatNumber(item["totalPrize"])}
                                </h4>
                              </div>
                              <div className="info">
                                <h3>
                                  <span>Total Prize</span>
                                  {formatNumber(item["totalPrize"])}
                                </h3>
                                <h3>
                                  <span>Winner Point</span>
                                  {item["winnersPoint"]}
                                </h3>
                              </div>
                            </div>
                            <div className="bottom">
                              <div className="left">
                                <p>
                                  Date:{" "}
                                  <Moment utc local format="dddd, MMM Do, YYYY">
                                    {item["startTime"]}
                                  </Moment>
                                  <br />
                                  No. of Prize: {item["noOfEntrantsPrizePaid"]}
                                </p>
                                <p>
                                  Ranking: {item["ranking"]}/
                                  {item["totalParticipents"]}
                                  <br />
                                  Points Made: {item["pointsMade"]}
                                </p>
                                <p>
                                  Prize Earned:{" "}
                                  {formatNumber(item["earnedPrize"])}
                                  <br />
                                  {item["positionAwayFromLastWinningPosition"] >
                                  0 ? (
                                    <span>
                                      Places away from last paid position:{" "}
                                      {
                                        item[
                                          "positionAwayFromLastWinningPosition"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    " "
                                  )}
                                </p>
                              </div>
                              <div className="right">
                                <button title="View Contest">
                                  View Contest
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="mobile">
                            <div className="top">
                              <img src={item["gameImage"]} alt={item["name"]} />
                              <h3>
                                {item["name"]}{" "}
                                {item["featured"] ? <span>Featured</span> : ""}
                                {item["totalEntries"] ===
                                item["maxEntriesAllowed"] ? (
                                  <span className="contest-filled">
                                    Contest Filled
                                  </span>
                                ) : (
                                  ""
                                )}
                              </h3>
                              <span className="clearfix"></span>
                              <h4>
                                <span>Total Prize</span>$
                                {formatNumber(item["totalPrize"])}
                              </h4>
                              <h4>
                                <span>Ranking</span>
                                {item["ranking"]}/{item["totalParticipents"]}
                              </h4>
                            </div>
                            <div className="bottom">
                              <p>
                                Date:{" "}
                                <span>
                                  <Moment utc local format="dddd, MMM Do, YYYY">
                                    {item["startTime"]}
                                  </Moment>
                                </span>
                              </p>
                              <p>
                                No. of Prize:{" "}
                                <span>{item["noOfEntrantsPrizePaid"]}</span>
                              </p>
                              {item["positionAwayFromLastWinningPosition"] >
                              0 ? (
                                <p>
                                  Places away from last paid:{" "}
                                  <span>
                                    {
                                      item[
                                        "positionAwayFromLastWinningPosition"
                                      ]
                                    }
                                  </span>
                                </p>
                              ) : (
                                " "
                              )}
                              <p>
                                Prize Earned:{" "}
                                <span>{formatNumber(item["earnedPrize"])}</span>
                              </p>
                              <p>
                                Points Made: <span>{item["pointsMade"]}</span>
                              </p>
                              <p>
                                Winner Point:{" "}
                                <span>{item["winnersPoint"]}</span>
                              </p>
                              <button title="View Contest">View Contest</button>
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))
                  ) : (
                    <li className="no-results">
                      <div></div>
                      <h3>No Events Found</h3>
                      {/* <span>Contests Usually Posted at 10 AM EST</span> */}
                      <Link
                        to={process.env.REACT_APP_START_URL + "contest-lobby"}
                      >
                        Go Back
                      </Link>
                    </li>
                  )}
                </ul>
                <button onClick={this.loadMore} className="load-more">
                  Load More
                </button>
              </div>
            ) : (
              ""
            )}
          </section>
        </div>
        <Footer />
      </main>
    );
  }
}

function formatNumber(num) {
  return "$" + (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default MyContestsHistory;
