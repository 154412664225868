import React from 'react';

import './faq.scss';

const FrequentlyAskedQuestions = () => {

  return(
    <div className="faq info-page">
      <div className="faq-header info-page-header">
        <h2>Frequently Asked Questions</h2>
      </div>
      <div id="faq-body" className="info-page-body">
        {/* <div className="top-scollbar-cover"></div>
        <div className="bottom-scollbar-cover"></div> */}
        <strong>How can I deposit funds into my account?</strong>
        <p>ThriveFantasy accepts deposits from major credit/debit cards Visa, Mastercard, and Discover, as well as via PayPal and E-Check. Follow this link to Deposit Now.</p>
        <strong>How does my deposit bonus work?</strong>
        <p>Deposit bonuses are added instantly as additional funds to a users' account. All users are eligible for a deposit bonus on their first deposit as well as occasional promotional opportunities. All first deposit bonuses are subject to a 4x rollover requirement.</p>
        <strong>How can I receive a deposit bonus?</strong>
        <p>Keep an eye on your email and our social media pages for any updates on deposit bonus promotions that we are running.</p>
        <strong>Is there an age restriction to play on ThriveFantasy?</strong>
        <p>Yes, you must be at least 18 years of age to play and participate on ThriveFantasy (21+ in Massachusetts, 19+ in Nebraska).</p>
        <strong>How long does it take to withdraw funds from my account?</strong>
        <p>All withdrawals require a minimum of 48 to 72 hours before they are processed. </p>
        <strong>What kind of contests does ThriveFantasy offer?</strong>
        <p>ThriveFantasy offers a variety of different contests including Traditional, Versus, and the Prop Lobby. Our contests cover traditional sports such as the NFL, NBA, MLB, PGA, and Cricket, as well as Esports titles CS:GO, Dota 2, and League of Legends with more titles coming soon. For more information, check out our “How to Play” page. </p>
        <strong>How much does it cost to play on ThriveFantasy?</strong>
        <p>ThriveFantasy offers a variety of contests that range from FREE to thousands of dollars!</p>
        <strong>How do contest prizes work?</strong>
        <p>Check the Prize Payouts section in each contest to see where you must finish on the leaderboard in order to qualify for a prize payout. In the event of a tie, payouts are split evenly between users.</p>
        <strong>How are contests scored?</strong>
        <p>Each prop has a fantasy point value associated with it based on the probability for it to occur. If you choose the Over or Under correctly, your lineup earns the amount of points associated with your selection.</p>
        <strong>How come my contest was cancelled?</strong>
        <p>With exception to Guaranteed Prize pool contests, all other our contests must fill to at least 60% capacity. If a contest is cancelled, all entrants will receive a full refund deposited directly into their account. (Head to Heads, 3-Man and 5-Man contests must also fill)</p>
        <strong>What is an ICE Pick?</strong>
        <p>In Case of Emergency (ICE) Picks were created to ensure every user has a fair chance at each contest. If a certain athlete is unable to play in their respective game, an ICE pick will automatically be substituted into your lineup for that athlete. Your ICE Pick #1 is your primary substitute while your ICE Pick #2 is your secondary substitute.</p>
      </div>
    </div>
  )
}

export default FrequentlyAskedQuestions;