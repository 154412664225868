import React from 'react';
import { Switch, Route } from "react-router-dom";
import Header from '../header/header';
import Footer from '../footer/footer';

import EnvyLanding from './promo_pages/envy/envy-landing.js';
import FJLanding from './promo_pages/fj/fj-landing';
import ColinLanding from './promo_pages/colin/colin-landing';
import Promo from './promo_pages/promo/promo';

class PromoLanding extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <main className="landing-page">
                <Header onLandingPage={true} />
                <Switch>
                    <Route path={process.env.REACT_APP_START_URL + "envy"} component={EnvyLanding} />
                    <Route path={process.env.REACT_APP_START_URL + "fj"} component={FJLanding} />
                    <Route path={process.env.REACT_APP_START_URL + "colin"} component={ColinLanding} />
                    <Route path={process.env.REACT_APP_START_URL + "promo"} component={Promo} />
                </Switch>
                <Footer />
            </main>
        );
    }
}


export default PromoLanding;

