import React from "react";
import { Link, withRouter } from "react-router-dom";
import "./home.scss";
import Header from "../header/header";
import Footer from "../footer/footer";
import $ from "jquery";
import Hammer from "react-hammerjs";
import { isAuthenticated } from "../isAuthenticated";
import TemplateALanding from "../promo_landing/promo_pages/templateA/template-a";
import TemplateBLanding from "../promo_landing/promo_pages/templateB/template-b";
import { messages } from "../messages";

import axios from "axios";

//images
import playOnline from "../../assets/images/online.png";
import appleStore from "../../assets/images/apple store.png";
import appleTemplate from "../../assets/images/appleTemplate.png";
import googlePlay from "../../assets/images/google play.png";
import slid1 from "../../assets/images/mockup2.png";
import slid2 from "../../assets/images/mockup1.png";
import slid3 from "../../assets/images/mockup2.png";
import logo from "../../assets/images/logo.png";
import logoNFL from "../../assets/images/logo-nfl.png";
import logoNBA from "../../assets/images/logo-nba.png";
import logoDota from "../../assets/images/logo-dota.png";
import howToPlay1 from "../../assets/images/svg/1.png";
import howToPlay2 from "../../assets/images/svg/2.png";
import howToPlay3 from "../../assets/images/svg/3.png";
import pag from "../../assets/images/mockup.png";
import pagMobile from "../../assets/images/mobile/mobile-mockup.png";
import playSBNFL from "../../assets/images/mobile/SBNFL.png";
import playPGA from "../../assets/images/mobile/playPGA.png";
import playLOL from "../../assets/images/mobile/playLOL.png";
import playCSGO from "../../assets/images/mobile/playCSGO.png";
import playNBA from "../../assets/images/mobile/playNBA.png";
import playCricket from "../../assets/images/mobile/playCricket.png";
import playMLB from "../../assets/images/mobile/playMLB.png";
import testimonial1 from "../../assets/images/mobile/Testimonial1.png";
import testimonial2 from "../../assets/images/mobile/Testimonial2.png";
import testimonial3 from "../../assets/images/mobile/Testimonial3.png";
import testimonial4 from "../../assets/images/mobile/Testimonial4.png";
import NFLW122 from "../../assets/images/mobile/NFLW122.png";
import jagsLogo from "../../assets/images/jags-logo-small.png";
import chargersLogo from "../../assets/images/chargers-logo-small.png";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sport: this.props.match.params.sport,
      image: slid1,
      intervalId: 0,
      promoCode: null,
      landingPage: null,
      redirectLink: null,
      playnowRedirectLink: null,
      lpImage: null,
    };
    this.sliderCount = 0;
    this.sliderImageRef = React.createRef();
    this.getImage = this.getImage.bind(this);
    this.getReview = this.getReview.bind(this);
    this.adjustSliderStyling = this.adjustSliderStyling.bind(this);
    this.sliderLength = 4;
    this.getPromoCode = this.getPromoCode.bind(this);
    this.getFantasyAlarmId = this.getFantasyAlarmId.bind(this);
    this.getRotogrindersId = this.getRotogrindersId.bind(this);
    this.getFantasyProsId = this.getFantasyProsId.bind(this);
    this.getIABtag = this.getIABtag.bind(this);
    this.collectQueryParameters = this.collectQueryParameters.bind(this);
  }

  getImage() {
    switch (this.state.sport) {
      case "pga":
        return <img src={playPGA} alt="slid 1" className="sportSpecific" />;
        break;

      case "csgo":
        return <img src={playCSGO} alt="slid 1" className="sportSpecific" />;
        break;

      case "nba":
        return <img src={playNBA} alt="slid 1" className="sportSpecific" />;
        break;

      case "lol":
        return <img src={playLOL} alt="slid 1" className="sportSpecific" />;
        break;

      case "mlb":
        return <img src={playMLB} alt="slid 1" className="sportSpecific" />;

      case "cricket":
        return <img src={playCricket} alt="slid 1" className="sportSpecific" />;
    }
    // return (<img src={slid1} alt="slid 1" className="sportSpecific"/>)
    return <img src={NFLW122} alt="sbnfl" />;
  }

  getReview() {
    switch (this) {
      case "testimonial1":
        return <img src={testimonial1} alt="slid 1" />;
        break;

      case "testimonial2":
        return <img src={testimonial2} alt="slid 1" />;
        break;

      case "testimonial3":
        return <img src={testimonial3} alt="slid 1" />;
        break;

      case "testimonial4":
        return <img src={testimonial4} alt="slid 1" />;
        break;
    }
    return <img src={testimonial1} alt="sbnfl" />;
    // Adam Testimonial should go here
  }

  adjustSliderStyling() {
    var node = this.sliderImageRef.current;
    node.style.height = "1104px";
    node.style.width = "510px";
  }

  autoSlider() {
    const slider = $(".landing-slider");
    const activeSlide = slider.find("li.active");

    if (activeSlide.next("li").length) {
      $(".landing-slider").find("button.next").trigger("click");
    } else {
      for (var i = 0; i < this.sliderLength; i++) {
        $(".landing-slider").find("button.prev").trigger("click");
      }
    }
  }

  autoSliderReview() {
    const slider = $(".landing-review-slider");
    const activeSlide = slider.find("li.active");

    if (activeSlide.next("li").length) {
      $(".landing-review-slider").find("button.next").trigger("click");
    } else {
      for (var i = 0; i < this.sliderLength; i++) {
        $(".landing-review-slider").find("button.prev").trigger("click");
      }
    }
  }

  render() {
    this.getImage();
    this.getReview();
    return (
      <div>
        <main className="landing-page">
          <Header onLandingPage={true} />
          {this.state.landingPage === null ? (
            <div>
              <section className="landing-section-1">




                {/*<div>omgomgomg</div>*/}

                <div className="fb-customerchat"
                     attribution="install_email"
                     attribution_version="biz_inbox"
                     page_id="294181551078639">
                </div>







                <div className="promo-logo">
                  <h1 className="partner-text">
                    Proud Partners Of The
                    {<img className="jags-logo" src={jagsLogo}></img>}
                    {<img className="chargers-logo" src={chargersLogo}></img>}
                  </h1>
                </div>
                <div className="landing-wrapper">
                  <div className="inner-home">
                    <h1>
                      Daily Fantasy Sports
                      <br />
                      For Player Props
                    </h1>
                    <p id="deposit-match-message">
                      First Deposit Match Up To $250!
                    </p>
                    <div className="btns">
                      <ul>
                        <li>
                          <Link
                            to={process.env.REACT_APP_START_URL + "sign-up"}
                            title="Play Online"
                          >
                            <span>SIGN UP</span>
                          </Link>
                        </li>
                        <li>
                          <button
                            onClick={(e) => {
                              window.open(
                                "https://apps.apple.com/us/app/thrivefantasy/id1240062484?ls=1"
                              );
                            }}
                            title="Apple Store"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={appleStore} alt="Apple Store" />
                            <span>
                              <small>Download on</small>
                              App Store
                            </span>
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={(e) => {
                              window.open(
                                "https://play.google.com/store/apps/details?id=com.appster.p2f&hl=en_US"
                              );
                            }}
                            title="Google Play"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={googlePlay} alt="Google Play" />
                            <span>
                              <small>Get It On</small>
                              Google Play
                            </span>
                          </button>
                          <br />
                        </li>
                        {/*<img src={logo} />*/}
                        <br />
                        <br />
                        <h3>Over $40M In Total Payouts and Counting</h3>
                      </ul>
                    </div>
                  </div>
                  <div className="landing-slider">
                    <div className="landing-slider-interface">
                      <button className="next "></button>
                      <button className="prev disabled"></button>
                      <Hammer
                        onSwipeLeft={this.sliderLeft}
                        onSwipeRight={this.sliderRight}
                      >
                        <ul>
                          <li className="active">
                            <a href="https://www.thrivefantasy.com/sign-up">
                              {this.getImage()}
                            </a>
                          </li>
                          <li className="next">
                            <a href="https://www.thrivefantasy.com/sign-up">
                              <img src={playCSGO} alt="slid 2" />
                            </a>
                          </li>
                          <li className="next">
                            <a href="https://www.thrivefantasy.com/sign-up">
                              <img src={playNBA} alt="slid 3" />
                            </a>
                          </li>
                          <li className="next">
                            <a href="https://www.thrivefantasy.com/sign-up">
                              <img src={playLOL} alt="slid 3" />
                            </a>
                          </li>
                          <li className="next">
                            <a href="https://www.thrivefantasy.com/sign-up">
                              <img src={playPGA} alt="slid 3" />
                            </a>
                          </li>
                        </ul>
                      </Hammer>
                    </div>
                  </div>
                </div>
              </section>
              <section className="landing-section-2">
                <div className="inner">
                  <br />
                  <br />
                  <h2 className="header">Our Player Props</h2>
                  <h2 className="reasons">
                    • Easy To Play Contests
                    <h6>
                      Including Our Popular User vs. User, Versus, and
                      Traditional Contests
                    </h6>
                  </h2>
                  <h2 className="reasons">
                    • Only Your Favorite Superstars
                    <h6>
                      No More Spending Entire Days Searching For The Perfect
                      Lineup
                    </h6>
                  </h2>
                  <h2 className="reasons">
                    • No Salary Cap
                    <h6>You Pick Who You Want, And Enjoy</h6>
                  </h2>
                  <h2 className="reasons">
                    • Best Multipliers In Daily Fantasy Sports
                    <h6>You'll Thrive In Every Contest</h6>
                  </h2>
                  <h2 className="reasons">
                    • A REAL Chance To Win
                    <h6>
                      Don't Worry, We Want EVERYONE To Have A Fair Shot At
                      Winning
                    </h6>
                  </h2>
                  <h2 ref={this.howToPlay} id="how-to-play">
                    How To Play
                  </h2>
                  <iframe
                    width="560"
                    height="560"
                    src="https://www.youtube.com/embed/_e0CToimul4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <ul>
                    <li>
                      <div>
                        <img src={howToPlay1} alt="Choose a Contest" />
                      </div>
                      <h3>1. Choose a Contest!</h3>
                      <p>
                        NFL, NBA, MLB, NHL, PGA, Cricket, and Esports (CS:GO,
                        Dota 2, LoL, VAL)
                      </p>
                      {/* <p>We Offer NFL, NBA, MLB, PGA, and Cricket, as well as CS:GO, Dota 2, and LoL</p> */}
                    </li>
                    <li>
                      <div>
                        <img src={howToPlay2} alt="Pick Your Side" />
                      </div>
                      <h3>2. Pick Your Props!</h3>
                      <p>Make A Lineup In Minutes!</p>
                    </li>
                    <li>
                      <div>
                        <img src={howToPlay3} alt="Get Your Points" />
                      </div>
                      <h3>3. Win Cash Prizes!</h3>
                      <p>Watch Your Team Rack Up Points!</p>
                    </li>
                  </ul>
                  <div className="testimonials">
                    <h1>Testimonials</h1>
                    <div className="landing-review-slider">
                      <div className="landing-review-slider-interface">
                        <button className="next "></button>
                        <button className="prev disabled"></button>
                        <Hammer
                          onSwipeLeft={this.sliderReviewLeft}
                          onSwipeRight={this.sliderReviewRight}
                        >
                          <ul>
                            <li className="active">{this.getReview()}</li>
                            <li className="next">
                              <img src={testimonial2} alt="slid 2" />
                            </li>
                            <li className="next">
                              <img src={testimonial3} alt="slid 2" />
                            </li>
                            <li className="next">
                              <img src={testimonial4} alt="slid 2" />
                            </li>
                          </ul>
                        </Hammer>
                      </div>
                      <p>
                        By focusing solely on top-tier athletes while
                        streamlining the drafting process, we've created a
                        unique DFS experience that gives the casual sports and
                        Esports fan an opportunity to compete and succeed at a
                        high level.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            [
              this.state.landingPage.type === "A" ? (
                <TemplateALanding
                  lp={this.state.landingPage}
                  redirect={this.state.redirectLink}
                  loginRedirect={this.state.playnowRedirectLink}
                ></TemplateALanding>
              ) : (
                <TemplateBLanding
                  lp={this.state.landingPage}
                  redirect={this.state.redirectLink}
                  loginRedirect={this.state.playnowRedirectLink}
                ></TemplateBLanding>
              ),
            ]
          )}
          <Footer />
        </main>
      </div>
    );
  }

  sliderLeft() {
    $(".landing-slider").find("button.next").trigger("click");
  }
  sliderRight() {
    $(".landing-slider").find("button.prev").trigger("click");
  }

  sliderReviewLeft() {
    $(".landing-review-slider").find("button.next").trigger("click");
  }
  sliderReviewRight() {
    $(".landing-review-slider").find("button.prev").trigger("click");
  }

  // promo = alarm
  getFantasyAlarmId() {
    let fantasyAlarmId = window.location.search.substring(
      window.location.search.indexOf("&id=") + 4
    );
    window.localStorage.setItem("fantasyAlarmId", fantasyAlarmId);
  }
  // promo grinderssub
  getRotogrindersId() {
    let rotogrindersId = window.location.search.substring(
        window.location.search.indexOf("&rg_id=") + 7
    );
    window.localStorage.setItem("rotogrindersId", rotogrindersId);
  }

  // promo FPROSSUB
  getFantasyProsId() {
    console.log('GET FANTASY PROS ID');
    let fantasyProsId = window.location.search.substring(
        window.location.search.indexOf("&userKey=") + 9
    );
    window.localStorage.setItem("fantasyProsId", fantasyProsId);
    console.log('Setting Fantasy Pros Id to: ' + fantasyProsId);
  }

  getPromoCode() {
    if (window.location.search.includes("promo")) {
      this.promoCode = window.location.search.substring(
        window.location.search.indexOf("promo")
      );
      if (this.promoCode.includes("&")) {
        this.promoCode = this.promoCode.substring(
          6,
          this.promoCode.indexOf("&")
        );
      } else if (this.promoCode.includes('FPROSSUB') || this.promoCode.includes('fprossub')) {
          this.promoCode = 'FPROSSUB';
          console.log('PROMO CODE SET TO ' + this.promoCode);
      } else {
        this.promoCode = this.promoCode.substring(6);
      }
    }
    let options = ["SLG", "slg"];
    if (
      window.location.pathname.replace(/^\/([^\/]*).*$/, "$1") === "SLG" ||
      window.location.pathname.replace(/^\/([^\/]*).*$/, "$1") === "slg"
    ) {
      window.location.href = process.env.REACT_APP_START_URL + "?promo=SLG";
    }

    let requestBody = {
      promoCode: this.promoCode,
    };
    const getLandingPageOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };

    $(".loader").addClass("show");
    fetch(
      process.env.REACT_APP_SERVER_URL +
        "admin/fetchPromoCodeAndLandingPageByPromo",
      getLandingPageOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          try {
            window.signupScript(document, "script");
          } catch (error) {
            console.error(error);
          }
        }

        if (data["success"]) {
          this.setState({
            landingPage: data["response"].landingPageDto,
            redirectLink: data["response"].redirectLink,
            playnowRedirectLink: data["response".playnowRedirectLink],
          });
          window.localStorage.setItem(
            "redirectLink",
            data["response"].redirectLink
          );
          window.localStorage.setItem(
            "playnowRedirectLink",
            data["response"].playnowRedirectLink
          );

          // console.log("LOGIN LOCAL STORAGE", window.localStorage.getItem('playnowRedirectLink'));
        } else {
          this.setState({
            landingPage: null,
            redirectLink: null,
            playnowRedirectLink: null,
          });
          window.localStorage.setItem("redirectLink", null);
          window.localStorage.setItem("playnowRedirectLink", null);
        }
        $(".loader").removeClass("show");
      })
      .catch((error) => {
        console.error("Error during service worker registration:", error);
        messages("error", error.message);
        $(".loader").removeClass("show");
      });
  }


  // If users are routed to home page via IA campaign, will have a 'btag' query parameter
  // Run through query string and create a hash of all params in query string, in case later on more params are needed
  // Pull out 'btag' param, save to localStorage for use in sign-up process
  getIABtag(queryStringHash) {
    if(queryStringHash){
      if (queryStringHash["btag"]) {
        window.localStorage.setItem("iabtag", queryStringHash["btag"]);
      }
    }
  }


  collectQueryParameters(){
    const searchString = window.location.search;
    if(searchString.length === 0){
      return false;
    }
    if(!searchString.startsWith("?")){
      return false;
    }
    const queryString = searchString.slice(1, searchString.length); // take out leading question mark
    const queryStringArray = queryString.split("&");
    const queryStringHash = {};
    for (let i = 0; i < queryStringArray.length; i++) {
      const queryStringTerm = queryStringArray[i];
      const queryStringTermArray = queryStringTerm.split("=");
      queryStringHash[queryStringTermArray[0]] = queryStringTermArray[1];
    }
    return queryStringHash;
  }

  componentDidMount() {
    const queryStringHash = this.collectQueryParameters();

    this.getFantasyAlarmId();
    this.getRotogrindersId();
    this.getFantasyProsId();
    this.getIABtag(queryStringHash);
    this.getPromoCode();
    this.getIABtag();


    if (window.localStorage.getItem("userData") !== null && !this.promoCode) {
      window.location.href = process.env.REACT_APP_START_URL + "contest-lobby";
    }

    if (window.localStorage.getItem("promo") !== this.promoCode) {
      window.localStorage.setItem("promo", this.promoCode);
    }

    // console.log(window.localStorage.getItem('promo'), this.promoCode, window.localStorage.getItem('redirectLink'), window.localStorage.getItem('playnowRedirectLink'));

    $(".landing-slider").on("click", "button.next", function () {
      const slider = $(this).closest(".landing-slider");
      const activeSlide = slider.find("li.active");
      if (activeSlide.next("li").length) {
        activeSlide
          .removeClass("active")
          .addClass("prev")
          .next("li")
          .removeClass("next")
          .addClass("active");
        $(this).siblings().removeClass("disabled");
      }
      if (!activeSlide.next("li").next("li").length) {
        $(this).addClass("disabled");
      }
    });
    $(".landing-slider").on("click", "button.prev", function () {
      const slider = $(this).closest(".landing-slider");
      const activeSlide = slider.find("li.active");
      if (activeSlide.prev("li").length) {
        activeSlide
          .removeClass("active")
          .addClass("next")
          .prev("li")
          .removeClass("prev")
          .addClass("active");
        $(this).siblings().removeClass("disabled");
      }
      if (!activeSlide.prev("li").prev("li").length) {
        $(this).addClass("disabled");
      }
    });

    $(".landing-review-slider").on("click", "button.next", function () {
      const slider = $(this).closest(".landing-review-slider");
      const activeSlide = slider.find("li.active");
      if (activeSlide.next("li").length) {
        activeSlide
          .removeClass("active")
          .addClass("prev")
          .next("li")
          .removeClass("next")
          .addClass("active");
        $(this).siblings().removeClass("disabled");
      }
      if (!activeSlide.next("li").next("li").length) {
        $(this).addClass("disabled");
      }
    });
    $(".landing-review-slider").on("click", "button.prev", function () {
      const slider = $(this).closest(".landing-review-slider");
      const activeSlide = slider.find("li.active");
      if (activeSlide.prev("li").length) {
        activeSlide
          .removeClass("active")
          .addClass("next")
          .prev("li")
          .removeClass("prev")
          .addClass("active");
        $(this).siblings().removeClass("disabled");
      }
      if (!activeSlide.prev("li").prev("li").length) {
        $(this).addClass("disabled");
      }
    });
    var intervalId = setInterval(() => {
      this.autoSlider();
    }, 5000);
    var reviewIntervalId = setInterval(() => {
      this.autoSliderReview();
    }, 5000);
    this.setState({
      intervalId: intervalId,
      reviewIntervalId: reviewIntervalId,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }
}

export default withRouter(Home);
