import React from 'react';
import { Link } from "react-router-dom";
import './colin-sign-up.scss'; // CHANGE
import logo from "../../assets/images/loginlogo.png";
import $ from 'jquery';
import { messages } from "../messages";
import { isBrowser, isMobile } from 'react-device-detect';

function validateEmail($email) {
    var emailReg = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return emailReg.test($email);
}
const requestOptions = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
};

class ColinSignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            dateOfBirthBefore: '',
            dateOfBirth: '',
            address: '',
            promotionalCode: '',
            deviceID: null,
            deviceToken: null,
            devicetype: 3,
            utm_source: window.localStorage.getItem('utm_source'),
            utm_campaign: window.localStorage.getItem('utm_campaign'),
            linkPromo: window.localStorage.getItem('promo')
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.openTermsAndConditions = this.openTermsAndConditions.bind(this);
        this.openPrivacyPolicy = this.openPrivacyPolicy.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    handleSubmit(event) {

        $('.loader').addClass('show');
        event.preventDefault();

        const username = this.state.username;
        const email = this.state.email;
        const password = this.state.password;
        const dateOfBirthBefore = this.state.dateOfBirth.slice(5, 7) + '/' + this.state.dateOfBirth.slice(8, 10) + '/' + this.state.dateOfBirth.slice(0, 4)

        const tempState = JSON.parse(JSON.stringify(this.state))
        tempState.dateOfBirth = dateOfBirthBefore

        console.log('this.state - ' + this.state.dateOfBirth)
        console.log('tempstate - ' + tempState.dateOfBirth)

        let error = false;
        $('input').removeClass('error').next('.error').hide().html('');

        if (username === '') {
            $('input[name="username"]').addClass('error').next('.error').show().html('Username is required.');
            error = true;
        }

        if (email === '') {
            $('input[name="email"]').addClass('error').next('.error').show().html('Email is required.');
            error = true;
        }

        if (!validateEmail(email)) {
            $('input[name="email"]').addClass('error').next('.error').show().html('Invalid email.');
            error = true;
        }


        if (password.length < 6) {
            $('input[name="password"]').addClass('error').next('.error').show().html('Password length must be at least 6 characters.');
            error = true;
        }

        if (this.state.dateOfBirth == '') {
            $('input[name="dateOfBirth"]').addClass('error').next('.error').show().html('Date of Birth is required.');
            error = true;
        }

        // if (this.state.address == '') {
        //     $('input[name="address"]').addClass('error').next('.error').show().html('Address is required.');
        //     error = true;
        // }

        if (error) {
            $('.loader').removeClass('show');
            return;
        }


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify(tempState)
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'signup', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    try {
                        window.signupScript(document, "script")
                    } catch (error) {
                        console.error(error);
                    }
                    window.localStorage.setItem('userData', JSON.stringify(data['response']));
                    $('.loader').removeClass('show');
                    window.location.href = process.env.REACT_APP_START_URL + "contest-lobby/224671";
                } else {
                    $('.server-error').show().html(data['message']);
                    $('.loader').removeClass('show');
                }
            });
    }

    openTermsAndConditions() {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'adminContest/get-terms-and-condition', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.openPdfPopup(data['response']);
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            }).catch((error) => {
                messages('error', error);
            });
    }

    openPrivacyPolicy() {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'adminContest/get-privacy-policy', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.openPdfPopup(data['response']);
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            }).catch((error) => {
                messages('error', error);
            });
    }

    openPdfPopup(url) {
        $('.pdf-popup').addClass('show').find('iframe').attr('src', url);
    }

    closePdfPopup() {
        $('.pdf-popup').removeClass('show').find('iframe').attr('src', '');
    }

    handlePromo() {
        if (this.state.linkPromo == "" || this.state.linkPromo == null) {
            return (
                <li>
                    <input className="field-input" type="text" name="promotionalCode" placeholder="Promo code" onChange={this.handleInputChange} />
                </li>
            )
        } else if (this.state.promotionalCode == null || this.state.promotionalCode == "") {
            this.state.promotionalCode = this.state.linkPromo;
            console.log("promotional code set to: " + this.state.promotionalCode)
        }
    }

    /* <li>
        <input className="field-input" type="text" name="address" placeholder="Address" onChange={this.handleInputChange} />
        <span className="error"></span>
    </li> */

    render() {
        const privacyButton = (isBrowser)
            ? <button title="Privacy Policy" onClick={this.openPrivacyPolicy}>Privacy Policy</button>
            : <button title="Privacy Policy"> <a target="_blank" href={"https://s3.amazonaws.com/s3-p2f-dev/privacyPolicy/PrivacyPolicy2019.pdf"}>Privacy Policy</a></button>;
        const termsButton = (isBrowser)
            ? <button title="Terms & Conditions" onClick={this.openTermsAndConditions}>Terms & Conditions </button>
            : <button title="Terms & Conditions"> <a target="_blank" href={"https://s3.amazonaws.com/s3-p2f-dev/pdf/TermsAndConditions2019.pdf"}>Terms & Conditions</a></button>

        return (
            <main className="Login-page">
                <section className="left">
                    <Link to={process.env.REACT_APP_START_URL} title="Thrive Fantasy">
                        <img src={logo} alt="Thrive Fantasy" />
                    </Link>
                    <h1><strong>Welcome!</strong></h1>
                </section>
                <section className="right">
                    <div style={{ marginTop: '75px' }}>
                        <h1>Getting Started</h1>
                        <form onSubmit={this.handleSubmit}>
                            <ul>
                                <li>
                                    <input className="field-input" type="text" name="username" placeholder="Username" onChange={this.handleInputChange} />
                                    <span className="error"></span>
                                </li>
                                <li>
                                    <input className="field-input" type="text" name="email" placeholder="Email" onChange={this.handleInputChange} />
                                    <span className="error"></span>
                                </li>
                                <li>
                                    <input className="field-input" type="password" name="password" placeholder="Password" onChange={this.handleInputChange} />
                                    <span className="error"></span>
                                </li>

                                <li>
                                    <input className='field-input'
                                        type="text"
                                        name='dateOfBirth'
                                        onFocus={
                                            (e) => {
                                                e.currentTarget.type = "date";
                                                e.currentTarget.focus();
                                            }
                                        }
                                        placeholder="Date of Birth"
                                        onChange={this.handleInputChange} />
                                    <span className="error"></span>
                                </li>



                                {this.handlePromo()}
                                <li>
                                    <input type="submit" value="Sign Up" />
                                </li>
                            </ul>
                        </form>
                        <p className="server-error">

                        </p>
                        <p className="text-nav">
                            Already a member? <Link to={process.env.REACT_APP_START_URL + "login"}>Sign In</Link>
                        </p>
                        <p>
                            By signing up, you agree to ThriveFantasy’s <br /> {termsButton} and {privacyButton}
                        </p>
                    </div>
                </section>
                <div className="pdf-popup">
                    <div className="inner">
                        <button onClick={this.closePdfPopup} className="close-pdf-modal" title="Close">x</button>
                        <iframe src="" frameBorder="0" > </iframe>
                    </div>
                </div>
            </main>
        );
    }
}

export default ColinSignUp;
