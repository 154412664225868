import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import "./contest-event.scss";
import LoggedInHeader from "../Logged-in-header/Logged-in-header";
import ContestTraditionalPicks from "../contest-traditional-picks/contest-traditional-picks";
import ContestVersusPicks from "../contest-versus-picks/contest-versus-picks";
import MyContestTraditionalPicksUpcoming from "../my-contest-traditional-picks-upcoming/my-contest-traditional-picks-upcoming";
import MyContestVersusPicksUpcoming from "../my-contest-versus-picks-upcoming/my-contest-versus-picks-upcoming";
import ContestEntrants from "../contest-entrants/contest-entrants";
import $ from "jquery";
import Moment from "react-moment";
import { isAuthenticated } from "../isAuthenticated";
import Footer from "../footer/footer";
import { Mixpanel } from "../mixpanel/Mixpanel";

let prizes = [];

let requestBody = {
  contestId: 0,
  userContestId: 0,
};

const requestOptions = {
  method: "post",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Token: window.localStorage.getItem("userData")
      ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
      : false,
  },
  body: JSON.stringify(requestBody),
};

class ContestEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: this.props.match.params.view,
      contestId: this.props.match.params.contestId,
      userContestId: this.props.match.params.userContestId,
      type: "",
      picked: 0,
      icePick: 0,
      maximumPoint: 0,
      contest: {},
      prizes: [],
      picks: [],
    };
    this.fetchNewData = this.fetchNewData.bind(this);
    this.forceReload = this.forceReload.bind(this);
  }

  fetchNewData(requestOptions) {
    $(".loader").addClass("show");
    fetch(
      process.env.REACT_APP_SERVER_URL + "contests/contestDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          parseContest(data["response"]["contestPrizes"]);
          this.setState({
            contest: data["response"]["contestDetails"],
            prizes: prizes,
            type: data["response"]["contestDetails"]["type"],
          });
          updateTime();
          $(".loader").removeClass("show");
        } else {
          $(".loader").removeClass("show");
        }
      });
  }

  tabSwitch(event) {
    const tab = event.target.getAttribute("rel");
    $(".tabs-head").find("button").removeClass("active");
    event.target.classList.add("active");
    $(".tabs-body").find(".tab-container").removeClass("active");
    $(".tabs-body")
      .find('.tab-container[rel="' + tab + '"]')
      .addClass("active");

    Mixpanel.track("web:user_switched_contest_event_tab", {
      Tab: tab,
    });
  }

  componentDidMount() {
    isAuthenticated("login", "/contest-lobby");
    requestBody["contestId"] = this.state.contestId;
    if (this.state.view) {
      requestBody["userContestId"] = this.state.userContestId;
    }
    requestOptions["body"] = JSON.stringify(requestBody);
    this.fetchNewData(requestOptions);
  }

  showSummary() {
    $(".event-Prize-Payouts").removeClass("open");
    $(".event-summary").toggleClass("open");
  }

  showPrize() {
    $(".event-summary").removeClass("open");
    $(".event-Prize-Payouts").toggleClass("open");
  }
  forceReload() {
    window.location.reload();
  }

  render() {
    const contest = this.state.contest;
    // console.log(contest);
    const type = this.state.type;
    return (
      <main className="contest-event-page">
        <LoggedInHeader />
        {contest && type ? (
          <div className="inner">
            <aside>
              <Link
                className="back-btn"
                to={process.env.REACT_APP_START_URL + "contest-lobby"}
                title="Back to Results"
              >
                Back to Results
              </Link>
              <div className="event-details">
                <div className="top">
                  <img src={contest["gameImage"]} alt={contest["name"]} />
                  <h1>
                    {contest["name"]}
                    {contest["featured"] ? <span>Featured</span> : ""}
                  </h1>
                  {contest["multiEntryAllowed"] ? (
                    <div className="multy-entries">
                      <span>M - {contest["maxEntriesPerUser"]} Max</span>
                      <span>
                        {contest["maxEntriesPerUser"] -
                          contest["remainingEntries"]}{" "}
                        Left
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="bottom">
                  <div className="info">
                    <h3>
                      Entry fees
                      <span>{formatNumber(contest["entryFee"])}</span>
                    </h3>
                    <h3>
                      Prize Pool
                      <span>
                        {formatNumber(contest["guaranteedPrizeMoney"])}
                      </span>
                    </h3>
                    <h3>
                      Entries
                      <span>
                        {contest["totalEntries"]}/{contest["maxEntriesAllowed"]}
                      </span>
                    </h3>
                  </div>
                  <div className="dates">
                    <p>
                      Date:{" "}
                      <Moment utc local format="dddd, MMM Do, YYYY">
                        {contest["startTime"]}
                      </Moment>
                      <br />
                      Start Time:{" "}
                      <Moment utc local format="hh:mm a">
                        {contest["startTime"]}
                      </Moment>
                    </p>
                    <div
                      className="countdown"
                      rel={(
                        new Date().getTime() +
                        new Date(contest["timeToStart"]).getTime()
                      ).toString()}
                    >
                      <div>
                        <span className="h">00</span>
                        Hours
                      </div>
                      <div>
                        <span className="m">00</span>
                        Mins
                      </div>
                      <div>
                        <span className="s">00</span>
                        Sec
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="event-details-mobile">
                <div className="top">
                  <img src={contest["gameImage"]} alt={contest["name"]} />
                  <div
                    className="countdown"
                    rel={(
                      new Date().getTime() +
                      new Date(contest["timeToStart"]).getTime()
                    ).toString()}
                  >
                    <div>
                      <span className="h">00</span>
                      Hours
                    </div>
                    <div>
                      <span className="m">00</span>
                      Mins
                    </div>
                    <div>
                      <span className="s">00</span>
                      Sec
                    </div>
                  </div>
                  <h3>
                    {contest["name"]}{" "}
                    {contest["featured"] ? <span>Featured</span> : ""}
                  </h3>
                  <h4>
                    <span>Entry fees</span>
                    {formatNumber(contest["entryFee"])}
                  </h4>
                  <h4>
                    <span>Prize Pool</span>
                    {formatNumber(contest["guaranteedPrizeMoney"])}
                  </h4>
                </div>
                <div className="bottom">
                  <p>
                    Date:{" "}
                    <span>
                      <Moment utc local format="dddd, MMM Do, YYYY">
                        {contest["startTime"]}
                      </Moment>
                    </span>
                  </p>
                  <p>
                    Start Time:{" "}
                    <span>
                      <Moment utc local format="hh:mm a">
                        {contest["startTime"]}
                      </Moment>
                    </span>
                  </p>
                  <p>
                    Entries:{" "}
                    <span>
                      {contest["totalEntries"]}/{contest["maxEntriesAllowed"]}
                    </span>
                  </p>
                </div>
              </div>
              <div className="event-summary">
                <h2>Summary</h2>
                <p>{contest["summary"]}</p>
              </div>
              <div className="event-Prize-Payouts">
                <h2>Prize Payouts</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Places</th>
                      <th>Prizes</th>
                    </tr>
                    {this.state.prizes.length ? (
                      this.state.prizes.map((prize, index) => (
                        <tr key={prize["prizeId"]}>
                          <td className="nowrap">{prize["text"]}</td>
                          <td>
                            {formatNumber(prize["amount"])}
                            {isFirst(index, this.state.contest.extraPrize)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="event-information">
                  <h2>General Information</h2>
                  <p>{contest["generalInfo"]}</p>
                </div>
              </div>
              <div className="mobile-switch-info">
                <button onClick={this.showSummary}>Summary</button>
                <button onClick={this.showPrize}>Prize Payouts</button>
              </div>
            </aside>
            <section className="event-content">
              <div className="tabs-head">
                <button
                  onClick={this.tabSwitch}
                  rel="entrants"
                  title="Entrants"
                >
                  Entrants
                </button>
                <button
                  onClick={this.tabSwitch}
                  className="active"
                  rel="making-picks"
                  title="Making Picks"
                >
                  Making Picks
                </button>
              </div>
              <div className="tabs-body">
                <ContestEntrants />
                {this.state.userContestId && this.state.view ? (
                  this.state.type === "TRADITIONAL" ? (
                    <MyContestTraditionalPicksUpcoming
                      contest={this.state.contest}
                      details={this.state.contest}
                    />
                  ) : (
                    <MyContestVersusPicksUpcoming
                      contest={this.state.contest}
                    />
                  )
                ) : this.state.type === "TRADITIONAL" ? (
                  <ContestTraditionalPicks
                    contest={this.state.contest}
                    details={this.state.contest}
                  />
                ) : (
                  <ContestVersusPicks contest={this.state.contest} />
                )}
              </div>
            </section>
          </div>
        ) : (
          ""
        )}
        <Footer />
      </main>
    );
  }
}

function isFirst(index, extraPrize) {
  if (index === 0 && extraPrize != null) {
    return " + " + extraPrize;
  } else {
    return null;
  }
}

function parseContest(contestPrizes) {
  let lastPosition = 0;
  let start = 0;
  let end = 0;
  for (let i = 0; i < contestPrizes.length; i++) {
    start = lastPosition + 1;
    end = lastPosition + contestPrizes[i]["numEntrants"];
    lastPosition = end;
    if (start === end) {
      contestPrizes[i].text = start + numberSuffix(start) + " place";
    } else {
      contestPrizes[i].text =
        start + numberSuffix(start) + "-" + end + numberSuffix(end) + " place";
    }
    prizes = contestPrizes;
  }
}

function numberSuffix(n) {
  return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
}

function updateTime() {
  $(".countdown").each(function () {
    const time = getTimeRemaining($(this).attr("rel"));

    $(this).find(".h").html(time.hours);
    $(this).find(".m").html(time.minutes);
    $(this).find(".s").html(time.seconds);
  });
}

function getTimeRemaining(endTime) {
  var newDate = new Date();
  let t = endTime - Date.parse(newDate.toString());
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  var hours = Math.floor(t / (1000 * 60 * 60));
  return {
    total: t,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}

function formatNumber(num) {
  return "$" + (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default withRouter(ContestEvent);
