import React from 'react';
import './App.scss';
import Main from './components/main';
import logo from './assets/images/tf2.jpg';

function App() {
  return (
    <div className='App'>
      <div className='loader'>
        <div className='cube-grid-spinner'>
          <div className='cube1'></div>
          <div className='cube2'></div>
          <div className='cube3'></div>
          <div className='cube4'></div>
          <div className='cube5'></div>
          <div className='cube6'></div>
          <div className='cube7'></div>
          <div className='cube8'></div>
          <div className='cube9'></div>
        </div>
      </div>
      <div className='site-messages'></div>
      <Main />
    </div>

    //Naglis - Comment this out if you want to put the site into maintenance mode.

    // <div style={{backgroundColor: "#03212c", position: "fixed",  width: "100%", height: "100%"}}>
    // <article style={{display: 'block', textAlign: 'left', width: "650px", margin: "0 auto"}}>
    // <h1 style={{fontSize: "50px", color: "white"}}>We&rsquo;ll be back soon!</h1>
    // <div style={{font: "20px Helvetica, sans-serif", color: "white"}}>
    //     <p>
    //     Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. We&rsquo;ll be back online
    //     shortly!
    //     </p>
    //     <p>&mdash; The ThriveFantasy Team</p>
    //     <img src={logo} style={{}}></img>
    // </div>
    // </article>
    // </div>
  );
}

export default App;
