import React from "react";
import { Link } from "react-router-dom";
import "./my-contests-upcoming.scss";
import LoggedInHeader from "../Logged-in-header/Logged-in-header";
import $ from "jquery";
import { isAuthenticated } from "../isAuthenticated";
import Moment from "react-moment";
import Footer from "../footer/footer";
import { Mixpanel } from "../mixpanel/Mixpanel";
import { messages } from "../messages";

let upcomingList = [];

let requestUpcomingBody = {
  leagueType: null,
  contestType: null,
  currentPage: 1,
  currentSize: 10,
  featured: "0",
  fetchUserContestProps: true,
};

const requestUpcomingOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Token: window.localStorage.getItem("userData")
      ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
      : false,
  },
  body: JSON.stringify(requestUpcomingBody),
};

class MyContestsUpcoming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upcomingList: upcomingList,
      call: false,
      upcomingUserProps: [],
    };
    $(".loader").addClass("show");
    this.fetchUpcomingData = this.fetchUpcomingData.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  fetchUpcomingData(requestUpcomingOptions) {
    $(".loader").addClass("show");
    fetch(
      process.env.REACT_APP_SERVER_URL + "contests/upcoming",
      requestUpcomingOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          if (requestUpcomingBody["currentPage"] > 1) {
            for (let i = 0; i < data["response"]["data"].length; i++) {
              upcomingList.push(data["response"]["data"][i]);
            }
          } else {
            upcomingList = data["response"]["data"];
          }
          for(let contest of upcomingList) {
            contest.userPropPicks.sort((a,b) => a.propId - b.propId)
          }

          this.setState({ upcomingList: upcomingList, call: true });
          updateLiveTime();
          if (upcomingList.length >= requestUpcomingBody["currentSize"]) {
            $(".load-more").addClass("show");
          }
          $(".loader").removeClass("show");
        } else {
          $(".loader").removeClass("show");
        }
      });
  }

  componentDidMount() {
    isAuthenticated("login", "/my-contests/upcoming");
    this.fetchUpcomingData(requestUpcomingOptions);

    $(".mobile-select-sport").on("click", ".selected", function () {
      $(".mobile-select-sport").find(".selection").toggleClass("open");
    });

    Mixpanel.track("web:my_contests_upcoming_opened");

    window.setInterval(function () {
      updateLiveTime();
    }, 1000);
  }

  loadMore() {
    requestUpcomingBody["currentPage"] = requestUpcomingBody["currentPage"] + 1;
    requestUpcomingOptions["body"] = JSON.stringify(requestUpcomingBody);
    this.fetchUpcomingData(requestUpcomingOptions);
  }

  getParams(params) {
    let ans = "";
    for (var i = 0; i < params.length; i++) {
      if (i !== params.length - 1) {
        ans += params[i].abbreviation + " + ";
      } else {
        ans += params[i]["abbreviation"];
      }
    }
    return ans;
  }

  getParamsVs(params) {
    let ans = "";
    for (var i = 0; i < 1; i++) {
      ans += params[i].abbreviation;
    }
    return ans;
  }

  getPlayerName(name) {
    if (name) {
      if (name.length > 18) {
        var spaceIndex = name.indexOf(" ");
        name = name.substr(0, 1) + "." + name.substr(spaceIndex);
      }
      if (name.length > 18) {
        name = name.substr(0, 18);
        console.log(name);
      }
      return name;
    }
  }

  render() {
    // console.log(this.state.upcomingList);

    return (
      <main className="my-contest-upcoming">
        <LoggedInHeader />
        <div className="inner">
          {/* <nav className="filters">
                        <button>Date</button>
                        <button>Entry Fee</button>
                        <button>Prize Pool</button>
                        <button>Number of Entries</button>
                    </nav> */}

          <aside>
            <h2>Select Category</h2>
            <nav className="my-contest-nav">
              <Link
                className="live "
                to={process.env.REACT_APP_START_URL + "my-contests/live"}
                title="live"
              >
                Live
              </Link>
              <Link
                className="upcoming active"
                to={process.env.REACT_APP_START_URL + "my-contests/upcoming"}
                title="Upcoming"
              >
                Upcoming
              </Link>
              <Link
                className="History"
                to={process.env.REACT_APP_START_URL + "my-contests/history"}
                title="History"
              >
                History
              </Link>
            </nav>
          </aside>
          <section className="events">
            <div className="mobile-select-sport">
              <h2>Select Category</h2>
              <div className="selected">Upcoming</div>
              <div className="selection">
                <nav className="my-contest-nav">
                  <Link
                    className="live "
                    to={process.env.REACT_APP_START_URL + "my-contests/live"}
                    title="live"
                  >
                    Live
                  </Link>
                  <Link
                    className="upcoming active"
                    to={
                      process.env.REACT_APP_START_URL + "my-contests/upcoming"
                    }
                    title="Upcoming"
                  >
                    Upcoming
                  </Link>
                  <Link
                    className="History"
                    to={process.env.REACT_APP_START_URL + "my-contests/history"}
                    title="History"
                  >
                    History
                  </Link>
                </nav>
              </div>
            </div>
            <h2>Upcoming Events</h2>
            {this.state.call ? (
              <div className="events-listing-new">
                <ul>
                  {this.state.upcomingList.length ? (
                    this.state.upcomingList.map((item) => (
                      <li key={item["userContestId"]}>
                        <div className="desktop">
                          <div className="top">
                            <Link to={process.env.REACT_APP_START_URL + "my-contests/upcoming/view/" + item["contestId"] + "/" + item["userContestId"]} title="Enter Contest">
                              <img src={item["gameImage"]} alt={item["name"]} />
                              <div className="headings">
                                <h3>{item["name"]}{item["totalEntries"] === item["maxEntriesAllowed"] ? (
                                    <span className="contest-filled">Contest Filled</span>) : ("")}
                                </h3>
                                <h4>
                                  Entry fees: {formatNumber(item["entryFee"])}
                                </h4>
                                <div className="date">
                                  <h4>
                                    <Moment
                                      utc
                                      local
                                      format="ddd, MMM Do, YYYY"
                                    >
                                      {item.startTime}
                                    </Moment>
                                    ,{" "}
                                    <Moment utc local format="hh:mm a">
                                      {item.startTime}
                                    </Moment>
                                  </h4>
                                </div>
                              </div>
                              <div className="info">
                                <h3 className="prize">
                                  <span>Prize Pool</span>
                                  {formatNumber(item["guaranteedPrizeMoney"])}
                                </h3>
                                <h3 className="numbers">
                                  <span>Entries</span>
                                  {item["totalEntries"]}/
                                  {item["maxEntriesAllowed"]}
                                </h3>
                              </div>
                            </Link>
                          </div>
                          {item["type"] === "TRADITIONAL" ? (
                            <div className="middle-traditional">
                              {item["userPropPicks"]
                                ? item["userPropPicks"].map((pick) => (
                                    <div
                                      style={{ color: "white" }}
                                      className="playerProps"
                                    >
                                      <div className="singleProp">
                                        {pick["ice"] === true &&
                                        pick["icePrimary"] === true ? (
                                          <p
                                            className="propData"
                                            style={{ color: "#0CBCED" }}
                                          >
                                            (ICE 1)
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {pick["ice"] === true &&
                                        pick["icePrimary"] === false ? (
                                          <p
                                            className="propData"
                                            style={{ color: "#0CBCED" }}
                                          >
                                            (ICE 2)
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        <div className="headshot">
                                          {pick.leagueType === "SOCCER" ? (
                                            <img
                                              className="upcomingPlayerSoccer"
                                              src={pick["player1Image"]}
                                            ></img>
                                          ) : (
                                            <img
                                              className="upcomingPlayer"
                                              src={pick["player1Image"]}
                                            ></img>
                                          )}
                                        </div>
                                        <p className="propData">
                                          {this.getPlayerName(
                                            pick["player1Name"]
                                          )}
                                        </p>
                                        <p
                                          className="propData"
                                          style={{
                                            color: "#0CBCED",
                                            overflow: "hiden",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {pick["propValue"]}{" "}
                                          {this.getParams(
                                            pick["propParameters"]
                                          )}
                                        </p>
                                        {pick["over"] === true ? (
                                          <p
                                            style={{ width: "100%" }}
                                            className="propData"
                                          >
                                            More = {pick["overPoints"]} Points
                                          </p>
                                        ) : (
                                          <p className="propData">
                                            Less = {pick["underPoints"]} Points{" "}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  ))
                                : ""}
                              <div className="buttonWrapper">
                                <Link
                                  to={
                                    process.env.REACT_APP_START_URL +
                                    "my-contests/upcoming/view/" +
                                    item["contestId"] +
                                    "/" +
                                    item["userContestId"]
                                  }
                                >
                                  <button title="Enter Contest">
                                    Re-Enter/Edit Contest
                                  </button>
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div className="middle-versus">
                              {item["userPropPicks"]
                                ? item["userPropPicks"].map((pick) => (
                                    <div
                                      style={{ color: "white" }}
                                      className="playerProps"
                                    >
                                      <div className="singleProp-vs">
                                        {pick["ice"] === true &&
                                        pick["icePrimary"] === true ? (
                                          <p
                                            className="propData-vs"
                                            style={{ color: "#0CBCED" }}
                                          >
                                            (ICE 1)
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {pick["ice"] === true &&
                                        pick["icePrimary"] === false ? (
                                          <p
                                            className="propData-vs"
                                            style={{ color: "#0CBCED" }}
                                          >
                                            (ICE 2)
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {pick["isOver"] === true ? (
                                          <div className="playerData1-picked">
                                            <div className="headshot-vs">
                                              {pick.leagueType === "SOCCER" ? (
                                                <img
                                                  className="upcomingPlayerSoccer"
                                                  src={pick["player1Image"]}
                                                ></img>
                                              ) : (
                                                <img
                                                  className="upcomingPlayer"
                                                  src={pick["player1Image"]}
                                                ></img>
                                              )}
                                            </div>
                                            <p className="propData-vs">
                                              {this.getPlayerName(
                                                pick["player1Name"]
                                              )}{" "}
                                            </p>
                                            <p
                                              className="propData-vs"
                                              style={{
                                                color: "#0CBCED",
                                                overflow: "hiden",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              +{pick["player1Advantage"]}{" "}
                                              {this.getParamsVs(
                                                pick["propParameters"]
                                              )}
                                            </p>
                                            <p className="propData-vs-pt">
                                              {pick["player1Points"]} Points{" "}
                                            </p>
                                          </div>
                                        ) : (
                                          <div className="playerData1-notPicked">
                                            <div className="headshot-vs">
                                              {pick.leagueType === "SOCCER" ? (
                                                <img
                                                  className="upcomingPlayerSoccer"
                                                  src={pick["player1Image"]}
                                                ></img>
                                              ) : (
                                                <img
                                                  className="upcomingPlayer"
                                                  src={pick["player1Image"]}
                                                ></img>
                                              )}
                                            </div>
                                            <p className="propData-vs">
                                              {this.getPlayerName(
                                                pick["player1Name"]
                                              )}{" "}
                                            </p>
                                            <p
                                              className="propData-vs"
                                              style={{
                                                color: "#0CBCED",
                                                overflow: "hiden",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              +{pick["player1Advantage"]}{" "}
                                              {this.getParamsVs(
                                                pick["propParameters"]
                                              )}
                                            </p>
                                            <p className="propData-vs-pt">
                                              {pick["player1Points"]} Points{" "}
                                            </p>
                                          </div>
                                        )}
                                        {/*<div className='selected'>
                                                                        {pick['isOver'] === true ? <p className='your-pick'>YOUR PICK</p> : ''}
                                                                    </div> */}
                                      </div>
                                      <div className="secondProp">
                                        <div className="playerData2">
                                          <div className="vs">
                                            <p>VS.</p>
                                          </div>
                                          {pick["isOver"] === false ? (
                                            <div className="p2-outline">
                                              <div className="headshot-vs-2">
                                                {pick.leagueType ===
                                                "SOCCER" ? (
                                                  <img
                                                    className="upcomingPlayerSoccer"
                                                    src={pick["player1Image"]}
                                                  ></img>
                                                ) : (
                                                  <img
                                                    className="upcomingPlayer-2"
                                                    src={pick["player2Image"]}
                                                  ></img>
                                                )}
                                              </div>
                                              <p className="propData-vs-2">
                                                {this.getPlayerName(
                                                  pick["player2Name"]
                                                )}
                                              </p>
                                              <p
                                                className="propData-vs-2"
                                                style={{
                                                  color: "#0CBCED",
                                                  overflow: "hiden",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                Total{" "}
                                                {this.getParamsVs(
                                                  pick["propParameters"]
                                                )}
                                              </p>
                                              <p className="propData-vs-2">
                                                {200 - pick["player1Points"]}{" "}
                                                Points{" "}
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="p2-no-outline">
                                              <div className="headshot-vs-2">
                                                {pick.leagueType ===
                                                "SOCCER" ? (
                                                  <img
                                                    className="upcomingPlayerSoccer"
                                                    src={pick["player1Image"]}
                                                  ></img>
                                                ) : (
                                                  <img
                                                    className="upcomingPlayer-2"
                                                    src={pick["player2Image"]}
                                                  ></img>
                                                )}
                                              </div>
                                              <p className="propData-vs-2">
                                                {this.getPlayerName(
                                                  pick["player2Name"]
                                                )}
                                              </p>
                                              <p
                                                className="propData-vs-2"
                                                style={{
                                                  color: "#0CBCED",
                                                  overflow: "hiden",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                Total{" "}
                                                {this.getParamsVs(
                                                  pick["propParameters"]
                                                )}
                                              </p>
                                              <p className="propData-vs-2">
                                                {200 - pick["player1Points"]}{" "}
                                                Points{" "}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                        {/*}
                                                                    <div className='selected'>
                                                                        {pick['isOver'] === false ? <p className='your-pick'>YOUR PICK</p> : ''}
                                                                </div> */}
                                      </div>
                                    </div>
                                  ))
                                : ""}
                              <div className="buttonWrapper">
                                <Link
                                  to={
                                    process.env.REACT_APP_START_URL +
                                    "my-contests/upcoming/view/" +
                                    item["contestId"] +
                                    "/" +
                                    item["userContestId"]
                                  }
                                >
                                  <button title="Enter Contest">
                                    Re-Enter/Edit Contest
                                  </button>
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="mobile">
                          <div className="top">
                            <img src={item["gameImage"]} alt={item["name"]} />
                            <div
                              className="countdown"
                              rel={
                                new Date().getTime() +
                                new Date(item["timeToStart"]).getTime()
                              }
                            >
                              <div>
                                <span className="h">00</span>
                                Hours
                              </div>
                              <div>
                                <span className="m">00</span>
                                Mins
                              </div>
                              <div>
                                <span className="s">00</span>
                                Sec
                              </div>
                            </div>
                            <h3>
                              {item["name"]}{" "}
                              {item["featured"] ? <span>Featured</span> : ""}
                              {item["totalEntries"] ===
                              item["maxEntriesAllowed"] ? (
                                <span className="contest-filled">
                                  Contest Filled
                                </span>
                              ) : (
                                ""
                              )}
                            </h3>
                            <h4>
                              <span>Entry fees</span>
                              {formatNumber(item["entryFee"])}
                            </h4>
                            <h4>
                              <span>Prize Pool</span>
                              {formatNumber(item["guaranteedPrizeMoney"])}
                            </h4>
                          </div>
                          <div className="bottom">
                            <p>
                              Date:{" "}
                              <span>
                                <Moment utc local format="dddd, MMM Do, YYYY">
                                  {item["startTime"]}
                                </Moment>
                              </span>
                            </p>
                            <p>
                              Start Time:{" "}
                              <span>
                                <Moment utc local format="hh:mm a">
                                  {item["startTime"]}
                                </Moment>
                              </span>
                            </p>
                            <p>
                              Entries:{" "}
                              <span>
                                {item["totalEntries"]}/
                                {item["maxEntriesAllowed"]}
                              </span>
                            </p>
                            <Link
                              to={
                                process.env.REACT_APP_START_URL +
                                "my-contests/upcoming/view/" +
                                item["contestId"] +
                                "/" +
                                item["userContestId"]
                              }
                            >
                              <button title="Enter Contest">
                                Re-Enter Contest
                              </button>
                            </Link>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="noContests">
                      <li className="no-results">
                        <div></div>
                        <h3>No Events Found</h3>
                        {/* <span>Contests Usually Posted at 10 AM EST</span> */}
                        <Link
                          to={process.env.REACT_APP_START_URL + "contest-lobby"}
                        >
                          Go Back
                        </Link>
                      </li>
                    </div>
                  )}
                </ul>
                <button onClick={this.loadMore} className="load-more">
                  Load More
                </button>
              </div>
            ) : (
              ""
            )}
          </section>
        </div>
        <Footer />
      </main>
    );
  }
}

function updateLiveTime() {
  $(".countdown").each(function () {
    const time = getTimeRemaining($(this).attr("rel"));

    $(this).find(".h").html(time.hours);
    $(this).find(".m").html(time.minutes);
    $(this).find(".s").html(time.seconds);
  });
}

function getTimeRemaining(endTime) {
  var newDate = new Date();
  let t = endTime - Date.parse(newDate.toString());

  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  var hours = Math.floor(t / (1000 * 60 * 60));
  return {
    total: t,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}

function formatNumber(num) {
  return "$" + (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default MyContestsUpcoming;
