import React from 'react';
import './settings.scss';
import LoggedInHeader from '../Logged-in-header/Logged-in-header';
import {isAuthenticated} from "../isAuthenticated";
import Footer from "../footer/footer";
import {messages} from "../messages";
import $ from "jquery";
import { Mixpanel } from '../mixpanel/Mixpanel';

const requestOptions = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    }
};

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            repeatPassword: ''
        };
        this.openTermsAndConditions = this.openTermsAndConditions.bind(this);
        this.openPrivacyPolicy = this.openPrivacyPolicy.bind(this);
        this.openHowToPlay = this.openHowToPlay.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    componentDidMount() {
        isAuthenticated("login", "/settings");

        Mixpanel.track('web:settings_opened');

    }

    openTermsAndConditions() {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'adminContest/get-terms-and-condition', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.openPdfPopup(data['response']);
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            }).catch((error) => {
            messages('error', error);
        });
    }

    openPrivacyPolicy() {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'adminContest/get-privacy-policy', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.openPdfPopup(data['response']);
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            }).catch((error) => {
            messages('error', error);
        });
    }

    openHowToPlay() {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'adminContest/get-how-to-play', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data['success']) {
                    this.openPdfPopup(data['response']);
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            }).catch((error) => {
            messages('error', error);
        });
    }

    openPdfPopup(url) {
        $('.pdf-popup').addClass('show').find('iframe').attr('src', url);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    openChangePasswordModal() {
        $('.change-password-popup').addClass('show');
    }

    closeChangePasswordModal() {
        $('.change-password-popup').removeClass('show');
    }

    changePassword() {
        $('.loader').addClass('show');
        const oldPassword = this.state.oldPassword;
        const newPassword = this.state.newPassword;
        const repeatPassword = this.state.repeatPassword;

        Mixpanel.track('web:change_password_modal_opened');

        let error = false;
        $('input').removeClass('error').next('.error').hide().html('');

        if (oldPassword === '') {
            messages('error', 'Old Password is required.');
            error = true;
        }

        if (newPassword.length < 6) {
            messages('error', 'Password length must be at least 6 characters.');
            error = true;
        }

        if (newPassword !== repeatPassword) {
            messages('error', 'New password and confirm password should match.');
            error = true;
        }

        if (error) {
            $('.loader').removeClass('show');
            return;
        }


        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            },
            body: JSON.stringify({newPassword:newPassword, oldPassword: oldPassword, isAdmin: 0 })
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'admin/change-password', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    Mixpanel.track('web:user_changed_password');
                    messages('success', data['message']);
                    this.closeChangePasswordModal();
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            });

    }

    render() {
        return (
            <main className="settings-pages">
                <LoggedInHeader/>
                <div className="inner">
                    <h1>Settings</h1>
                    <ul>
                        <li>
                            <button onClick={this.openChangePasswordModal} title="Change Password">Change Password</button>
                        </li>
                        <li>
                            <button onClick={this.openTermsAndConditions} title="Terms & Conditions">Terms & Conditions</button>
                        </li>
                        <li>
                            <button onClick={this.openPrivacyPolicy} title="Privacy Policy">Privacy Policy</button>
                        </li>
                        <li>
                            <button  onClick={this.openHowToPlay}  title="How To Play?">How To Play?</button>
                        </li>
                    </ul>
                </div>
                <div className="change-password-popup popup">
                    <div className="inner">
                        <button onClick={this.closeChangePasswordModal} className="close-enter-password-modal close" title="Close"> </button>
                        <h2>Change Password</h2>
                        <ul>
                            <li>
                                <input type="password" name="oldPassword" placeholder="Old Password" onChange={this.handleInputChange}/>
                            </li>
                            <li>
                                <input type="password" name="newPassword" placeholder="New Password" onChange={this.handleInputChange}/>
                            </li>
                            <li>
                                <input type="password" name="repeatPassword" placeholder="Repeat Password" onChange={this.handleInputChange}/>
                            </li>
                        </ul>
                        <button onClick={this.changePassword}>Change Password</button>
                    </div>
                </div>
                <Footer/>
            </main>
        );
    }
}

export default Settings;
