import React from 'react';
import { withRouter } from "react-router";
import './my-contest-entrants-live.scss';
import $ from 'jquery';
import { Link } from "react-router-dom";

import { useJsonToCsv } from 'react-json-csv';

import { messages } from "../messages";


let ContestEntrantsBody = {
    contestId: 0,
    currentPage: 1,
    currentSize: 1000,
    isFollowing: false
};


const ContestEntrantsOptions = {
    method: 'post',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    },
    body: JSON.stringify(ContestEntrantsBody)
};


class MyContestEntrantsLive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entrants: [],
            userData: [],
            view: this.props.match.params.view,
            contestId: this.props.match.params.contestId,
            userContestId: this.props.match.params.userContestId
        };

        this.fetchContestEntrantsData = this.fetchContestEntrantsData.bind(this);

    }

    fetchContestEntrantsData(ContestEntrantsOptions) {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'userContests/entrantsListByLiveContest', ContestEntrantsOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.setState({ entrants: data['response']['data'], userData: data['response']['userData'] });
                    $('.loader').removeClass('show');
                }
            });
    }


    componentDidMount() {
        ContestEntrantsBody['contestId'] = this.props.match.params.contestId;
        ContestEntrantsOptions['body'] = JSON.stringify(ContestEntrantsBody);
        this.fetchContestEntrantsData(ContestEntrantsOptions);
    }

    exportEntrants() {
        $('.loader').addClass('show');
        const ExportEntrantsOptions = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            }
        };

        const { saveAsCsv } = useJsonToCsv();
        fetch(process.env.REACT_APP_SERVER_URL + 'contests/get-csv-by-admin-contest?contestid=' + this.state.contestId, ExportEntrantsOptions)
            .then(response => response.text())
            .then(jsonData => {
                if (jsonData['success']) {
                    const data = jsonData['response']['model']['csvData'];
                    const fields = toObject(data, jsonData['response']['model']['csvHeader']);
                    const filename = 'Entrants';
                    saveAsCsv({ fields, data, filename });
                    $('.loader').removeClass('show');
                } else {
                    $('.loader').removeClass('show');
                    messages('error', jsonData['message']);
                }
            });
    }


    storeData(rank, name) {
        window.localStorage.setItem('HeadtoHeadData', JSON.stringify({ rank: rank, name: name }));
    }


    render() {
        return (
            <div className="tab-container my-contest-entrants active" rel="entrants">
                {/* {this.state.view === 'history' ?
                    <div className="actions">
                        <button onClick={() => this.exportEntrants()} className="export-entrants">Export</button>
                    </div>
                    : <span className="actions-space"></span>
                } */}
                <div className={'contest-entrants-listing ' + this.state.view}>
                    <div className="head">
                        <span className="user">Username</span>
                        <span className="ranking">Ranking</span>
                        <span className="user-points">Earned Points</span>
                        {this.state.view === 'live' ? <span className="maximum-points">Maximum Point</span> : ''}
                        <span className="prize">Prize</span>
                    </div>
                    <ul className="listing">
                        {this.state.userData.length ? this.state.userData.map(userData => (
                            <li key={userData.userContestId} className="user">
                                <div className="user">
                                    <span>
                                        {userData.userName}<br />
                                        {userData.userContestId.toString() === this.state.userContestId.toString() ? <span className="current">Current</span> : ''}
                                    </span>
                                </div>
                                <div className="ranking">{userData.ranking}</div>
                                <div className="user-points">
                                    <span>{userData.userPoints}</span>
                                </div>
                                {this.state.view === 'live' ? <div className="maximum-points"><span>{userData.maxPoints}</span></div> : ''}
                                <div className="prize">
                                    {userData.prize > 0 ?
                                        <span className='winningPrize'>
                                            ${userData.prize}
                                        </span>
                                        :
                                        <span className='notWinningPrize'>
                                            ${userData.prize}
                                        </span>
                                    }
                                </div>
                            </li>
                        )) : ''
                        }
                        {this.state.entrants.length ? this.state.entrants.map(entrant => (
                            <li key={entrant.userContestId}>
                                <Link onClick={() => this.storeData(entrant.ranking, entrant.userName)}
                                    to={process.env.REACT_APP_START_URL + "my-contests/" + this.state.view + "/" + this.props.contest.type.toLowerCase() + "/" + this.state.contestId + "/" + this.state.userContestId + "/head-to-head/" + entrant.userId + "/" + entrant.userContestId}>
                                    <div className="user">
                                        <span>
                                            {entrant.userName}
                                        </span>
                                    </div>
                                    <div className="ranking">{entrant.ranking}</div>
                                    <div className="user-points">
                                        <span>{entrant.userPoints}</span>
                                    </div>
                                    {this.state.view === 'live' ? <div className="maximum-points"><span>{entrant.maxPoints}</span></div> : ''}
                                    <div className="prize">
                                        {entrant.prize > 0 ?
                                            <span className='winningPrize'>
                                                ${entrant.prize}
                                            </span>
                                            :
                                            <span className='notWinningPrize'>
                                                ${entrant.prize}
                                            </span>
                                        }
                                    </div>
                                </Link>
                            </li>
                        )) : ''
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

function toObject(data, arr) {
    let count = 0;
    let fields = {};
    for (var key of Object.keys(data[0])) {
        fields[key] = arr[count];
        count++;
    }
    return fields;
}

export default withRouter(MyContestEntrantsLive);
