import React, { useState, useEffect } from 'react';
import { Mixpanel } from '../mixpanel/Mixpanel';


const PrivacyPolicyPage = () => {
  useEffect(() => {
    Mixpanel.track('web:privacy_policy_opened');
  }, [])


  return(
    <div className="info-page">
      <div className="info-page-header">
        <h2>Privacy Policy</h2>
      </div>
      <div className="privacy-policy-body info-page-body">
        <h2>Privacy Policy </h2>
        <p>Last Update Posted: 5/8/18</p>
        <strong>1. Introduction </strong>
        <p>1.1 Props2Fantasy.com, Inc. (“Props2Fantasy, P2F, ThriveFantasy, we, us”) recognizes that
          people who use ThriveFantasy’s Service (“you” or “users”) value their privacy. This Privacy
          Policy details important information regarding the collection, use and disclosure of User
          information collected on ThriveFantasy’s fantasy sports website located at ThriveFantasy.com
          (the "Site"), the related mobile app, and any other features, tools, materials, or other services
          (including co-branded or affiliated services) offered from time to time by ThriveFantasy or its
          affiliate companies (the “Service”). ThriveFantasy provides this Privacy Policy to help you make
          an informed decision about whether to use or continue using the Service.
        </p>
        <p>1.2 This Privacy Policy should be read in conjunction with our Terms of Use. By accessing the
          Service, you are consenting to the information collection and use practices described in this
          Privacy Policy.
        </p>
        <p>1.3 Your use of the Service and any information you provide through the Service remains
          subject to the terms of this Privacy Policy and our Terms of Use, as each may be updated from
          time to time.
        </p>
        <p>1.4 Any questions, comments or complaints that you might have should be emailed to
          admin@thrivefantasy.com or forwarded in writing to:
        </p>
        <p>ThriveFantasy</p>
        <p>261 Madison Ave. Fl. 9</p>
        <p>New York, NY 10016</p>
        <strong>2. Information Props2Fantasy.com, Inc. Collect</strong>
        <p>By using our Service and by submitting information to us through your use of our Service then
          this Privacy Policy will apply. You provide certain personal information to ThriveFantasy when
          choosing to participate in the various activities on the Service such as registering for an
          account, participating in contests, posting messages, taking advantage of promotions,
          responding to surveys or subscribing to newsletters or other mailing lists. The personal
          information we collect from you generally may include: 
        </p>
        <p>2.1.1 your name; </p>
        <p>2.1.2 email address;</p>
        <p>2.1.3 date of birth; </p>
        <p>2.1.4 PayPal or billing information; </p>
        <p>2.1.5 contacts you choose to submit;</p>
        <p>2.1.7 username; </p>
        <p>2.1.8 location; </p>
        <p>2.1.9 your preferences; </p>
        <p>2.1.10 submissions to various competitions and promotions; </p>
        <p>2.1.11 responses to surveys; </p>
        <p>2.1.12 communications sent to us by you; </p>
        <p>2.1.13 your subscriptions to newsletters and services offered by us; and </p>
        <p>2.1.14 any other information you submit to ThriveFantasy when choosing to participate in
          various activities on the Service.
        </p>
        <p>2.2 In addition to the above, we may need to verify your identity in order for you to use some
          aspects of the Service. For purposes of verification, we may also collect the following personal
          information from you: 
        </p>
        <p>2.2.1 passport information;</p>
        <p>2.2.2 driving license information; </p>
        <p>2.2.3 other identification documents; </p>
        <p>2.2.4 permanent and temporary address; </p>
        <p>2.2.5 tax-related information; and </p>
        <p>2.2.6 other information as may be required to verify you in accordance with applicable laws and regulations.</p>
        <p>2.3 When you use our mobile app, we also may collect mobile device information like operating
          system and hardware type, numbers or codes that are unique to your particular device (such as
          IDFA or an Android Ad ID), device information, default device language, the location of your
          device (at a GPS level), and app usage information. This data also may be linked to your other
          information, including your location data.
        </p>
        <p>2.4 In addition, if you choose to log in, access or otherwise connect to ThriveFantasy, or contact
          ThriveFantasy, through a social networking service (such as Facebook), we may collect your
          user ID and user name associated with that social networking service, as well as any
          information you make public using that social networking service. We may also collect
          information you have authorized the social networking service to share with us (such as your 
          user ID, public profile information, email address, birthday, friends list, and pages you have
          "liked"). 
        </p>
        <p>2.5 Cookies Information: When you visit the www.Thrivefantasy.com website, we may send one
          or more cookies - small files - to your computer or other device, which may enable us or others
          to uniquely identify your browser. You may be able to reset your web browser to refuse all
          cookies or to indicate when a cookie is being sent. However, some features of the Site or
          Service may not function properly if the ability to accept cookies is disabled
        </p>
        <p>2.6 Log File Information: When you use our Service, our servers may automatically record
          certain information that your device sends whenever you visit any website and use certain
          apps. These server logs may include information such as your web or app request, Internet
          Protocol ("IP") address, browser type, browser language, referring/exit pages and URLs,
          platform type, number of clicks, domain names, landing pages, pages viewed and the order of
          those pages, the amount of time spent on particular pages, the date and time of your request,
          and one or more cookies that may uniquely identify your browser.
        </p>
        <p>2.7 Clear GIFs Information: When you use our Service, we may employ technology such as
          "clear GIFs" (a.k.a. Web Beacons) which are used to track the online usage patterns of our
          users. In addition, we may also use clear GIFs in HTML-based emails sent to our users to track
          which emails are opened by specific users. 
        </p>
        <strong>3. The Way ThriveFantasy Uses Your Personal Information </strong>
        <p>3.1 We use your personal information to operate, maintain, and provide to you the features
          and functionality of the Service, including but not limited to the following: 
        </p>
        <p>3.1.1 providing you with our products and services, including our games; </p>
        <p>3.1.2 processing and responding to enquiries;</p>
        <p>3.1.3 personalizing your use of the Service, </p>
        <p>3.1.4 alerting you to new features, special events, products and services, or certain third-party
          products or services in which we think you will be interested; 
        </p>
        <p>3.1.5 enforcing the legal terms that govern your use of the Service; and </p>
        <p>3.1.6 investigating and protecting the integrity of ThriveFantasy’s contests. </p>
        <p>3.2 We may use your information (both personal and non-personal information) to send you
          marketing and advertising content, including sending you advertising through multiple
          channels, such as direct mail, email, push notifications and display media. We may send you
          advertising or content regarding our products and services, or products and services that we
          market on behalf of another company, such as a sports team, a sports venue, or a other entity. 
        </p>
        <p>3.3 We may use your information to communicate with you about:</p>
        <p>3.3.1 our products and services in which you may be interested provided that you have not
          requested otherwise;
        </p>
        <p>3.3.2 newsletters and information for which you have signed up; and </p>
        <p>3.3.3 non-marketing or administrative purposes (such as notifying you of major changes to the
          Service or for customer service purposes). 
        </p>
        <p>3.3 We use your information to improve the quality and design of our Service and to create new
          features, promotions, functionality, and services such as by storing, tracking, and analyzing user
          preferences and trends.
        </p>
        <p>3.4 We also use cookies, clear gifs, log file information, and mobile app information such as
          device identifiers for purposes such as (a) remembering information so that you will not have to
          re-enter it during your visit or the next time you visit our Service; (b) providing custom,
          personalized content and information; (c) monitoring the effectiveness of our marketing
          campaigns; (d) monitoring aggregate metrics such as total number of visitors, pages viewed,
          etc.; and (e) tracking your entries, submissions, and status in promotions, sweepstakes, and
          contests. You can learn more about how these technologies are used in the Section below,
          Third Party Advertising and Analytics.
        </p>
        <p>3.5 We may anonymize, de-identify, or aggregate your information for any legitimate business
          purposes, including for reporting and research, or advertising. Such anonymized and/or
          aggregated data does not personally identify you. Sometimes, this information may be used to
          target more relevant content or offers to you across different devices – for instance, we may
          provide you with offers and content on your mobile device, based on your web-based activity. 
        </p>
        <strong>4. When ThriveFantasy Discloses Information</strong>
        <p>4.1 We may disclose the information we collect or receive from you to our subsidiaries,
          affiliated companies, agents, or other businesses, or service providers who process your
          information on our behalf in order to provide or operate the Service. For instance, these
          entities may have access to your information for purposes including (but not limited to) billing
          and accounting, hosting, marketing, promotional and email services, customer and technical
          support, and for the purpose of accepting deposits and paying withdrawals and processing such
          information on our behalf, and in each case, may use the information to make improvements to
          the services they provide to 
        </p>
        <p>us. Our agreements with these service providers limit the kinds of information they can use or
          process and require them to use reasonable efforts to keep your personal information secure. 
        </p>
        <p>4.2 When you choose to share information with social media services about your activities on
          ThriveFantasy by, for example, connecting your social media account to our Service, then
          ThriveFantasy may share your personal information and information about your use of the 
          Service with such social media sites. Please make sure to read the terms and privacy statements
          of such sites prior to connecting to our Service in this way. </p>
        <p>4.3 In the event that you win a prize we may contact use your personal information (such as
          your name and photo) in connection with publicity purposes.
        </p>
        <p>4.4 In the event that you win a promotion run by us we may be required to either publish or
          make available upon request your name and county in accordance with applicable legislation.
        </p>
        <p>4.5 We may publish a profile page that includes your username, the date you became a
          member, your stats from previous contests and, if you uploaded one, your profile picture.
          Where you post personal information about yourself through our Service to public areas of our
          Service, you acknowledge and agree that this personal information will be publically available. 
        </p>
        <p>4.6 When you participate in a contest, we may publish your username on a list of the contest
          participants, along with a link to your profile page.
        </p>
        <p>4.7 Where you have provided your consent, we may share your personal information with
          selected third parties for their commercial or marketing use in conjunction with your
          relationship with ThriveFantasy or as part of a specific program or feature. 
        </p>
        <p>4.8 In addition, we may disclose to third parties various types of your information for targeted
          online or mobile advertising, as further described in the Section titled “Third Party Advertising
          and Analytics.” </p>
        <p>4.9 We also may share personal information in other situations with your consent. </p>
        <p>4.10 ThriveFantasy also reserves the right to disclose personal information that ThriveFantasy
          believes, in good faith, is appropriate or necessary to enforce our Terms of Use, take
          precautions against liability or harm, to investigate and respond to third-party claims or
          allegations, to respond to court orders or official requests, to comply with state and federal
          regulations, including but not limited to sharing with appropriate tax authorities, to protect the
          security or integrity of our Service, to report violations of ThriveFantasy’s eligibility rules to
          third parties, including employers of individuals affiliated with other daily fantasy sites, and to
          protect the rights, property, or safety of ThriveFantasy, our users or others.
        </p>
        <p>4.11 In the event that ThriveFantasy is involved in a merger, acquisition, sale, bankruptcy,
          insolvency, reorganization, receivership, assignment for the benefit of creditors, or the
          application of laws or equitable principles affecting creditors' rights generally, or other change
          of 
        </p>
        <p>control, there may be a disclosure of your personal information to another entity related to such event. </p>
        <p>5.1 ThriveFantasy will process your personal information in accordance with this Privacy Policy,
          and as part of that ThriveFantasy provides you with certain choices about how we process your
          personal information. These choices are set out below. 
        </p>
        <p>5.2 You can access and update certain personal information ThriveFantasy holds about you at
          any time by logging into your account via the Service. You can update your marketing
          preferences and whether or not you would like us to send you newsletters in your account. You
          can also unsubscribe to marketing emails and newsletters by using the 'unsubscribe' feature in
          the communication. 
        </p>
        <p>5.3 We may occasionally send display media to you, in a targeted way, whether through web or
          mobile browsers or mobile applications. You may opt out of many third parties that support
          and send this type of targeting advertising by going to www.aboutads.info, and you may learn
          more about this type of advertising in the below Section titled “Third Party Advertising and
          Analytics.” You may opt out of tracking for mobile advertising through the settings on most
          smartphones, and you may learn more about these settings through those mobile device
          platforms, i.e., Google (here) and Apple (here). (Please note that these companies may change
          either the way these settings operate, the content or the availability of these pages.)
        </p>
        <p>5.4We may occasionally send you push notifications to your mobile device. You can turn off
          certain push notifications in the account settings of your ThriveFantasy mobile app. You can
          also turn off push notifications specific to our Service in the settings on your iOS device, or
          through the application manager in your Android device. 
        </p>
        <strong>6. Third Party Advertising And Analytics</strong>
        <p>6.1 Certain ads appearing on our Service are delivered by our advertising partners. Our
          advertising partners may use cookies and other similar technologies, often in conjunction with
          unique (anonymous) cookie identifiers, to collect data about your activities and interests,
          including the technologies described above. Some of these partners may use mobile device
          identifiers, such as Apple IDFAs and Google/Android Advertising IDs. Doing this allows the ad
          companies to recognize your computer or device each time they send you an online or mobile
          advertisement. In this way, the ad companies may compile information about where you, or
          others who are using your computer or device, saw their advertisements and measure your
          interaction with each ad. This information allows ad companies to deliver targeted
          advertisements that may be of increased interest to you, both on our Service and elsewhere
          online. Just as these technologies may be used to target ads on our websites and apps, we, or
          our advertising partners, may use these same technologies and data points (e.g., mobile
          identifiers, cookie identifiers, location-based data), through our Service or through other
          services, to target advertising (for ourselves or other companies) on other sites or mobile apps.
          Sometimes, these identifiers may be derived from a hashed or encrypted version of personal
          information such as your email address. We may use this information to measure the
          performance of our advertising as well, for instance, to evaluate which ads or content our users 
          prefer, or which are most effective. In addition, sometimes we or an advertising partner may
          tailor advertising to you across different devices.
        </p>
        <p>6.2 We also work with third-party analytics companies to collect data on our Service through
          cookies and other automated means. In some cases, the third parties mentioned in this section
          may maintain the information they collect in personally identifiable form.
        </p>
        <strong>7. Information Security</strong>
        <p>7.1 We are committed to protecting the security of your personal information. We use a variety
          of security technologies and procedures to help protect your personal information from
          unauthorized access, use, or disclosure.
        </p>
        <p>7.2 Whilst neither we, nor any other organization, can guarantee the security of information
          processed online, we do have appropriate security measures in place to protect your personal
          information. 
        </p>
        <p>7.3 To further protect your privacy and security, we reserve the right to take reasonable steps
          (such as requesting your password) to verify your identity before granting you profile access or
          making corrections.
        </p>
        <p>7.4 Additionally, you should ensure your password is kept secret at all times. You will be
          responsible for maintaining the secrecy of your password and account information.
        </p>
        <strong>8. Your California Privacy Rights </strong>
        <p>8.1 If you reside in California, you may request certain general information regarding our
          disclosure of personal information to third parties for their direct marketing purposes. To make
          such a request, please write to us at the following address:
        </p>
        <p>ThriveFantasy</p>
        <p>261 Madison Ave. Fl. 9</p>
        <p>New York, NY 10016</p>
        <strong>9. Responsible Gaming</strong>
        <p>ThriveFantasy contests ensures every user can play within their financial means. We are
          committed to integrity, fairness and reliability. We do everything possible to prevent gamingrelated problems. We’ve developed measures to maintain a responsible, safe and reliable place
          for online gaming.
        </p>
        <p>ThriveFantasy offers the options to self-exclude and self limit. If, at any point, you think you're
          spending too much time or money on ThrivFantasy, you have the option of taking a break 
          entirely. Self-limiting allows you to still play, however, within the parameters that you set for
          yourself.
        </p>
        <p>Options to Limit Play:</p>
        <ul>
          <li>Self-Exclusion</li>
          <li>Self-Limitation</li>
        </ul>
        <p>ThriveFantasy also provides options to limit the amount of funds you can deposit into your
          account by choosing an amount over a daily, weekly and monthly period. Please email
          admin@thrivefantasy.com for further details. 
        </p>
        <strong>10. Changes and updates</strong>
        <p>9.1 This Privacy Policy may be revised periodically and this will be reflected by the "Last update
          posted" date above. Please revisit this page to stay aware of any changes. If a revision to this
          Privacy Policy, in our sole discretion, is material, we will notify you by contacting you through
          the email address associated with your account.
        </p>
        <p>9.2 Contact Information: Please contact ThriveFantasy with any questions or comments about
          this Privacy Policy by emailing admin@thrivefantasy.com
        </p>
        <p></p>
      </div>
    </div>
  )
}

export default PrivacyPolicyPage;