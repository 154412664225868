import React from "react";
import { Link } from "react-router-dom";
import "./login.scss";
import logo from "../../assets/images/loginlogo.png";
import $ from "jquery";
import { GoogleLogin } from "react-google-login";
import { Mixpanel } from "../mixpanel/Mixpanel";
import mixpanel from "mixpanel-browser";

function validateEmail($email) {
    var emailReg = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return emailReg.test($email);
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            deviceID: null,
            deviceToken: null,
            devicetype: "3",
            playnowRedirectLink: window.localStorage.getItem("playnowRedirectLink"),
            redirectLink: window.localStorage.getItem("redirectLink"),
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.responseGoogle = this.responseGoogle.bind(this);
        this.showUsernameModal = this.showUsernameModal.bind(this);
        this.closeUsernameModal = this.closeUsernameModal.bind(this);
        this.usernameSubmit = this.usernameSubmit.bind(this);
        this.handleUsernameInput = this.handleUsernameInput.bind(this);
    }

    componentDidMount() {
        Mixpanel.track("web:login_opened");
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }

    handleUsernameInput(event) {
        const target = event.target;
        const value = target.value;
        // const name = target.name;

        this.setState({
            usernameInput: value,
        });
    }

    showUsernameModal() {
        $(".username-modal").addClass("show");
    }

    usernameSubmit() {
        // this.state.usernameInput

        Mixpanel.track("web:google_username_entered");

        let case1SubmitBody = {
            email: this.state.googleEmail,

            username: this.state.usernameInput,
            idToken: this.state.idToken,

            devicetype: "3",
        };
        const case1Options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(case1SubmitBody),
        };

        fetch(process.env.REACT_APP_SERVER_URL + "signupG", case1Options)
            .then((response) => response.json())
            .then((data) => {
                if (data["success"]) {
                    try {
                        window.signupScript(document, "script");
                    } catch (error) {
                        console.error(error);
                    }
                    window.localStorage.setItem(
                        "userData",
                        JSON.stringify(data["response"])
                    );
                    $(".loader").removeClass("show");

                    if (
                        window.localStorage.getItem("redirectLink") === null ||
                        window.localStorage.getItem("redirectLink") === "null" ||
                        window.localStorage.getItem("redirectLink") === ""
                    ) {
                        window.location.href =
                            process.env.REACT_APP_START_URL + "completed-registration/wallet";
                    } else {
                        window.location.href =
                            process.env.REACT_APP_START_URL +
                            window.localStorage.getItem("redirectLink");
                    }
                } else {
                    $(".server-error").show().html(data["message"]);
                    $(".loader").removeClass("show");
                }
            });
    }

    closeUsernameModal() {
        $(".username-modal").removeClass("show");
        Mixpanel.track("web:google_username_closed");
    }

    responseGoogle = (googleResponse) => {
        let requestBody = {
            email: googleResponse.profileObj.email,
        };
        const googleSignInOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(requestBody),
        };

        fetch(
            process.env.REACT_APP_SERVER_URL + "signupGoogle",
            googleSignInOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data["success"]) {
                    if (data["response"] === 1) {
                        this.setState({
                            googleEmail: googleResponse.profileObj.email,
                            idToken: googleResponse.tokenId,
                        });
                        this.showUsernameModal();
                    }
                    if (data["response"] === 2) {
                        $(".server-error")
                            .show()
                            .html("This User Exists But Is Not A Google Account");
                    }
                    if (data["response"] === 3) {
                        let loginReqBody = {
                            email: googleResponse.profileObj.email,
                            password: null,
                            deviceID: "",
                            deviceToken: "",
                            devicetype: "3",
                            idToken: googleResponse.tokenId,
                        };
                        const case3Options = {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                            },
                            body: JSON.stringify(loginReqBody),
                        };
                        fetch(process.env.REACT_APP_SERVER_URL + "loginWebsiteNewest", case3Options)
                            .then((response) => response.json())
                            .then((data) => {
                                if (data["success"]) {
                                    window.localStorage.setItem(
                                        "userData",
                                        JSON.stringify(data["response"])
                                    );

                                    // redirect within Thrive
                                    if (
                                        this.state.redirectLink !== null &&
                                        this.state.redirectLink !== "null" &&
                                        this.state.redirectLink !== ""
                                    ) {
                                        window.location.href =
                                            process.env.REACT_APP_START_URL + this.state.redirectLink;
                                    }
                                    // default redirect – Contest Lobby
                                    else {
                                        window.location.href =
                                            process.env.REACT_APP_START_URL + "contest-lobby";
                                    }
                                    const user = data["response"]["userDto"];

                                    Mixpanel.identify(user["userid"]);
                                    Mixpanel.track("web:user_signed_in", { 'Provider': 'Google' });
                                    Mixpanel.people.set({
                                        $first_name: user["userName"],
                                        $email: user["email"],
                                    });

                                    // window.location.href = process.env.REACT_APP_START_URL + "contest-lobby";
                                } else {
                                    $(".server-error").show().html(data["message"]);
                                }
                            });
                    }
                }
            });
    };

    handleSubmit(event) {
        $(".loader").addClass("show");
        event.preventDefault();

        const email = this.state.email;
        const password = this.state.password;

        let error = false;
        $("input").removeClass("error").next(".error").hide().html("");

        if (!validateEmail(email)) {
            $('input[name="email"]')
                .addClass("error")
                .next(".error")
                .show()
                .html("Invalid email.");
            error = true;
        }

        if (email === "") {
            $('input[name="email"]')
                .addClass("error")
                .next(".error")
                .show()
                .html("Email is required.");
            error = true;
        }

        if (password.length < 6) {
            $('input[name="password"]')
                .addClass("error")
                .next(".error")
                .show()
                .html("Password length must be at least 6 characters.");
            error = true;
        }

        if (error) {
            $(".loader").removeClass("show");
            return;
        }

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(this.state),
        };
        fetch(process.env.REACT_APP_SERVER_URL + "loginWebsiteNewest", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data["success"]) {
                    window.localStorage.setItem(
                        "userData",
                        JSON.stringify(data["response"])
                    );

                    const user = data["response"]["userDto"];
                    // mixpanel.init('37bcd3d006bcab31766a97024fa3fd4a');
                    Mixpanel.identify(user["userid"]);
                    Mixpanel.track("web:user_signed_in");
                    Mixpanel.people.set({
                        $first_name: user["userName"],
                        $email: user["email"],
                    });

                    $(".loader").removeClass("show");

                    // if (this.state.playnowRedirectLink !== null && this.state.playnowRedirectLink !== 'null' && this.state.playnowRedirectLink !== "") {
                    // window.localStorage.clear('playnowRedirectLink');
                    // window.open('https://www.' + this.state.playnowRedirectLink, '_blank');
                    // window.location.href = 'contest-lobby'
                    // }
                    if (
                        this.state.redirectLink !== null &&
                        this.state.redirectLink !== "null" &&
                        this.state.redirectLink !== ""
                    ) {
                        // window.localStorage.clear('redirectLink');
                        window.location.href =
                            process.env.REACT_APP_START_URL + this.state.redirectLink;
                    } else {
                        window.location.href =
                            process.env.REACT_APP_START_URL + "contest-lobby";
                    }
                } else {
                    $(".server-error").show().html(data["message"]);
                    $(".loader").removeClass("show");
                }
            });
    }

    render() {
        return (
            <main className="Login-page">
                <section className="left">
                    <Link to={process.env.REACT_APP_START_URL} title="Thrive Fantasy">
                        <img src={logo} alt="Thrive Fantasy" />
                    </Link>
                    <h1>
                        <strong>Welcome Back!</strong>
                    </h1>
                </section>
                <section className="right">
                    <div>
                        <h1>Sign In</h1>
                        <form onSubmit={this.handleSubmit}>
                            <ul>
                                <li>
                                    <input
                                        className="field-input"
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        onChange={this.handleInputChange}
                                    />
                                    <span className="error"></span>
                                </li>
                                <li>
                                    <input
                                        className="field-input"
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        onChange={this.handleInputChange}
                                    />
                                    <span className="error"></span>
                                </li>
                                <li>
                                    <input type="submit" value="Sign In" />
                                </li>
                            </ul>
                        </form>
                        <p className="server-error"></p>
                        <p className="text-nav">
                            Don't have an account?{" "}
                            <Link to={process.env.REACT_APP_START_URL + "sign-up"}>
                                Get started!
                            </Link>
                        </p>
                        <Link className="forgot-password" to="/forgot-password">
                            Forgot Password?
                        </Link>{" "}
                        <br></br> <br></br>
                        <GoogleLogin
                            clientId="952828972098-i7ddorimqhdcgp7gp3n8rs7dqa42pipv.apps.googleusercontent.com"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                        >
                            {" "}
                            Sign In/Up With Google
                        </GoogleLogin>
                    </div>
                    <div className="username-modal">
                        <div className="inner">
                            <button
                                onClick={this.closeUsernameModal}
                                className="close-username-modal close"
                                title="Close"
                            ></button>
                            <h2>Choose a Username</h2>
                            <div>
                                <div>
                                    <label>
                                        Please Create a Username to Complete Registration
                                    </label>
                                    <input
                                        name="usernamePick"
                                        onChange={this.handleUsernameInput}
                                        className="username-pick"
                                        style={{ width: 300 }}
                                    />
                                </div>
                            </div>
                            <button
                                onClick={this.usernameSubmit}
                                className="submit-username-now"
                                title="Pay Now"
                            >
                                Register
                            </button>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default Login;
