import React from 'react';
import { withRouter } from "react-router";
import {Link} from "react-router-dom";
import './reset-password.scss';
import logo from "../../assets/images/loginlogo.png";
import $ from "jquery";



class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            confirmPassword: "",
            resetPasswordToken: this.props.match.params.resetPasswordToken,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        $('.loader').addClass('show');
        event.preventDefault();

        let error = false;
        const { newPassword, confirmPassword } = this.state;

        $('#reset-password-status-message')
            .removeClass("error")
            .hide()
            .html("");

        if (newPassword.length < 6) {
            $('input[name="newPassword"]')
                .addClass("error")
                .next(".error")
                .show()
                .html("Password length must be at least 6 characters.");
            error = true;
        }else{
            $('input[name="newPassword"]')
                .removeClass("error")
                .next(".error")
                .hide()
                .html("");
        }

        if(newPassword !== confirmPassword){
            $('input[name="confirmPassword"]')
                .addClass("error")
                .next(".error")
                .show()
                .html("Password and confirm password fields must match.");
            error = true;
        }else{
            $('input[name="confirmPassword"]')
                .removeClass("error")
                .next(".error")
                .hide()
                .html("");
        }

        const { resetPasswordToken } = this.state;

        if(!resetPasswordToken){
            $('#reset-password-status-message')
                .addClass("error")
                .show()
                .html("Invalid token, please contact an administrator.");
            error = true;
        }

        if (error) {
            $('#reset-password-status-message')
                .removeClass("error")
                .show()
                .html("");
            $(".loader").removeClass("show");
            return;
        }


        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(this.state),
        };


        fetch(process.env.REACT_APP_SERVER_URL + `reset-password/${resetPasswordToken}`, requestOptions)
            .then((response) => {
                if(response.ok){
                    response.json()
                }else{
                    throw new Error("Unable to reset password");
                }
            })
            .then((data) => {
                $('#reset-password-status-message')
                    .removeClass("error")
                    .show()
                    .html("Successfully reset password!");
                $(".loader").removeClass("show");
            })
            .catch((err) => {
                $('#reset-password-status-message')
                    .addClass("error")
                    .show()
                    .html("Unable to reset password, please contact an administrator.");
                $(".loader").removeClass("show");
            })

    }


    render() {
        return (
            <main className="Login-page" id="reset-password-page">
                <section className="left">
                    <Link to={process.env.REACT_APP_START_URL} title="Thrive Fantasy">
                        <img src={logo} alt="Thrive Fantasy"/>
                    </Link>
                </section>
                <section className="right">
                    <h1>Choose new password</h1>
                    <form onSubmit={this.handleSubmit}>
                        <ul>
                            <li>
                                <input
                                    type="password"
                                    name="newPassword"
                                    placeholder="New Password"
                                    className="field-input"
                                    onChange={this.handleInputChange}
                                />
                                <span className="error"></span>
                            </li>
                            <li>
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    className="field-input"
                                    onChange={this.handleInputChange}
                                />
                                <span className="error"></span>
                            </li>
                        </ul>
                        <button
                            onClick={this.usernameSubmit}
                            className="reset-password-submit"
                        >
                            Reset Password
                        </button>
                    </form>
                    <p id="reset-password-status-message"></p>
                    <p>Password must be at least 6 characters long</p>
                </section>
            </main>
        );
    }
}

export default withRouter(ResetPassword);
