import React from "react";
import { Link } from "react-router-dom";
import "./header.scss";
import { messages } from "../messages";

//images
import logo from "../../assets/images/logo.png";
import menu from "../../assets/images/menu.png";
import $ from "jquery";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.openHowToPlay = this.openHowToPlay.bind(this);
    this.openPdfPopup = this.openPdfPopup.bind(this);
    this.closePdfPopup = this.closePdfPopup.bind(this);
  }

  componentDidMount() {
    $(".mobile-open").on("click", function () {
      $(this).next("nav").toggleClass("open");
    });
  }

  openHowToPlay() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    $(".loader").addClass("show");
    fetch(
      process.env.REACT_APP_SERVER_URL + "adminContest/get-how-to-play",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data["success"]) {
          this.openPdfPopup(data["response"]);
          $(".loader").removeClass("show");
        } else {
          messages("error", data["message"]);
          $(".loader").removeClass("show");
        }
      })
      .catch((error) => {
        messages("error", error);
      });
  }

  openPdfPopup(url) {
    $(".pdf-popup").addClass("show").find("iframe").attr("src", url);
    // $('.pdf-popup').addClass('show').find('embed').attr('src', url);
  }

  closePdfPopup() {
    $(".pdf-popup").removeClass("show").find("iframe").attr("src", "");
  }

  handleScroll() {
    let howToPlayButton = document.getElementById("how-to-play");
    howToPlayButton.scrollIntoView();
  }

  render() {

    return (
      <header className="landing-page-header">
        <div className="inner">
          <Link to={process.env.REACT_APP_START_URL} title="Thrive Fantasy">
            <img src={logo} alt="Thrive Fantasy" />
          </Link>
          <button className="mobile-open" title="Open mune">
            <img src={menu} alt="menu" />
          </button>
          <nav>
            <ul>
              {this.props.onLandingPage ? (
                <li>
                  <a onClick={this.handleScroll} style={{ color: "white" }}>
                    How to Play
                  </a>
                </li>
              ) : null}
              {/* <li>
                                <a onClick={this.handleScroll}>How to Play</a>
                            </li> */}
              <li>
                <Link
                  to={process.env.REACT_APP_START_URL + "login"}
                  title="Log in"
                  loginRedirect={this.props.loginRedirect}
                >
                  {" "}
                  Log in
                </Link>
              </li>
              <li>
                {window.location.pathname === "/colin" ? (
                  <Link
                    to={process.env.REACT_APP_START_URL + "colin-sign-up"}
                    className="blue-btn"
                    title="Sign Up"
                  >
                    Sign Up
                  </Link>
                ) : (
                  <Link
                    to={process.env.REACT_APP_START_URL + "sign-up"}
                    className="blue-btn"
                    title="Sign Up"
                  >
                    Sign Up
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
        <div className="pdf-popup">
          <div className="inner">
            <button
              onClick={this.closePdfPopup}
              className="close-pdf-modal"
              title="Close"
            >
              x
            </button>
            <iframe src="" frameBorder="0">
              {" "}
            </iframe>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
