import React, { useState, useEffect } from 'react';
import { Mixpanel } from '../mixpanel/Mixpanel';


const TermsAndConditionsPage = () => {
  useEffect(() => {
    Mixpanel.track('web:terms_and_conditions_opened');
  }, [])

  
  return(
    <div className="info-page">
      <div className="info-page-header">
        <h2>Terms and Conditions</h2>
      </div>
      <div className="terms-and-conditions info-page-body">
        <h2>Terms of Use</h2>
        <p>Our Terms of Use have been updated as of 4/11/18</p>
        <p>Props2Fantasy.com, Inc. is not associated with Apple, Inc. Apple does not sponsor and is not involved in any way with ThriveFantasy contests. </p>
        <p>IMPORTANT NOTICE:THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS AS DETAILED IN SECTION 15 </p>
        <h3>1. Acceptance of Terms </h3>
        <p>Props2Fantasy.com, Inc. (collectively, “We”, “Us”, or “ThriveFantasy”) provide a fantasy sports
          website located at ThriveFantasy.com (the "Site") and a related mobile app — which include all
          of the text, images, audio, code and other material they contain or provide (collectively, the
          “Content”) and all of the features, contests and other services they provide. Residents of the
          United States will be contracting with Props2fantasy.com, Inc. The Site, the mobile app, and any
          other features, tools, materials, or other services (including co-branded or affiliated services)
          offered from time to time by ThriveFantasy.com are referred to here as the “Service.” Please
          read these Terms of Use (the “Terms” or “Terms of Use”) carefully before using the Service. By
          using or otherwise accessing the Service, or clicking to accept or agree to these Terms where
          that option is made available, you (1) accept and agree to these Terms and (2) consent to the
          collection, use, disclosure and other handling of information as described in our Privacy Policy
          and (3) any additional terms, rules and conditions of participation in particular contests issued
          by Props2Fantasy from time to time. If you do not agree to the Terms, then you may not access
          or use the Content or Services.
        </p>
        <p>ThriveFantasy may issue additional terms, rules and conditions of participation in particular contests. You agree to be subject to those additional rules if you participate in such contests. </p>
        <h3>2. Modification of Terms of Use</h3>
        <p>Except for Section 15, providing for binding arbitration and waiver of class action rights,
          ThriveFantasy reserves the right, at its sole discretion, to modify or replace the Terms of Use at
          any time. The most current version of these Terms will be posted on our Site. You shall be
          responsible for reviewing and becoming familiar with any such modifications. If a revision to the
          Terms, in our sole discretion, is material, we will notify you by contacting you through the email
          address associated with your account. Use of the Services by you after any modification to the
          Terms constitutes your acceptance of the Terms of Use as modified.
        </p>
        <h3>3. Eligibility </h3>
        <p>You hereby represent and warrant that you are fully able and competent to enter into the
          terms, conditions, obligations, affirmations, representations and warranties set forth in these
          terms and to abide by and comply with these terms.
        </p>
        <p>By depositing money or entering a contest, you are representing and warranting that:</p>
        <ul className="bullet-list">
          <li>you are of 18 years of age or older (21 years of age or older in Massachusetts, 19 years of age or older in Nebraska) </li>
          <li>you are a citizen or resident of the United States of America and that you have an address</li>
          <li>in the United States of America;  </li>
          <li>at the time of deposit or game entry you are physically located in the United States of America in a jurisdiction in which participation in the contest is not prohibited by applicable law;  </li>
          <li>you are not listed on any U.S. Government list of prohibited or restricted parties;  </li>
          <li>you will abide at all times by these Terms of Use and any other agreements between
            you and ThriveFantasy regarding your use of the Service or participation in games; when
            depositing funds or entering a paid contest, you are not physically located in of any of
            the following states: Alabama, Arizona, Hawaii, Idaho, Indiana, Iowa, Louisiana, Maine,
            Mississippi, Missouri, Montana, Nevada, New Hampshire, New Jersey, New York,
            Pennsylvania, Tennessee, Vermont, Virginia, or Washington;
          </li>
          <li>you are not any member, officer, employee or agent of Props2Fantasy.com, Inc;  </li>
          <li>you are not any spouse, child, brother, sister, or parent residing as a member of the same household in the principal place place of abode of any member, officer, employee or agent of Props2Fantasy.com, Inc;  </li>
          <li>you are not any amateur or professional athlete whose performance may be used to determine the outcome of a contest; </li>
          <li>you are not any sports agent, team employee, referee, or league official associated with any sport or athletic event on which contests are based; </li>
          <li>you are not subject to backup withholding tax because: (a) you are exempt from backup
            withholding, or (b) you have not been notified by the Internal Revenue Service (IRS) that
            you are subject to backup withholding as a result of a failure to report all interest or
            dividends, or (c) the IRS has notified you that you are no longer subject to backup
            withholding.
          </li>
          <li>When entering any contest that awards prizes, you are not an employee or operator of another daily fantasy site that charges entrance fees or offers cash prizes  </li>
          <li>You do not, by virtue of affiliation with another daily fantasy site, have access to the site’s pre-release non-public confidential data about contest-related information.  </li>
          <li>If ThriveFantasy determines that you do not meet the eligibility requirements of this
            section, then you are not authorized to use the Service. ThriveFantasy may require you
            to provide proof that you are eligible to participate according to this section prior to
            receiving a prize. This includes by requesting that you fill out an affidavit of eligibility or
            other verification information (as discussed in Section 4 below). If ThriveFantasy
            otherwise determines that you do not meet the eligibility requirements of this section,
            in addition to any rights that ThriveFantasyl may have in law or equity, ThriveFantasy
            reserves the right to terminate your account, withhold or revoke the awarding of any
            prizes associated with your account or limit your ability to withdraw. In such a situation,
            ThriveFantasy may pay out any withheld or revoked prizes to the other entrants in the
            relevant contest in a manner consistent with the prize structure of the contest, to be
            precisely determined by ThriveFantasy in its sole discretion. ThriveFantasy also reserves
            the right to withhold revoked prizes to use in furtherance of its fraud prevention or antimoney laundering efforts. ThriveFantasy employees may use the Service for the purpose
            of testing the user experience, but may not withdraw money or prizes except when
            playing in a private league. Relatives of ThriveFantasy employees with whom they share
            a household are not eligible to participate in paid contests unless they are private
            contests with other ThriveFantasy employees or household members. ThriveFantasy
            consultants or promoters of the Service may play in contests without such limitation,
            but only if (i) their arrangement with ThriveFantasy does not permit them to have any
            access to non-public Service data or any other data not made available to all players on
            the Service and (ii) they do not receive any other advantages in their play on the Service.
          </li>
        </ul>
        <p>Employees or operators of other daily fantasy sites that charge entry fees or offer cash prizes,
          including but not limited to DraftKings and Yahoo, and individuals who, by virtue of affiliation
          with another daily fantasy site, have access to the site’s pre-release non-public confidential
          data about game-related information may not enter any contests in which a real money prize is
          awarded. If such person enters a ThriveFantasy contest that awards prizes, ThriveFantasy will
          disqualify the entry, will not award a prize, and may report such person’s violation of this
          provision to the daily fantasy site for which the entrant is employed by, operates or affiliated
          with. Additionally, ThriveFantasy may maintain information about the entrant sufficient to
          assist ThriveFantasy in blocking the user from entering future ThriveFantasy contests, unless
          and until ThriveFantasy determines, in its sole discretion, that the entrant is no longer an
          employee or operator of another daily fantasy site or no longer has access to pre-release nonpublic confidential data about game-related information by virtue of affiliation with a daily
          fantasy site.
        </p>
        <p>Athletes, coaches and other team management, team support personnel (e.g. without
          limitation, team physicians) and team owners may not participate in any ThriveFantasy contests
          in the sport or sports with which they’re associated. Team owners, referees, league employees,
          sports commissioners and other individuals who through an ownership interest or gamerelated employment can influence the gameplay are likewise ineligible.
        </p>
        <p>ThriveFantasy is not affiliated with or sponsored by the National Football League, the National
          Hockey League or Major League Baseball.
        </p>
        <h3>4. Conditions of participation</h3>
        <strong>4.1 Registration</strong>
        <p>In order to participate in a contest on the Service, you must register for an account. By
          registering as a user of the Service, you agree to provide accurate, current and complete
          information about yourself as prompted (such information being the “Registration Data”) and
          maintain and promptly update the Registration Data to keep it accurate, current and complete.
          If
        </p>
        <p>you provide any information that is inaccurate, not current or incomplete, or ThriveFantasy has
          reasonable grounds to suspect that such information is inaccurate, not current or incomplete,
          ThriveFantasy may deny you access to areas requiring registration, or terminate your account,
          at its sole discretion.
        </p>
        <p>You may establish, maintain, use and control only one account on the Service. Each account on
          the Service may only be owned, maintained, used and controlled by one individual. For
          avoidance of doubt, users may not “co-own” accounts on the Service. In the event
          ThriveFantasy determines that you have opened, maintained, used or controlled more than one
          account, in addition to any other rights that ThriveFantasy may have, ThriveFantasy reserves
          the right to suspend or terminate any or all of your accounts and terminate, withhold or revoke
          the awarding of any prizes.
        </p>
        <p>You agree that the sole and specific purpose of creating an account on ThriveFantasy is to
          participate in fantasy sports contests on the Service. ThriveFantasy shall be entitled to suspend,
          limit or terminate your account if we determine, in our sole discretion, that you are depositing
          funds without the intention of using them in contests on the Service. In such circumstances, we
          may also report such activity to relevant authorities.
        </p>
        <h3>4.2 Account Password and Security</h3>
        <p>At the time of registration for online account access, you must provide a valid email address
          and supply a Username and Password to be used in conjunction with your account. You may
          not use a Username that promotes a commercial venture or a Username that ThriveFantasy in
          its sole discretion deems offensive. Many portions of the Service require registration for access
          (the "Restricted Areas"). You are responsible for maintaining the confidentiality of Password,
          and are fully responsible for all uses of your Username and Password, whether by you or
          others. You agree to (a) never to use the same Password for the Service that you use or have
          ever used outside of the Service; (b) keep your Username and Password confidential and not
          share them with anyone else; (c) immediately notify ThriveFantasy of any unauthorized use of
          your Username and Password or account or any other breach of security; and (d) use only your
          own Username and Password to access the Service’s Restricted Areas. ThriveFantasy cannot 
          and will not be liable for any loss or damage arising from your failure to comply with this
          Section.
        </p>
        <p>You acknowledge and agree that ThriveFantasy is authorized to act on instructions received
          through the use of your Username and Password, and that ThriveFantasy may, but is not
          obligated to, deny access or block any transaction made through use of your Username and
          Password without prior notice if we believe your Username and Password are being used by
          someone other than you, or for any other reason.
        </p>
        <p>ThriveFantasy may require you to change your Username or may unilaterally change your Username. </p>
        <h3>4.3 Communications and Information Practices</h3>
        <p>As a result of your registration for the Service, you may receive certain commercial
          communications from ThriveFantasy. You understand and agree that these communications are
          part of your registration, and that, to the extent required by law, you may opt out of receiving
          these communications at any time by either using the unsubscribe functionality or sending an
          email to info [at] props2fantasy [dot] com. Following such an opt-out, we may still
          communicate with you via email to the extent permitted by applicable law.
        </p>
        <h3>4.4 Disqualification and Cancellation </h3>
        <p>ThriveFantasy also reserves the right to cancel contests, in our sole discretion, without any restrictions. </p>
        <p>ThriveFantasy, in its sole discretion, may disqualify you from a contest or the entire Service,
          refuse to award fantasy points or prizes and require the return of any prizes, or suspend, limit,
          or terminate your account if you engage in conduct ThriveFantasy deems, in its sole discretion,
          to be improper, unfair, fraudulent or otherwise adverse to the operation of the Service or in
          any way detrimental to other users. Improper conduct includes, but is not limited to: falsifying
          personal information, including payment information, required to use the Service or claim a
          prize; violating eligible payment method terms, including the terms of any cash rewards
          payment card, violating any of these rules, accumulating points or prizes through unauthorized
          methods such as unauthorized scripts or other automated means; tampering with the
          administration of the Service or trying to in any way tamper with the computer programs
          associated with the Service; obtaining other entrants’ information and spamming other
          entrants; and abusing the Service in any way; or otherwise violating these Terms of Use. You
          acknowledge that the forfeiture and/or return of any prize shall in no way prevent FanDuel
          from informing the relevant authorities, and/or pursuing criminal or civil proceedings in
          connection with such conduct.
        </p>
        <p>If for any reason the Service is not running as originally planned, ThriveFantasy reserves the
          right, in its sole discretion, to disqualify any individual implicated in or relating to the cause 
          and/or to cancel, terminate, extend, modify or suspend the Service, and select the winner(s)
          from all eligible entries received. If such cancellation, termination, modification or suspension
          occurs, notification may be posted on the Site.
        </p>
        <p>The failure of ThriveFantasy to comply with any provision of these Terms due to an act of God,
          hurricane, war, fire, riot, earthquake, terrorism, act of public enemies, actions of governmental
          authorities outside of the control of ThriveFantasy (excepting compliance with applicable codes
          and regulations) or other force majeure event will not be considered a breach of these Terms.\
        </p>
        <h3>4.5 Deposits and Withdrawals Generally </h3>
        <p>By depositing funds or entering paid contests, you agree to provide us with a valid mailing
          address, date of birth and social security number and any other information we may require in
          order to run appropriate identity checks and comply with applicable rules and regulations. If
          necessary, you may be required to provide appropriate documentation that allows us to verify
        </p>
        <p>you. While your account is pending verification, you may be able to deposit funds into your
          account and participate in contests, but you will not be able to withdraw any funds from your
          account until verification is complete. If we are unable to verify you, we reserve the right to
          suspend your account and withhold any funds until such time as we have been able to
          successfully verify you.
        </p>
        <p>We also may conduct checks for Terms compliance, including anti-fraud checks on playing
          patterns and deposits prior to processing a withdrawal, and we may request additional
          information before permitting a withdrawal. Subject to such checks, you may close your
          account and withdraw your deposits and/or winnings at any time and for any reason.
        </p>
        <p>In cases where you have participated in a promotion to receive a bonus or other benefit, you
          may be required to play through your deposit (by entering contests) whose total entry fees
          equal the value of the deposit to receive the entirety of such bonus.
        </p>
        <h3>4.6 Taxation</h3>
        <p>Each year all winners who have won $600 or more over the previous year must provide
          updated address and social security details to ThriveFantasyl. These details will be used to allow
          ThriveFantasy to comply with tax regulations and may be shared with appropriate tax
          authorities. You, not ThriveFantasy, are responsible for filing and paying applicable state and
          federal taxes on any winnings. ThriveFantasy does not provide tax advice, nor should any
          statements in this agreement or on the Service be construed as tax advice.
        </p>
        <h3>4.7 Publicity </h3>
        <p>By entering a contest, you consent to ThriveFantasy’s and its service providers’ and business
          partners’ use of your name, voice, likeness, location and photograph in connection with the
          development, production, distribution and/or exploitation (including marketing and promotion) 
          of the selected contest and/or other ThriveFantasy contests and ThriveFantasy generally, unless
          otherwise prohibited by law. Props2Fantasy.com, Inc. and its business partners reserve the
          right to make public statements about the entrants and winner(s), on-air, on the Internet, or
          otherwise, prior to, during, or following the contest. Entrants agree that Props2Fantasy.com,
          Inc. may announce any winner's name on-air or on any of its websites or any other location at
          any time in connection with the marketing and promotion of ThriveFantasy or other contests or
          games operated by Props2Fantasy.com, Inc. You agree that participation in and (where
          applicable) the winning of a prize in connection with a contest constitute complete
          compensation for your obligations under this paragraph, and you agree not to seek to charge a
          fee or impose other conditions on the fulfillment of these obligations. The rules specific to
          certain contests may contain additional publicity obligations or may require a written signature
          on a separate publicity waiver.
        </p>
        <h3>5. Game Rules</h3>
        <p>5.1 Game of Skill </p>
        <p>ThriveFantasy is a game of skill. Winners are determined by the criteria stated in each contest's
          rules. For each contest, winners are determined by the individuals who use their skill and
          knowledge of relevant professional sports information and fantasy sports rules to accumulate
          the most points. Points are accumulated through the performance of individual athletes across
          multiple sports events. All real-money contests in ThriveFantasy must be made up of players
          drawn from a minimum of two sporting events.
        </p>
        <h3>5.2 Entry fees</h3>
        <p>Each ThriveFantasy contest has an entry fee listed in US dollars. When you opt to participate in
          a contest, that amount in US dollars will be debited from your ThriveFantasy account. In the
          event of a dispute regarding the identity of the person submitting an entry, the entry will be
          deemed submitted by the person in whose Username the entry was submitted, or if possession
          of the Username itself is contested and in ThriveFantasy’s opinion sufficiently uncertain, the
          name in which the email address on file was registered with the email service provider.
          ThriveFantasy reserves the right not to award a prize to an individual it believes in its sole
          discretion did not submit the winning entry.
        </p>
        <p>Users may cancel entries up to 15 minutes prior to game start. If you timely cancel an entry,
          your entry fee will be refunded back into your ThriveFantasy account. ThriveFantasy has no
          obligation to honor cancellation requests received within 15 minutes of or after game start.
          Similarly, contests lock when the first game starts and no more entries or lineup changes may
          be made at that time.
        </p>
        <h3>5.3 Contest Term </h3>
        <p>ThriveFantasy offers contests for multiple professional sports events generally taking place on a single day or at most a week. </p>
        <h3>5.4 Prizes </h3>
        <p>After each contest ends, the tentative winners are announced but remain subject to final
          verification. The players in each contest who accumulate the most points and comply with
          eligibility requirements and applicable rules will win prizes as set out in the posted contest
          details. Prizes are added to the winning player's account balance. In the event of a tie, the prize
          is divided evenly between the tied players, unless otherwise specified.
        </p>
        <p>ThriveFantasy offers a number of different types of contests. For each contest, we announce the entry fees and prizes in advance on the contest page. </p>
        <p>Prize calculations are based on the results as of the time when final scoring is tabulated by
          ThriveFantasy. ThriveFantasy reserves the right to make adjustments based on errors or
          irregularities in the transmission of information to us from our stats provider or in our
          calculation of results. We also may make adjustments in the event of noncompliance with the
          Terms. ThriveFantasy has no obligation to delay the awarding of a prize in anticipation of any
        </p>
        <p>adjustment, and we reserve the right to reverse payments in the event of any adjustment. You agree to cooperate with our efforts to reverse payments. </p>
        <p>No substitution or transfer of a prize is permitted. All taxes associated with the receipt or use of
          any prize are the sole responsibility of the winner. In the event that the awarding of any prizes
          to winners of the Contest is challenged by any legal authority, ThriveFantasy reserves the right
          in its sole discretion to determine whether or not to award or adjust such prizes. In all disputes
          arising out of the determination of the winner of ThriveFantasy contests, Props2Fantasy.com,
          Inc. is the sole judge and its actions are final and binding.
        </p>
        <h3>5.5 Notification</h3>
        <p>Winners are generally posted on the Site after the conclusion of each contest. Winners may be
          requested to return via email or regular mail an affidavit of eligibility, a publicity agreement and
          appropriate tax forms by a specified deadline. Failure to comply with this requirement can
          result in disqualification. Any prize notification returned as undeliverable may result in
          disqualification and selection of an alternate winner. In addition, a list of winners and winner's
          names for each competition period may be obtained by writing to:
        </p>
        <p>Props2Fantasy.com, Inc. 160 East 48th St</p>
        <p>New York, NY 10017</p>
        <strong>5.6 Bonuses and Promotions</strong>
        <p>We frequently offer bonuses to newly depositing users and for other marketing purposes. In
          the event of abuse of the bonus system by any user, ThriveFantasy reserves the right to retract
          your user bonuses. Certain bonuses are awarded as a “pending bonus” earned gradually when
          users enter and complete paid contests. A user’s pending bonus is converted into a monetary 
          credit that can be used to play on ThriveFantasy as the user enters real money contests in
          accordance with the terms of the offer or promotion. Unless otherwise stated, any unconverted
          pending bonus remaining in a player’s account 45 days after it has been initially credited can be
          removed by ThriveFantasy. Any credit a new user receives is for entry into competitions on
          ThriveFantasy and can only be withdrawn if they have been previously entered into at least one
          game. Additionally, if a user immediately withdraws money after a deposit which delivers a
          deposit bonus then the bonus will be retracted.
        </p>
        <h2>6. Conduct</h2>
        <p>As a condition of use, you promise not to use the Services for any purpose that is unlawful or
          prohibited by these Terms, or any other purpose not reasonably intended by ThriveFantasy. By
          way of example, and not as a limitation, you agree not to:
        </p>
        <ul>
          <li> abuse, harass, impersonate, intimidate or threaten other ThriveFantasy users; </li>
          <li>• post or transmit, or cause to be posted or transmitted, any Content that are infringing,
            libelous, defamatory, abusive, offensive, obscene, pornographic or otherwise violates
            any law or right of any third party;
          </li>
          <li>use the Service for any unauthorized purpose, or in violation of any applicable law,
            including intellectual property laws;
          </li>
          <li>post or transmit, or cause to be posted or transmitted, any communication or
            solicitation designed or intended to obtain password, account, or private information
            from any ThriveFantasy user;
          </li>
          <li>create or submit unwanted email ("Spam") to any other ThriveFantasy users; </li>
          <li>• infringe upon the intellectual property rights of ThriveFantasy, its users, or any third
            party;
          </li>
          <li>• submit comments linking to affiliate programs, multi-level marketing schemes, sites
            repurposing existing stories or off-topic content;
          </li>
          <li>• post, email, transmit, upload, or otherwise make available any material that contains
            software viruses or any other computer code, files or programs designed or functioning
            to interrupt, destroy, or limit the functionality of any computer software or hardware or
            telecommunications equipment;
          </li>
          <li>use any robot, spider, scraper, sniping software or other automated means to access the
            Service for any purpose (except for RSS feed access) without our express written
            permission. Additionally, you agree that you will not: (1) take any action that imposes,
            or may impose in our sole discretion an unreasonable or disproportionately large load
            on our infrastructure; (2) interfere or attempt to interfere with the proper working of
            the Service or any activities conducted on the Service; or (3) bypass any measures we
            may use to prevent or restrict access to the Service;
          </li>
          <li>use artificial means, including creating multiple user accounts, to inflate your position
            and standing with the ThriveFantasy leader boards and community;
          </li>
          <li>• use unauthorized scripts; all authorized scripts will be made available through the
            Service prior to game entry;
          </li>
          <li>• advertise to, or solicit, any user to buy or sell any products or Service, or use any
            information obtained from the Service in order to contact, advertise to, solicit, or sell to
            users without their prior explicit consent;
          </li>
          <li>sell or otherwise transfer your profile;</li>
          <li>attempt to influence the play in any sporting event from which athletes are available for
            selection in ThriveFantasy contests in which you are involved or in which you have a
            direct or indirect interest.
          </li>
          <li>enter into contests, by any means including multi-accounting, for which you are
            ineligible
          </li>
          <li>(e.g., beginner contests when you are not a beginner).</li>
          <li>Violation of our rules may result in the removal of your Content from the Service,
            forfeiture of winnings, and/or the canceling of your account. You acknowledge and
            agree that ThriveFantasy may remove any User Content (as defined below) and
            terminate any ThriveFantasy account at any time for any reason (including, but not
            limited to, upon receipt of claims or allegations from third parties or authorities relating
            to such User Content). To report Terms of Use violations, please email:
            admin@ThriveFantasy.com.
          </li>
        </ul>
        <strong>6.1 User Content</strong>
        <p>You understand that all Content made available on the Service by a user (“User Content”),
          including but not limited to profile information and communications with other users, whether
          privately transmitted or made publicly available, is the sole responsibility of the person from
          which such User Content originated. This means that you, not ThriveFantasy, are entirely
          responsible for all User Content that you upload, post, share, email, transmit, or otherwise
          make available via the Service. Under no circumstances will ThriveFantasy be liable in any way
          for any User Content.
        </p>
        <p>You acknowledge that ThriveFantasy may or may not pre-screen User Content, but that
          ThriveFantasy and its designees have the right (but not the obligation) in their sole discretion to
          pre-screen, refuse, permanently delete, undelete, modify and/or move any User Content
          available via the Service. Without limiting the foregoing, ThriveFantasy and its designees shall
          have the right to remove any User Content that violates these Terms or is otherwise
          objectionable in ThriveFantasy’s sole discretion. You understand that by using the Service, you
          may be exposed to User Content that you may consider to be offensive or objectionable. You
          agree that you must evaluate, and bear all risks associated with, the use or disclosure of any
          User Content. You further acknowledge and agree that you bear the sole risk of reliance on any
          Content available on or through the Service. 
        </p>
        <p>With respect to User Content you submit or otherwise make available on or to the Service, you
          grant ThriveFantasy an irrevocable, fully sub-licensable, perpetual, world-wide, royalty-free,
          non-exclusive license to use, distribute, reproduce, modify, adapt, publish, translate, publicly
          perform and publicly display such User Content (in whole or in part), and to incorporate such
          User Content into other works, in any format or medium now known or later developed.
        </p>
        <p>You are solely responsible for your interactions with other users of the Service. ThriveFantasy
          reserves the right, but has no obligation, to monitor disputes between you and other users. 
        </p>
        <h3>7. Indemnity </h3>
        <p>You agree to release and to indemnify, defend and hold harmless ThriveFantasy and its parents,
          subsidiaries, affiliates and agencies, as well as the officers, directors, employees, shareholders
          and representatives of any of the foregoing entities, from and against any and all losses,
          liabilities, expenses, damages, costs (including attorneys’ fees and court costs) claims or actions
          of any kind whatsoever arising or resulting from your use of the Service, your violation of these
          Terms of Use, your receipt, ownership, use or misuse of any prize, and any of your acts or
          omissions that implicate publicity rights, defamation or invasion of privacy. ThriveFantasy
          reserves the right, at its own expense, to assume exclusive defense and control of any matter
          otherwise subject to indemnification by you and, in such case, you agree to cooperate with
          ThriveFantasy in the defense of such matter.
        </p>
        <h3>8. Warranty disclaimers</h3>
        <p>You expressly understand and agree that your use of the Service is at your sole risk. The Service
          (including the Service and the Content) are provided on an "AS IS" and "as available" basis,
          without warranties of any kind, either express or implied, including, without limitation, implied
          warranties of merchantability, fitness for a particular purpose or non-infringement. You
          acknowledge that ThriveFantasy has no control over, and no duty to take any action regarding:
          which users gain access to or use the Service; what effects the Content may have on you; how
          you may interpret or use the Content; or what actions you may take as a result of having been
          exposed to the Content. You release ThriveFantasy from all liability for you having acquired or
          not acquired Content through the Service. The Service may contain, or direct you to other
          websites containing information that some people may find offensive or inappropriate.
          ThriveFantasy makes no representations concerning any Content contained in or accessed
          through the Service, and ThriveFantasy will not be responsible or liable for the accuracy,
          copyright compliance, legality or decency of material contained in or accessed through the
          Service. 
        </p>
        <h3>9. Limitation on liability </h3>
        <p>YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR USE OF
          THE SITE AND SERVICE. YOU ACKNOWLEDGE AND AGREE THAT ANY INFORMATION YOU SEND 
          OR RECEIVE DURING YOUR USE OF THE SITE AND SERVICE MAY NOT BE SECURE AND MAY BE
          INTERCEPTED OR LATER ACQUIRED BY UNAUTHORIZED PARTIES. YOU ACKNOWLEDGE AND
          AGREE THAT YOUR USE OF THE SITE AND SERVICE IS AT YOUR OWN RISK. RECOGNIZING SUCH,
          YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
          LAW, NEITHER FANDUEL NOR ITS SUPPLIERS OR LICENSORS WILL BE LIABLE TO YOU FOR ANY
          DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR OTHER
          DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS,
          GOODWILL, USE, DATA OR OTHER TANGIBLE OR INTANGIBLE LOSSES OR ANY OTHER DAMAGES
          BASED ON CONTRACT, TORT, STRICT LIABILITY OR ANY OTHER THEORY (EVEN IF FANDUEL HAD
          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM THE SITE OR
          SERVICE; THE USE OR THE INABILITY TO USE THE SITE OR SERVICE; UNAUTHORIZED ACCESS TO
          OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; STATEMENTS OR CONDUCT OF ANY
          THIRD PARTY ON THE SITE OR SERVICE; ANY ACTIONS WE TAKE OR FAIL TO TAKE AS A RESULT
          OF COMMUNICATIONS YOU SEND TO US; ANY INCORRECT, ILLEGIBLE, MISDIRECTED, STOLEN,
          INVALID OR INACCURATE ENTRY INFORMATION; HUMAN ERRORS; TECHNICAL MALFUNCTIONS;
          FAILURES, INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES; OMISSIONS, INTERRUPTIONS,
          LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK, PROVIDERS, OR SOFTWARE
          (INCLUDING, BUT NOT LIMITED TO, THOSE THAT DO NOT PERMIT AN ENTRANT TO PARTICIPATE
          IN THE SERVICE); ANY INJURY OR DAMAGE TO COMPUTER EQUIPMENT; INABILITY TO FULLY
          ACCESS THE SITE OR SERVICE OR ANY OTHER WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR
          UNAUTHORIZED ACCESS TO, OR ALTERATION OF, ENTRIES, IMAGES OR OTHER CONTENT OF
          ANY KIND; DATA THAT IS PROCESSED LATE OR
        </p>
        <p>INCORRECTLY OR IS INCOMPLETE OR LOST; TYPOGRAPHICAL, PRINTING OR OTHER ERRORS, OR
          ANY COMBINATION THEREOF; OR ANY OTHER MATTER RELATING TO THE SITE OR SERVICE.
          NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, FANDUEL'S LIABILITY
          TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL
          AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO FANDUEL FOR GENERAL
          USE OF THE SITE OR SERVICE DURING THE TERM OF YOUR REGISTRATION FOR THE SITE, NOT
          INCLUDING ANY ENTRY FEES FOR CONTESTS COMPLETED PRIOR TO THE CIRCUMSTANCES
          GIVING RISE TO THE CLAIM.
        </p>
        <p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion
          of liability for incidental or consequential damages. Accordingly, some of the above limitations
          may not apply to you.
        </p>
        <h3>10. Our proprietary rights </h3>
        <p>All title, ownership and intellectual property rights in and to the Service are owned by
          Props2Fantasy.com, Inc. or its licensors. You acknowledge and agree that the Service contains
          proprietary and confidential information that is protected by applicable intellectual property
          and other laws. Except as expressly authorized by Props2Fantasy.com, Inc., you agree not to 
          copy, modify, rent, lease, loan, sell, distribute, perform, display or create derivative works
          based on the Service, in whole or in part.
        </p>
        <h3>11. Links </h3>
        <p>The Service provides, or third parties may provide, links to other World Wide Web sites,
          applications or resources. Because ThriveFantasy has no control over such sites, applications
          and resources, you acknowledge and agree that ThriveFantasy is not responsible for the
          availability of such external sites, applications or resources, and does not endorse and is not
          responsible or liable for any content, advertising, products or other materials on or available
          from such sites or resources. You further acknowledge and agree that ThriveFantasy shall not
          be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be
          caused by or in connection with use of or reliance on any such content, goods or services
          available on or through any such site or resource.
        </p>
        <h3>12. Termination and suspension </h3>
        <p>ThriveFantasy may terminate or suspend all or part of the Service and your ThriveFantasy
          account immediately, without prior notice or liability, if you breach any of the terms or
          conditions of the Terms. Upon termination of your account, your right to use the Service will
          immediately cease. If you wish to terminate your ThriveFantasy account, you may contact us at
          admin@ThriveFantasy.com
        </p>
        <p>Or</p>
        <p>ThriveFantasy</p>
        <p>261 Madison Ave. Fl. 9</p>
        <p>New York, NY 10016</p>
        <p>The following provisions of the Terms survive any termination of these Terms: CONDITIONS OF
          PARTICIPATION (except for Registration and Account Password and Security); GAME RULES
          (only Bonuses and Promotions); CONDUCT (only User Content); INDEMNITY; WARRANTY
          DISCLAIMERS; LIMITATION ON LIABILITY; OUR PROPRIETARY RIGHTS; LINKS; TERMINATION; NO
          THIRD PARTY BENEFICIARIES; BINDING ARBITRATION AND CLASS ACTION WAIVER; GENERAL
          INFORMATION. 
        </p>
        <p>If your account is subject to a suspension, you must respect the restrictions and limitations
          imposed on your account as part of the suspension, and you should communicate with
          ThriveFantasy regarding restoration of your account only via support@ThriveFantasy.com
        </p>
        <h3>13. No third party beneficiaries</h3>
        <p>You agree that, except as otherwise expressly provided in these Terms, there shall be no third
          party beneficiaries to the Terms.
        </p>
        <h3>14. Notice and procedure for making claims of copyright infringement </h3>
        <p>ThriveFantasy may, in appropriate circumstances and at its discretion, disable and/or terminate
          the accounts of users who infringe the intellectual property of others. If you believe that your
          copyright or the copyright of a person on whose behalf you are authorized to act has been
          infringed, please provide ThriveFantasy’s Copyright Agent a written Notice containing the
          following information: 
        </p>
        <ul>
          <li>an electronic or physical signature of the person authorized to act on behalf of the
            owner of the copyright or other intellectual property interest;
          </li>
          <li>a description of the copyrighted work or other intellectual property that you claim has been infringed; </li>
          <li>a description of where the material that you claim is infringing is located on the Service; your address, telephone number, and email address; </li>
          <li>a statement by you that you have a good faith belief that the disputed use is not
            authorized by the copyright owner, its agent, or the law;
          </li>
          <li>a statement by you, made under penalty of perjury, that the above information in your
            Notice is accurate and that you are the copyright or intellectual property owner or
            authorized to act on the copyright or intellectual property owner's behalf.
          </li>
          <li>ThriveFantasy’s Copyright Agent can be reached in the following ways:</li>
          <p>Mail: ThriveFantasy, </p>
          <p>261 Madison Ave. Fl. 9</p>
          <p>New York, NY 10016</p>
        </ul>
        <p>E-mail: admin@ThriveFantasy.com</p>
        <h3>15. Binding arbitration and class action waiver</h3>
        <p>PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT </p>
        <strong>15.1 Initial Dispute Resolution</strong>
        <p>Our Customer Support Department is available at admin@ThriveFantasy.com Our Customer
          Service Department is able to resolve most concerns quickly to our players’ satisfaction. The
          parties shall use their best efforts through this Customer Service process to settle any dispute, 
          claim, question, or disagreement and engage in good faith negotiations which shall be a
          condition to either party initiating a lawsuit or arbitration.
        </p>
        <strong>15.2 Binding Arbitration </strong>
        <p>If the parties do not reach an agreed upon solution within a period of 30 days from the time
          informal dispute resolution under the Initial Dispute Resolution provision, then either party
          may initiate binding arbitration as the sole means to resolve claims, subject to the terms set
          forth below. Specifically, all claims arising out of or relating to these Terms (including their
          formation, performance and breach), the parties’ relationship with each other and/or your use
          of the Service shall be finally settled by binding arbitration administered by the American
          Arbitration Association in accordance with the provisions of its Commercial Arbitration Rules
          and the supplementary procedures for consumer related disputes of the American Arbitration
          Association (the “AAA”), excluding any rules or procedures governing or permitting class
          actions.
        </p>
        <p>The arbitrator, and not any federal, state or local court or agency, shall have exclusive authority
          to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability
          or formation of these Terms, including, but not limited to any claim that all or any part of these
          Terms are void or voidable, or whether a claim is subject to arbitration. The arbitrator shall be
          empowered to grant whatever relief would be available in a court under law or in equity. The
          arbitrator’s award shall be written, and binding on the parties and may be entered as a
          judgment in any court of competent jurisdiction. 
        </p>
        <p>The Commercial Arbitration Rules governing the arbitration may be accessed at www.adr.org or by calling the AAA at +1.800.778.7879. </p>
        <strong>15.3 Location</strong>
        <p>If you are a resident of the United States, arbitration will take place at any reasonable location within the United States convenient for you.</p>
        <strong>15.4 Class Action Waiver </strong>
        <p>The parties further agree that any arbitration shall be conducted in their individual capacities
          only and not as a class action or other representative action, and the parties expressly waive
          their right to file a class action or seek relief on a class basis. YOU AND ThriveFantasy AGREE
          THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
          CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
          REPRESENTATIVE PROCEEDING. If any court or arbitrator determines that the class action
          waiver set forth in this paragraph is void or unenforceable for any reason or that an arbitration
          can proceed on a class basis, then the arbitration provision set forth above shall be deemed null
          and void in its entirety and the parties shall be deemed to have not agreed to arbitrate
          disputes.
        </p>
        <strong>15.5 Exception - Litigation of Intellectual Property and Small Claims Court Claims</strong>
        <p>Notwithstanding the parties' decision to resolve all disputes through arbitration, either party
          may bring an action in state or federal court to protect its intellectual property rights
          (“intellectual property rights” means patents, copyrights, moral rights, trademarks, and trade
          secrets, but not privacy or publicity rights). Either party may also seek relief in a small claims
          court for disputes or claims within the scope of that court’s jurisdiction.
        </p>
        <strong>15.6 30-Day Right to Opt Out </strong>
        <p>You have the right to opt-out and not be bound by the arbitration and class action waiver
          provisions set forth above by sending written notice of your decision to opt-out to the following
          address: 
        </p>
        <p>ThriveFantasy</p>
        <p>261 Madison Ave. Fl. 9</p>
        <p>New York, NY 10016</p>
        <p>The notice must be sent within 30 days of your first use of the Service, otherwise you shall be
          bound to arbitrate disputes in accordance with the terms of those paragraphs. If you opt-out of
          these arbitration provisions, ThriveFantasy also will not be bound by them. 
        </p>
        <strong>15.7 Changes to this Section </strong>
        <p>ThriveFantasy will provide 60-days’ notice of any changes to this section. Changes will become
          effective on the 60th day, and will apply prospectively only to any claims arising after the 60th
          day. 
        </p>
        <p>For any dispute not subject to arbitration you and ThriveFantasy agree to submit to the
          personal and exclusive jurisdiction of and venue in the federal and state courts located in New
          York, NY. You further agree to accept service of process by mail, and hereby waive any and all
          jurisdictional and venue defenses otherwise available. 
        </p>
        <p>The Terms and the relationship between you and ThriveFantasy shall be governed by the laws
          of the State of New York without regard to conflict of law provisions.
        </p>
        <h3>16. APPLICATION LICENSE </h3>
        <p>Subject to your compliance with these Terms, ThriveFantasy grants you a limited non-exclusive,
          non-transferable license to download and install a copy of the app on a device that you
          exclusively control and to run such copy of the app solely for your own personal use.
          ThriveFantasy reserves all rights in and to the app not expressly granted to you under these
          Terms. You will not run any version of the app on a jailbroken device.
        </p>
        <p>If you have downloaded our app, you agree to promptly download and install any new version
          that we make available through the iTunes App Store or Google Play store, as applicable. Some
          new versions may contain updated Terms. Some new versions may contain security fixes and
          service improvements, whether or not we disclose that they do; accordingly, failure to promptly
          update your version of the App may in some cases expose you to increased security risks or
          Service malfunctions. 
        </p>
        <h3>17. Responsible Gaming</h3>
        <p>ThriveFantasy contests ensures every user can play within their financial means. We are
          committed to integrity, fairness and reliability. We do everything possible to prevent gamingrelated problems. We’ve developed measures to maintain a responsible, safe and reliable place
          for online gaming.
        </p>
        <p>ThriveFantasy offers the options to self-exclude and self limit. If, at any point, you think you're
          spending too much time or money on ThrivFantasy, you have the option of taking a break
          entirely. Self-limiting allows you to still play, however, within the parameters that you set for
          yourself.
        </p>
        <p>Options to Limit Play:</p>
        <ul>
          <li>Self-Exclusion</li>
          <li>Self-Limitation</li>
        </ul>
        <p>ThriveFantasy also provides options to limit the amount of funds you can deposit into your
          account by choosing an amount over a daily, weekly and monthly period. Please email
          admin@thrivefantasy.com for further details
        </p>
        <h3>18. ADDITIONAL TERMS FOR OUR iOS APP</h3>
        <p>You acknowledge and agree that (i) these Terms are binding between you and ThriveFantasy
          only, and Apple is not a party hereto, and (ii) as between ThriveFantasy and Apple, it is
          ThriveFantasy that is responsible for the app and the content thereof. You must use the iOS app
          only on an Apple-branded product that runs iOS. Your use of the app must comply with the
          terms of use applicable to the Apple source from which you obtain it (including the Usage Rules
          set forth in the Apple App Store Terms of Service). You acknowledge that Apple has no
          obligation to furnish you with any maintenance and support services with respect to the app. </p>
        <p>You acknowledge that Apple is not responsible for addressing any claims you have or any claims
          of any third party relating to the app or your possession and use of the app, including, but not
          limited to (i) product warranty or liability claims; (ii) any claim that the app fails to conform to 
          any applicable legal or regulatory requirement; (iii) claims arising under consumer protection or
          similar legislation; or (iv) claims that the app infringes a third party’s intellectual property rights. </p>
        <p>In the event of any failure of the iOS version of the app to conform to any applicable warranty
          that has not been effectively disclaimed by these Terms, you may notify Apple, and Apple will
          refund the purchase price for the app (if any) to you; to the maximum extent permitted by
          applicable law, Apple will have no other warranty obligation whatsoever with respect to the
          app, and, as between Apple and ThriveFantasy, any other claims, losses, liabilities, damages,
          costs or expenses attributable to any failure to conform to any warranty will be ThriveFantasy’s
          responsibility, but only to the extent provided by these Terms. Please read the entire Terms, as
          other sections of these Terms limit ThriveFantasy’s liability in this regard. 
        </p>
        <p>Apple, and Apple’s subsidiaries, are third-party beneficiaries of these Terms. Upon your
          acceptance of the Terms, Apple will have the right (and will be deemed to have accepted the
          right) to enforce the Terms against you as a third party beneficiary thereof. There are no other
          third-party beneficiaries of the Terms. 
        </p>
        <h3>19. GENERAL INFORMATION</h3>
        <strong>19.1 Entire Agreement </strong>
        <p>These Terms (and any additional terms, rules and conditions of participation in particular
          contests that ThriveFantasy may post on the Service) constitute the entire agreement between
          you and ThriveFantasy with respect to the Service and supersedes any prior agreements, oral or
          written, between you and ThriveFantasy. In the event of a conflict between these Terms and
          the additional terms, rules and conditions of participation in particular contests, the latter will
          prevail over the Terms to the extent of the conflict.
        </p>
        <strong>19.2 Waiver and Severability of Terms </strong>
        <p>The failure of ThriveFantasy to exercise or enforce any right or provision of the Terms shall not
          constitute a waiver of such right or provision. If any provision of the Terms is found by an
          arbitrator or court of competent jurisdiction to be invalid, the parties nevertheless agree that
          the arbitrator or court should endeavor to give effect to the parties' intentions as reflected in
          the provision, and the other provisions of the Terms remain in full force and effect.
        </p>
        <strong>19.3 Statute of Limitations </strong>
        <p>You agree that regardless of any statute or law to the contrary, any claim or cause of action
          arising out of or related to the use of the Service or the Terms must be filed within one (1) year
          after such claim or cause of action arose or be forever barred.</p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </div>
    </div>
  )
}

export default TermsAndConditionsPage;