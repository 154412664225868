import React from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import './my-contest-event.scss';
import LoggedInHeader from '../Logged-in-header/Logged-in-header';
import MyContestTraditionalPicksLive from '../my-contest-traditional-picks-live/my-contest-traditional-picks-live';
import MyContestVersusPicksLive from '../my-contest-versus-picks-live/my-contest-versus-picks-live'
import MyContestEntrantsLive from '../my-contest-entrants-live/my-contest-entrants-live';
import $ from 'jquery';
import Moment from 'react-moment';
import { isAuthenticated } from "../isAuthenticated";
import Footer from "../footer/footer";

let prizes = [];

let requestBody = {
  contestId: 0,
  userContestId: 0
};

const requestOptions = {
  method: 'post',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
  },
  body: JSON.stringify(requestBody)
};

class MyContestEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: this.props.match.params.view,
            contestId: this.props.match.params.contestId,
            userContestId: this.props.match.params.userContestId,
            picked: 0,
            icePick: 0,
            type: '',
            maximumPoint: 0,
            contest: {},
            prizes: [],
            picks: [],
            entrantsTab: this.props.location ? (this.props.location.entrantsTab ? true : false) : false
        };
        this.fetchNewData = this.fetchNewData.bind(this);
        this.entrantsTab = this.entrantsTab.bind(this);
        this.tabSwitchEntrants = this.tabSwitchEntrants.bind(this)
        this.tabSwitchPicks = this.tabSwitchPicks.bind(this)
    }

    fetchNewData(requestOptions) {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'contests/contestDetails', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    parseContest(data['response']['contestPrizes']);
                    this.setState({ contest: data['response']['contestDetails'], prizes: prizes, type: data['response']['contestDetails']['type'] });
                    updateTime();
                    $('.loader').removeClass('show');
                } else {
                    $('.loader').removeClass('show');
                }
            });
    }

    tabSwitchEntrants(event) {
        const tab = event.target.getAttribute('rel');
        $('.tabs-head').find('button').removeClass('active');
        event.target.classList.add('active');
        $('.tabs-body').find('.tab-container').removeClass('active');
        $('.tabs-body').find('.tab-container[rel="' + tab + '"]').addClass('active');

        this.setState({
            entrantsTab: true
        })
    }

    tabSwitchPicks(event) {
        const tab = event.target.getAttribute('rel');
        $('.tabs-head').find('button').removeClass('active');
        event.target.classList.add('active');
        $('.tabs-body').find('.tab-container').removeClass('active');
        $('.tabs-body').find('.tab-container[rel="' + tab + '"]').addClass('active');

        this.setState({
            entrantsTab: false
        })
    }

    componentDidMount() {
        console.log('----')
        console.log(this.props.location)
        console.log('----')
        isAuthenticated('login', '/contest-lobby');
        requestBody['contestId'] = this.state.contestId;
        requestBody['userContestId'] = this.state.userContestId;
        requestOptions['body'] = JSON.stringify(requestBody);
        this.fetchNewData(requestOptions);
    }

  showSummary() {
    $('.event-Prize-Payouts').removeClass('open');
    $('.event-summary').toggleClass('open');
  }

  showPrize() {
    $('.event-summary').removeClass('open');
    $('.event-Prize-Payouts').toggleClass('open');
  }

    entrantsTab() {
        if (this.state.entrantsTab) {
            return true
        }
        return false
    }

    render() {
        // console.log(this.entrantsTab())
        // console.log(this.state.entrantsTab)

        const contest = this.state.contest;
        const type = this.state.type;

    return (
      <main className="my-contest-event-page">
        <LoggedInHeader />
        {contest && type ?
          <div className="inner">
            <aside>
              <Link className="back-btn" to={process.env.REACT_APP_START_URL + "my-contests/" + this.state.view} title="Back to Results">Back to Results</Link>
              <div className="event-details">
                <div className="top">
                  <img src={contest['gameImage']} alt={contest['name']} />
                  <h1>
                    {contest['name']}
                    {contest['featured'] ? <span>Featured</span> : ''}
                  </h1>
                </div>
                <div className="bottom">
                  <div className="info">
                    <h3>
                      Entry fees
                      <span>${contest['entryFee']}.00</span>
                    </h3>
                    {this.state.view === 'live' ?
                      <h3>
                        Current Ranking
                        <span>{contest['ranking']}/{contest['totalEntries']}</span>
                      </h3>
                      :
                      <h3>
                        Prize Pool
                        <span>{formatNumber(contest['guaranteedPrizeMoney'])}</span>
                      </h3>
                    }
                    {this.state.view === 'live' ?
                      <h3>
                        Live Points
                        <span>{contest['livePoints']}</span>
                      </h3>
                      :
                      <h3>
                        Winner Point
                        <span>{contest['winnersPoint']}</span>
                      </h3>
                    }
                  </div>
                  {this.state.view === 'history' ?
                    <div className="stats">
                      <p>
                        Date: <Moment utc local format="dddd, MMM Do, YYYY">{contest['startTime']}</Moment><br />
                        Start Time: <Moment utc local format="hh:mm a">{contest['startTime']}</Moment>
                      </p>
                      <p>
                        Ranking: {contest['ranking']}/{contest['totalEntries']}<br />
                        Points Made: {contest['pointsMade']}
                      </p>
                    </div>
                    : ''
                  }
                  <div className="stats">
                    {this.state.view === 'live' ?
                      <p>

                        For 1st Position: {contest['pointsAwayFromFirstPostion']} Points Needed <br />
                        For Last Win Position: {contest['pointAwayFromLastPaidPosition']} Points Needed
                      </p>
                      :
                      <p>

                        Prize Earned: {formatNumber(contest['earnedPrize'])}<br />
                        Places away from last paid position: {contest['positionAwayFromLastWinningPosition']}
                      </p>
                    }
                  </div>
                </div>
              </div>
              <div className="event-details-mobile">
                <div className="top">
                  <img src={contest['gameImage']} alt={contest['name']} />
                  <h3>
                    {contest['name']} {contest['featured'] ? <span>Featured</span> : ''}
                  </h3>
                  <h4>
                    <span>Entry fees</span>
                    {formatNumber(contest['entryFee'])}
                  </h4>
                  <h4>
                    <span>Prize Pool</span>
                    {formatNumber(contest['guaranteedPrizeMoney'])}
                  </h4>
                  <h4>
                    <span>Winner Point</span>
                    {contest['winnersPoint']}
                  </h4>
                </div>
                <div className="bottom">
                  <p>
                    Date: <span><Moment utc local format="dddd, MMM Do, YYYY">{contest['startTime']}</Moment></span>
                  </p>
                  <p>
                    Start Time: <span><Moment utc local format="hh:mm a">{contest['startTime']}</Moment></span>
                  </p>
                  <p>
                    Ranking: <span>{contest['ranking']}/{contest['totalEntries']}</span>
                  </p>
                  <p>
                    Points Made: <span>{contest['pointsMade']}</span>
                  </p>
                  <p>
                    Prize Earned: <span>{formatNumber(contest['earnedPrize'])}</span>
                  </p>
                  <p>
                    Places away from last paid position: <span>{contest['positionAwayFromLastWinningPosition']}</span>
                  </p>
                </div>
              </div>
              <div className="event-summary">
                <h2>Summary</h2>
                <p>
                  {contest['summary']}
                </p>
              </div>

                            <div className="event-Prize-Payouts">
                                <h2>Prize Payouts</h2>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Places</th>
                                            <th>Prizes</th>
                                        </tr>
                                        {this.state.prizes.length ? this.state.prizes.map((prize, index) => (
                                            <tr key={prize['prizeId']}>
                                                <td className="nowrap">{prize['text']}</td>
                                                <td>{formatNumber(prize['amount'])}{isFirst(index, this.state.contest.extraPrize)}</td>
                                            </tr>
                                        )) : <tr><td></td></tr>}
                                    </tbody>
                                </table>
                                <div className="event-information">
                                    <h2>General Information</h2>
                                    <p>
                                        {contest['generalInfo']}
                                    </p>
                                </div>
                            </div>
                            <div className="mobile-switch-info">
                                <button onClick={this.showSummary}>Summary</button>
                                <button onClick={this.showPrize}>Prize Payouts</button>
                            </div>
                        </aside>
                        <section className="event-content">
                            <div className="tabs-head">
                                {this.entrantsTab() ?
                                    <button onClick={this.tabSwitchEntrants} className="active" rel="entrants" title="Entrants">Entrants</button>
                                    :
                                    <button onClick={this.tabSwitchEntrants} rel="entrants" title="Entrants">Entrants</button>}
                                {this.entrantsTab() ?
                                    <button onClick={this.tabSwitchPicks} rel="making-picks" title="Making Picks">Making Picks</button>
                                    :
                                    <button onClick={this.tabSwitchPicks} className="active" rel="making-picks" title="Making Picks">Making Picks</button>}
                            </div>
                            <div className="tabs-body">
                                {this.entrantsTab() === true ?
                                    <MyContestEntrantsLive contest={this.state.contest} /> :
                                    this.state.type === 'TRADITIONAL' ?
                                        <MyContestTraditionalPicksLive contest={this.state.contest} />
                                        :
                                        <MyContestVersusPicksLive contest={this.state.contest} />
                                }

              </div>
            </section>

          </div>
          : ''}
        <Footer />
      </main>
    );
  }
}

function isFirst(index, extraPrize) {
  if (index === 0 && extraPrize != null) {
    return ' + ' + extraPrize
  } else {
    return null
  }
}

function parseContest(contestPrizes) {
  let lastPosition = 0;
  let start = 0;
  let end = 0;
  for (let i = 0; i < contestPrizes.length; i++) {
    start = lastPosition + 1;
    end = lastPosition + contestPrizes[i]['numEntrants'];
    lastPosition = end;
    if (start === end) {
      contestPrizes[i].text = start + numberSuffix(start) + " place";
    } else {
      contestPrizes[i].text = start + numberSuffix(start) + '-' + end + numberSuffix(end) + " place";
    }
    prizes = contestPrizes;
  }
}

function numberSuffix(n) {
  return ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th";
}

function updateTime() {
  $('.countdown').each(function () {
    const time = getTimeRemaining($(this).attr('rel'));

    $(this).find('.h').html(time.hours);
    $(this).find('.m').html(time.minutes);
    $(this).find('.s').html(time.seconds);
  });
}


function getTimeRemaining(endTime) {
  var newDate = new Date();
  let t = endTime - Date.parse(newDate.toString());
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  var hours = Math.floor((t / ((1000 * 60 * 60))));
  return {
    'total': t,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

function formatNumber(num) {
  return '$' + (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export default withRouter(MyContestEvent);
