import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import "./contest-lobby.scss";
import LoggedInHeader from "../Logged-in-header/Logged-in-header";
import $ from "jquery";
import Moment from "react-moment";
import { isAuthenticated } from "../isAuthenticated";
import Footer from "../footer/footer";
import { messages } from "../messages";
import { Mixpanel } from "../mixpanel/Mixpanel";
import lock from "../../assets/images/password.png";

import axios from "axios";

let list = [];

// Related to handlePriceFilter, used in all applicable filters on page, Jeremy
let active = true;

let requestBody = {
  contestType: "ALL",
  currentPage: 1,
  currentSize: 100,
  featured: "0",
  leagueType: "ALL",
  // added for sorting buttons
  sortByStartTime: false,
  sortByEntryFee: false,
  sortByGuaranteedPrizeMoney: false,
  sortByTotalparticipents: false,
};

const requestOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Token: window.localStorage.getItem("userData")
      ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
      : false,
  },
  body: JSON.stringify(requestBody),
};

class ContestLobby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: list,
      entryPasswordContestId: "",
      call: false,
      fantistics: null,
      filteredList: list,
    };
    $(".loader").addClass("show");
    this.handlePriceFilter = this.handlePriceFilter.bind(this);
    this.fetchNewData = this.fetchNewData.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleSportsFilter = this.handleSportsFilter.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.showLockedContestPasswordBox =
      this.showLockedContestPasswordBox.bind(this);
    this.closeEntryPasswordModal = this.closeEntryPasswordModal.bind(this);
    this.submitEntryPassword = this.submitEntryPassword.bind(this);
  }

  fetchNewData(requestOptions) {
    $(".loader").addClass("show");
    fetch(process.env.REACT_APP_SERVER_URL + "contests/showContests", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          if (data["currentPage"] > 1) {
            for (let i = 0; i < data["response"]["data"].length; i++) {
              list.push(data["response"]["data"][i]);
            }
          } else {
            list = data["response"]["data"];
          }
          this.setState({ list: list, call: true, filteredList: list });
          updateTime();
          if (list.length >= data["currentSize"]) {
            $(".load-more").addClass("show");
          }
          $(".loader").removeClass("show");
        } else {
          $(".loader").removeClass("show");
        }
      });
  }

  componentDidMount() {
    isAuthenticated("login", "/contest-lobby");
    this.fetchNewData(requestOptions);

    $(window).scrollTop(0);

    const user = JSON.parse(window.localStorage.getItem("userData"))["userDto"];

    Mixpanel.identify(user["userid"]);
    Mixpanel.track("web:contest_lobby_opened");
    Mixpanel.people.set({
      $first_name: user["userName"],
      $email: user["email"],
    });


    $(".mobile-select-sport").on("click", ".selected", function () {
      $(".mobile-select-sport").find(".selection").toggleClass("open");
    });

    window.setInterval(function () {
      updateTime();
    }, 1000);
  }

  componentWillUnmount() {
    // window.location.reload(this.handleSportsFilter);
  }

  componentDidUpdate() {
    updateTime();
  }

  handlePriceFilter(lower, upper, event) {
    // let active = true needs to exist locally as well as globally
    // Locally so that every click reinitializes active and goes through process
    // Globally so that it can be accessed elsewhere - Jeremy
    let active = true;
    if (event.target.classList[0] === "active") {
      $(".entryFilters").find("button").removeClass("active");
      $(".entryFilters").find(".all").addClass("active");
      active = !active;
    } else {
      $(".entryFilters").find("button").removeClass("active");
      $(".entryFilters").find("button").add("active");
      event.target.classList.add("active");
    }

    let filter = active
      ? this.state.filteredList.filter(
          ({ entryFee }) =>
            entryFee >= lower && (upper ? entryFee <= upper : true)
        )
      : this.state.filteredList;
    const sortedEntries = filter.sort(
      (a, b) => a.featured - b.featured && a.entryFee - b.entryFee
    );
    this.setState((state) => ({ list: sortedEntries }));
  }

  handleFilter(event) {
    const ct = event.target.getAttribute("rel");
    requestBody["contestType"] = ct;
    requestOptions["body"] = JSON.stringify(requestBody);
    // requestBody['contestType'] = event.target.getAttribute('rel');
    // requestOptions['body'] = JSON.stringify(requestBody);
    $(".filters").find("button").removeClass("active");
    event.target.classList.add("active");
    Mixpanel.track("web:user_filtered_contest_lobby_contest_type", {
      "Contest Type": ct,
    });

    // Related to handlePriceFilter, used in all applicable filters on page, Jeremy
    $(".entryFilters").find("button").removeClass("active");
    $(".entryFilters").find(".all").addClass("active");

    this.fetchNewData(requestOptions);
  }

  handleSort(event) {
    // dict connecting rel values of sort buttons (keys) to keys in requestBody (values)
    const relValToRequestBodyKey = {
      date: "sortByStartTime",
      "entry-fee": "sortByEntryFee",
      "prize-pool": "sortByGuaranteedPrizeMoney",
      "number-of-entries": "sortByTotalparticipents",
    };

    // Related to handlePriceFilter, used in all applicable filters on page, Jeremy
    $(".entryFilters").find("button").removeClass("active");
    $(".entryFilters").find(".all").addClass("active");

    // set all sort values in requestBody to false
    // this, along with code later in the function, will ensure only one sorter is active at a time
    Object.values(relValToRequestBodyKey).forEach(
      (reqKey) => (requestBody[reqKey] = false)
    );

    // grab target button, if active, deactivate css, if inactive, activate css
    const target = event.target;
    if (target.classList.value.includes("active")) {
      // user turning off an already active sorter
      $(".sorters").find("button").removeClass("active");
    } else {
      $(".sorters").find("button").removeClass("active");
      target.classList.add("active");
      requestBody[relValToRequestBodyKey[target.getAttribute("rel")]] = true;
    }
    // set correct value in requestBody to true, letting backend know we want to sort contests by that term
    requestOptions["body"] = JSON.stringify(requestBody);

    //Mixpanel Tracking - naglis :)
    Mixpanel.track("web:user_sorted_contest_lobby", {
      "Sort By": target.getAttribute("rel"),
    });

    // fetch new contests with new requestBody
    this.fetchNewData(requestOptions);
  }

  handleSportsFilter(event) {
    const lt = event.target.getAttribute("rel");
    // requestBody['leagueType'] = event.target.getAttribute('rel');
    // requestOptions['body'] = JSON.stringify(requestBody);

    requestBody["leagueType"] = lt;
    requestOptions["body"] = JSON.stringify(requestBody);

    // Related to handlePriceFilter, used in all applicable filters on page, Jeremy
    $(".entryFilters").find("button").removeClass("active");
    $(".entryFilters").find(".all").addClass("active");

    $(".sport-filter").find("li").removeClass("active");
    event.target.classList.add("active");
    $(".selection")
      .removeClass("open")
      .prev(".selected")
      .html(event.target.innerText)
      .removeAttr("class")
      .addClass("selected " + event.target.getAttribute("data-class"));

    Mixpanel.track("web:user_filtered_contest_lobby_sport", {
      "League Type": lt,
    });

    this.fetchNewData(requestOptions);
  }

  loadMore() {
    requestBody["currentPage"] = requestBody["currentPage"] + 1;
    requestOptions["body"] = JSON.stringify(requestBody);
    this.fetchNewData(requestOptions);
  }

  showLockedContestPasswordBox(contestId) {
    this.setState({ entryPasswordContestId: contestId });
    $(".enter-password-popup").addClass("show");
  }

  PasswordBoxTagHelper(contestId) {
    Mixpanel.track("web:user_clicked_enter_locked_contest", {
      "Contest Id": contestId,
    });

    this.showLockedContestPasswordBox(contestId);
  }

  closeEntryPasswordModal() {
    this.setState({ entryPasswordContestId: "" });
    $(".enter-password-popup").removeClass("show");
  }

  submitEntryPassword() {
    const password = $(".password").val();

    if (password === "") {
      messages("error", "Valid password Required");
      return;
    }

    const entryPasswordBody = {
      contestId: this.state.entryPasswordContestId,
      passwordEntered: password,
    };

    const getEntryPasswordOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Token: window.localStorage.getItem("userData")
          ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
          : false,
      },
      body: JSON.stringify(entryPasswordBody),
    };

    fetch(
      process.env.REACT_APP_SERVER_URL +
        "contests/verifyLockedAdminContestPassword",
      getEntryPasswordOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["response"]) {
          this.props.history.push(
            process.env.REACT_APP_START_URL +
              "contest-lobby/" +
              this.state.entryPasswordContestId
          );
          this.closeEntryPasswordModal();
        } else {
          messages("error", "Password Incorrect");
        }
        $(".loader").removeClass("show");
      });
  }

  render() {
    return (
      <main className="contest-lobby-page">
        <LoggedInHeader />
        <div className="inner">
          <nav className="sorters">
            <button onClick={this.handleSort} rel="date">
              Date
            </button>
            <button onClick={this.handleSort} rel="entry-fee">
              Entry Fee
            </button>
            <button onClick={this.handleSort} rel="prize-pool">
              Prize Pool
            </button>
            <button onClick={this.handleSort} rel="number-of-entries">
              Number of Entries
            </button>
          </nav>
          <aside>
            <h2>Select Sport / Esport</h2>
            <ul className="sport-filter">
              <li
                onClick={this.handleSportsFilter}
                rel="ALL"
                className="active"
              >
                All Sports / Esports
              </li>
              <li
                onClick={this.handleSportsFilter}
                rel="NFL"
                className="sports football"
              >
                NFL
              </li>
              <li
                onClick={this.handleSportsFilter}
                rel="NBA"
                className="sports basketball"
              >
                NBA
              </li>
              <li
                onClick={this.handleSportsFilter}
                rel="MLB"
                className="sports baseball"
              >
                MLB
              </li>
              <li
                onClick={this.handleSportsFilter}
                rel="SOCCER"
                className="sports soccer"
              >
                Soccer
              </li>
              <li
                onClick={this.handleSportsFilter}
                rel="GOLF"
                className="sports golf"
              >
                Golf
              </li>
              {/*<li
                onClick={this.handleSportsFilter}
                rel='AUDL'
                className='sports deck'
              >
                AUDL
              </li>*/}
              <li
                onClick={this.handleSportsFilter}
                rel="HOCKEY"
                className="sports hockey"
              >
                Hockey
              </li>
              <li
                  onClick={this.handleSportsFilter}
                  rel="UFC"
                  className="sports ufc"
              >
                UFC
              </li>
              <li
                  onClick={this.handleSportsFilter}
                  rel="CRICKET"
                  className="sports cricket"
              >
                Cricket
              </li>
              <li
                onClick={this.handleSportsFilter}
                rel="CSGO2"
                className="sports online csgo"
              >
                CSGO
              </li>
              <li
                onClick={this.handleSportsFilter}
                rel="LOL"
                className="sports online lol"
              >
                LoL
              </li>
              {/*<li
                onClick={this.handleSportsFilter}
                rel='OW'
                className='sports online overwatch'
              >
                Overwatch
              </li>*/}
              {/* <p></p> */}
              <li
                onClick={this.handleSportsFilter}
                rel="DOTA2"
                className="sports online dota"
              >
                Dota 2
              </li>
              <li
                onClick={this.handleSportsFilter}
                rel="VAL"
                className="sports online val"
              >
                VALORANT
              </li>
              <li
                onClick={this.handleSportsFilter}
                rel="LOTTERY"
                className="sports lottery"
              >
                Lottery
              </li>
            </ul>
          </aside>
          <section className="upcoming-events">
            <nav className="filters">
              <span>Filter By Contest</span>
              <button rel="ALL" className="active" onClick={this.handleFilter}>
                All
              </button>
              <button rel="TRADITIONAL" onClick={this.handleFilter}>
                Traditional
              </button>
              <button rel="VERSUS" onClick={this.handleFilter}>
                Versus
              </button>
            </nav>
            <nav className="entryFilters">
              <span>Filter By Entry Fee</span>
              <button
                id="entryButton"
                rel="All"
                className="all active"
                onClick={(event) => this.handlePriceFilter(0, Infinity, event)}
              >
                All
              </button>
              <button
                id="entryButton"
                className=""
                onClick={(event) => this.handlePriceFilter(0, 5.0, event)}
              >
                $1-$5
              </button>
              <button
                id="entryButton"
                className=""
                onClick={(event) => this.handlePriceFilter(5.01, 10, event)}
              >
                $6-$10
              </button>
              <button
                id="entryButton"
                className=""
                onClick={(event) => this.handlePriceFilter(10.01, 25, event)}
              >
                $11-$25
              </button>
              <button
                id="entryButton"
                className=""
                onClick={(event) => this.handlePriceFilter(25.01, 50, event)}
              >
                $26-$50
              </button>
              <button
                id="entryButton"
                className=""
                onClick={(event) => this.handlePriceFilter(50.01, 100.0, event)}
              >
                $51-$100
              </button>
              <button
                id="entryButton"
                className=""
                onClick={(event) =>
                  this.handlePriceFilter(100.01, Infinity, event)
                }
              >
                $100+
              </button>
            </nav>
            <div className="mobile-select-sport">
              <h2>Choose Sport / Esport</h2>
              <div className="selected">All Sports / Esports</div>
              <div className="selection">
                <ul className="sport-filter">
                  <li
                    onClick={this.handleSportsFilter}
                    rel="ALL"
                    data-class=""
                    className="active"
                  >
                    All Sports / Esports
                  </li>
                  <li
                    onClick={this.handleSportsFilter}
                    rel="NFL"
                    data-class="football"
                    className="sports football"
                  >
                    NFL
                  </li>
                  <li
                    onClick={this.handleSportsFilter}
                    rel="NBA"
                    data-class="basketball"
                    className="sports basketball"
                  >
                    NBA
                  </li>
                  <li
                    onClick={this.handleSportsFilter}
                    rel="MLB"
                    data-class="baseball"
                    className="sports baseball"
                  >
                    MLB
                  </li>
                  <li
                    onClick={this.handleSportsFilter}
                    rel="SOCCER"
                    data-class="soccer"
                    className="sports soccer"
                  >
                    Soccer
                  </li>
                  <li
                    onClick={this.handleSportsFilter}
                    rel="GOLF"
                    data-class="golf"
                    className="sports golf"
                  >
                    Golf
                  </li>
                  {/*<li
                    onClick={this.handleSportsFilter}
                    rel='AUDL'
                    data-class='deck'
                    className='sports deck'
                  >
                    AUDL
                  </li>*/}
                  <li
                    onClick={this.handleSportsFilter}
                    rel="UFC"
                    data-class="deck"
                    className="sports ufc"
                  >
                    UFC
                  </li>
                  <li
                    onClick={this.handleSportsFilter}
                    rel="HOCKEY"
                    data-class="hockey"
                    className="sports hockey"
                  >
                    Hockey
                  </li>
                  <li
                      onClick={this.handleSportsFilter}
                      rel="CRICKET"
                      data-class="deck"
                      className="sports cricket"
                  >
                    Cricket
                  </li>
                  <li
                    onClick={this.handleSportsFilter}
                    rel="CSGO2"
                    data-class="csgo"
                    className="sports online csgo"
                  >
                    CSGO
                  </li>
                  <li
                    onClick={this.handleSportsFilter}
                    rel="LOL"
                    data-class="lol"
                    className="sports online lol"
                  >
                    LoL
                  </li>
                  {/*<li
                    onClick={this.handleSportsFilter}
                    rel='OW'
                    data-class='overwatch'
                    className='sports online overwatch'
                  >
                    Overwatch
                  </li>*/}
                  <li
                    onClick={this.handleSportsFilter}
                    rel="DOTA2"
                    data-class="dota"
                    className="sports online dota"
                  >
                    Dota 2
                  </li>
                  <li
                    onClick={this.handleSportsFilter}
                    rel="VAL"
                    data-class="val"
                    className="sports online val"
                  >
                    VALORANT
                  </li>
                  <li
                    onClick={this.handleSportsFilter}
                    rel="PUBG"
                    className="sports pubg"
                  >
                  </li>
                  <li
                    onClick={this.handleSportsFilter}
                    rel="LOTTERY"
                    data-class="lottery"
                    className="sports lottery"
                  >
                    Lottery
                  </li>
                </ul>
              </div>
            </div>
            <h2>Upcoming Events </h2>
            {this.state.call ? (
              <div className="upcoming-events-listing">
                <ul>
                  {this.state.list.length ? (
                    this.state.list.map((item) => (
                      <li key={item["contestId"]}>
                        <div className="desktop">
                          <div className="top">
                            <img src={item["gameImage"]} alt={item["name"]} />
                            <div className="headings">
                              <h3>
                                {item["name"]}{" "}
                                {item["featured"] ? <span>Featured</span> : ""}
                                {item["totalEntries"] ===
                                item["maxEntriesAllowed"] ? (
                                  <span className="contest-filled">
                                    Contest Filled
                                  </span>
                                ) : (
                                  ""
                                )}
                              </h3>
                              <h4>
                                Entry Fees: {formatNumber(item["entryFee"])}
                              </h4>
                              {item["multiEntryAllowed"] ? (
                                <div className="multy-entries">
                                  <span>
                                    M - {item["maxEntriesPerUser"]} Max
                                  </span>
                                  <span>
                                    {item["maxEntriesPerUser"] -
                                      item["remainingEntries"]}{" "}
                                    Left
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="info">
                              <h3>
                                {item["passwordEntryAllowed"] ? (
                                  <img src={lock}></img>
                                ) : (
                                  <img style={{ width: 0, height: 0 }}></img>
                                )}
                              </h3>
                              <h3>
                                <span>Prize Pool</span>
                                {formatNumber(item["guaranteedPrizeMoney"])}
                              </h3>
                              <h3>
                                <span>Entries</span>
                                {item["totalEntries"]}/
                                {item["maxEntriesAllowed"]}
                              </h3>
                            </div>
                          </div>
                          <div className="bottom">
                            <div className="left">
                              <p>
                                Date:{" "}
                                <Moment utc local format="dddd, MMM Do, YYYY">
                                  {item["startTime"]}
                                </Moment>
                                <br />
                                Start Time:{" "}
                                <Moment utc local format="hh:mm a">
                                  {item["startTime"]}
                                </Moment>
                              </p>
                            </div>
                            <div className="right">
                              <div
                                className="countdown"
                                rel={
                                  new Date().getTime() +
                                  new Date(item["timeToStart"]).getTime()
                                }
                              >
                                <div>
                                  <span className="h">00</span>
                                  Hours
                                </div>
                                <div>
                                  <span className="m">00</span>
                                  Mins
                                </div>
                                <div>
                                  <span className="s">00</span>
                                  Sec
                                </div>
                              </div>
                              {item["passwordEntryAllowed"] ? (
                                <button
                                  title="Enter Contest"
                                  onClick={() =>
                                    this.PasswordBoxTagHelper(item["contestId"])
                                  }
                                >
                                  Enter Contest
                                </button>
                              ) : (
                                <Link
                                  to={
                                    process.env.REACT_APP_START_URL +
                                    "contest-lobby/" +
                                    item["contestId"]
                                  }
                                  title="Enter Contest"
                                  onClick={() =>
                                    Mixpanel.track(
                                      "web:user_clicked_enter_contest",
                                      {
                                        "Contest Id": item["contestId"],
                                      }
                                    )
                                  }
                                >
                                  Enter Contest
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mobile">
                          <div className="top">
                            <img src={item["gameImage"]} alt={item["name"]} />
                            <div
                              className="countdown"
                              rel={
                                new Date().getTime() +
                                new Date(item["timeToStart"]).getTime()
                              }
                            >
                              <div>
                                <span className="h">00</span>
                                Hours
                              </div>
                              <div>
                                <span className="m">00</span>
                                Mins
                              </div>
                              <div>
                                <span className="s">00</span>
                                Sec
                              </div>
                            </div>
                            <h3>
                              {item["name"]}{" "}
                              {item["featured"] ? <span>Featured</span> : ""}
                              {item["totalEntries"] ===
                              item["maxEntriesAllowed"] ? (
                                <span className="contest-filled">
                                  Contest Filled
                                </span>
                              ) : (
                                ""
                              )}
                            </h3>
                            {item["multiEntryAllowed"] ? (
                              <div className="multy-entries">
                                <span>M - {item["maxEntriesPerUser"]} Max</span>
                                <span>
                                  {item["maxEntriesPerUser"] -
                                    item["remainingEntries"]}{" "}
                                  Left
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            <h4>
                              <span>Entry Fees</span>
                              {formatNumber(item["entryFee"])}
                            </h4>
                            <h4>
                              <span>Prize Pool</span>
                              {formatNumber(item["guaranteedPrizeMoney"])}
                              {item["passwordEntryAllowed"] ? (
                                <img
                                  src={lock}
                                  style={{
                                    position: "absolute",
                                    marginLeft: 77,
                                    width: 15,
                                    height: 15,
                                  }}
                                ></img>
                              ) : (
                                ""
                              )}
                            </h4>
                          </div>
                          <div className="bottom">
                            <p>
                              Date:{" "}
                              <span>
                                <Moment utc local format="dddd, MMM Do, YYYY">
                                  {item["startTime"]}
                                </Moment>
                              </span>
                            </p>
                            <p>
                              Start Time:{" "}
                              <span>
                                <Moment utc local format="hh:mm a">
                                  {item["startTime"]}
                                </Moment>
                              </span>
                            </p>
                            <p>
                              Entries:{" "}
                              <span>
                                {item["totalEntries"]}/
                                {item["maxEntriesAllowed"]}
                              </span>
                            </p>
                            {item["passwordEntryAllowed"] ? (
                              <button
                                title="Enter Contest"
                                onClick={() =>
                                  this.showLockedContestPasswordBox(
                                    item["contestId"]
                                  )
                                }
                              >
                                Enter Contest
                              </button>
                            ) : (
                              <Link
                                to={
                                  process.env.REACT_APP_START_URL +
                                  "contest-lobby/" +
                                  item["contestId"]
                                }
                                title="Enter Contest"
                              >
                                Enter Contest
                              </Link>
                            )}
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="no-results">
                      <div></div>
                      <h3>No Events Found</h3>
                      {/* <span>Contests Usually Posted at 10 AM EST</span> */}
                    </li>
                  )}
                </ul>
                <button onClick={this.loadMore} className="load-more">
                  Load More
                </button>
              </div>
            ) : (
              ""
            )}
          </section>
        </div>
        <div className="enter-password-popup popup">
          <div className="inner">
            <button
              onClick={this.closeEntryPasswordModal}
              className="close-enter-password-modal"
              title="Close"
            ></button>
            <h2>Enter Password</h2>
            <div>
              <input className="password" type="text" placeholder="Password" />
              <button onClick={this.submitEntryPassword} title="Submit">
                Submit
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    );
  }
}

function updateTime() {
  $(".countdown").each(function () {
    const time = getTimeRemaining($(this).attr("rel"));

    $(this).find(".h").html(time.hours);
    $(this).find(".m").html(time.minutes);
    $(this).find(".s").html(time.seconds);
  });
}

function getTimeRemaining(endTime) {
  var newDate = new Date();
  let t = endTime - Date.parse(newDate.toString());

  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  var hours = Math.floor(t / (1000 * 60 * 60));
  return {
    total: t,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}

function formatNumber(num) {
  return "$" + (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default withRouter(ContestLobby);
