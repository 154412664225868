import React from "react";
import { Link } from "react-router-dom";
import { Mixpanel } from "../../../mixpanel/Mixpanel";
import "./template-a.scss";
// import '../../promo-landing.scss';

import playOnline from "../../../../assets/images/online.png";
import appleStore from "../../../../assets/images/apple store.png";
import googlePlay from "../../../../assets/images/google play.png";
import howToPlay1 from "../../../../assets/images/svg/1.png";
import howToPlay2 from "../../../../assets/images/svg/2.png";
import howToPlay3 from "../../../../assets/images/svg/3.png";
import fjlogo from "../../../../assets/images/fjlogo.png";
import thriveLogo from "../../../../assets/images/logo.png";

class TemplateALanding extends React.Component {
  lpImage = null;
  lpImageLink = null;

  constructor(props) {
    super(props);

    this.state = {};
    this.newTabRedirect = this.newTabRedirect.bind(this);
  }

  componentDidMount() {
    Mixpanel.track("web:landing_page_opened", {
      Type: "A",
      Name: this.props.lp.name,
    });
  }

  newTabRedirect() {
    if (
      window.localStorage.getItem("playnowRedirectLink") !== null &&
      window.localStorage.getItem("playnowRedirectLink") !== "null" &&
      window.localStorage.getItem("playnowRedirectLink") !== ""
    ) {
      var popup_window = window.open(
        "https://www." + window.localStorage.getItem("playnowRedirectLink"),
        "_blank"
      );
      try {
        popup_window.focus();
      } catch (e) {
        alert(
          "We noticed you have a pop-up blocker enabled. Please add ThriveFantasy to your exception list so we can redirect you."
        );
      }
    }
  }

  render() {
    this.lpImage = this.props.lp.image;
    this.lpImageLink =
      "https://lp-partner-logos.s3.amazonaws.com/" + this.lpImage;
    return (
      <div>
        <section className="landing-section-1-promo">
          <div className="promo-left">
            <div className="templateA-content">
              {this.props.lp.titleBox !== null &&
              this.props.lp.titleBox !== "" ? (
                <h1>{this.props.lp.titleBox}</h1>
              ) : (
                <h1>
                  Daily Fantasy Sports And <br /> Esports For Player Props
                </h1>
              )}
              {this.props.lp.textbox1 !== null &&
              this.props.lp.textbox1 !== "" ? (
                <div className="templateA-banner-cta">
                  <p>{this.props.lp.textbox1}</p>
                </div>
              ) : (
                ""
              )}
              <div className="templateA-banner-instructions">
                {this.props.lp.textbox2 !== null &&
                this.props.lp.textbox2 !== "" ? (
                  <p className="how-to-claim">{this.props.lp.textbox2}</p>
                ) : (
                  ""
                )}
                {this.props.lp.textbox3 !== null &&
                this.props.lp.textbox3 !== "" ? (
                  <div className="instruction-block">
                    <p>1. {this.props.lp.textbox3}</p>
                  </div>
                ) : (
                  ""
                )}
                {this.props.lp.textbox4 !== null &&
                this.props.lp.textbox4 !== "" ? (
                  <div className="templateA-second-instruction">
                    <div className="instruction-block">
                      <p>2. {this.props.lp.textbox4}</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.props.lp.textbox5 !== null &&
                this.props.lp.textbox5 !== "" ? (
                  <div className="instruction-block">
                    <p>3. {this.props.lp.textbox5}</p>
                  </div>
                ) : (
                  ""
                )}
                {this.props.lp.textbox6 !== null &&
                this.props.lp.textbox6 !== "" ? (
                  <div className="instruction-block">
                    <p>4. {this.props.lp.textbox6}</p>
                  </div>
                ) : (
                  ""
                )}
                {this.props.lp.textbox7 !== null &&
                this.props.lp.textbox7 !== "" ? (
                  <div className="instruction-block">
                    <p>5. {this.props.lp.textbox7}</p>
                  </div>
                ) : (
                  ""
                )}
                {this.props.lp.textbox8 !== null &&
                this.props.lp.textbox8 !== "" ? (
                  <div className="instruction-block">
                    <p>6. {this.props.lp.textbox8}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {this.props.lp.playNowColor !== null &&
            this.props.lp.playNowColor !== "" ? (
              <Link
                style={{ backgroundColor: this.props.lp.playNowColor }}
                to={process.env.REACT_APP_START_URL + "sign-up"}
                title="Play Online"
                redirect={this.props.redirectLink}
                onClick={this.newTabRedirect}
              >
                {this.props.lp.playNowText !== null &&
                this.props.lp.playNowText !== "" ? (
                  <span>{this.props.lp.playNowText}</span>
                ) : (
                  <span>PLAY NOW</span>
                )}
              </Link>
            ) : (
              <Link
                to={process.env.REACT_APP_START_URL + "sign-up"}
                title="Play Online"
                redirect={this.props.redirectLink}
                onClick={this.newTabRedirect}
              >
                {this.props.lp.playNowText !== null &&
                this.props.lp.playNowText !== "" ? (
                  <span>{this.props.lp.playNowText}</span>
                ) : (
                  <span>PLAY NOW</span>
                )}
              </Link>
            )}
            {this.props.lp.disclaimerText !== null &&
            this.props.lp.disclaimerText !== undefined &&
            this.props.lp.disclaimerText !== "" ? (
              <p id="templateA-warning">{this.props.lp.disclaimerText}</p>
            ) : (
              ""
            )}
          </div>
          <div className="promo-right">
            <div className="thrive-templateA">
              <div
                className="thrive-templateA-logo"
                style={{ backgroundImage: "url(" + this.lpImageLink + ")" }}
              ></div>
            </div>
          </div>

          <div>
            <div
              className="lp-vert-logosA"
              style={{ backgroundImage: "url(" + this.lpImageLink + ")" }}
            ></div>
          </div>
        </section>
        <section className="landing-section-2">
          <div className="inner">
            <h2 id="how-to-play">How To Play!</h2>
            <iframe
              width="560"
              height="560"
              src="https://www.youtube.com/embed/_e0CToimul4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <ul>
              <li>
                <div>
                  <img src={howToPlay1} alt="Choose a Contest" />
                </div>
                <h3>Choose a Contest!</h3>
                <p>
                  We Offer NFL, NBA, MLB, PGA, Cricket and Esports (CS:GO, Dota,
                  LoL)
                </p>
              </li>
              <li>
                <div>
                  <img src={howToPlay2} alt="Pick Your Side" />
                </div>
                <h3>Pick Your Props!</h3>
                <p>Make A Lineup In Minutes.</p>
              </li>
              <li>
                <div>
                  <img src={howToPlay3} alt="Get Your Points" />
                </div>
                <h3>Win Cash Prizes!</h3>
                <p>Watch Your Team and Rack Up Points!</p>
              </li>
            </ul>
            <p>
              By focusing solely on top-tier athletes while streamlining the
              drafting process, we've created a unique DFS experience that gives
              the casual sports and Esports fan an opportunity to compete and
              succeed at a high level.
            </p>
          </div>
        </section>
        <section className="landing-section-3">
          <div className="inner">
            <h2>#PropUp</h2>
            <p>
              Our app is available on any device! Download now to get started!
            </p>
            <div className="btns">
              <ul>
                <li>
                  <Link
                    to={process.env.REACT_APP_START_URL + "sign-up"}
                    title="Play Online"
                  >
                    <img src={playOnline} alt="Online" />
                    <span>Play Online</span>
                  </Link>
                </li>
                <li>
                  <a
                    href="https://apps.apple.com/us/app/thrivefantasy/id1240062484?ls=1"
                    title="Apple Store"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appleStore} alt="Apple Store" />
                    <span>
                      <small>Download on</small>
                      App Store
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.appster.p2f&hl=en_US"
                    title="Google Play"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={googlePlay} alt="Google Play" />
                    <span>
                      <small>Download on</small>
                      Google Play
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default TemplateALanding;
