import React, { useEffect } from "react";
import { Route, useLocation, withRouter } from "react-router-dom";
import Fader from "react-fader";
import Switch from "react-router-transition-switch";
import Home from "../components/home/home";
import Login from "../components/login/login";
import ForgotPassword from "../components/forgot-password/forgot-password";
import ResetPassword from "../components/reset-password/reset-password";
import SignUp from "../components/sign-up/sign-up";
import ContestLobby from "../components/contest-lobby/contest-lobby";
import ContestEvent from "../components/contest-event/contest-event";
import MyContestsLive from "./my-contests-live/my-contests-live";
import MyContestsHistory from "./my-contests-history/my-contests-history";
import MyContestsUpcoming from "./my-contests-upcoming/my-contests-upcoming";
import PropsLobby from "./props-lobby/props-lobby";
import MyPropsHistory from "./my-props-history/my-props-history";
import MyPropsLive from "./my-props-live/my-props-live";
import MyPropsUpcoming from "./my-props-upcoming/my-props-upcoming";
import MyContestEvent from "./my-contest-event/my-contest-event";
import MyContestTraditionalHeadToHead from "./my-contest-traditional-head-to-head/my-contest-traditional-head-to-head";
import MyContestVersusHeadToHead from "./my-contest-versus-head-to-head/my-contest-versus-head-to-head";
import Notifications from "./notifications/notifications";
import InfoPage from "./info-pages/info-page";
import Wallet from "./wallet/wallet";
import ReactGA from "react-ga";
import Settings from "./settings/settings";
import PromoLanding from "./promo_landing/promo-landing.js";
import ColinLanding from "./promo_landing/promo_pages/colin/colin-landing";
import ColinSignUp from "./colin-sign-up/colin-sign-up";
import PokerGoSweepstakes from "./promo_landing/promo_pages/pokerGo/pokergo-sweepstakes";
// import Affiliates from "./affiliates-page/affiliates";
// import ReferralSignUp from "./referral/referral-sign-up";

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
}

const Main = () => {
  usePageViews();
  return (
    <Switch component={Fader}>
      <Route exact path={process.env.REACT_APP_START_URL + "login"}>
        <Login />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "forgot-password"}>
        <ForgotPassword />
      </Route>
      {/* <Route exact path={process.env.REACT_APP_START_URL + "ResetPassword/:"}>
        <ResetPassword />
      </Route> */}
      <Route exact path={process.env.REACT_APP_START_URL + "reset-password/:resetPasswordToken"}>
        <ResetPassword />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "sign-up"}>
        <SignUp />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "colin-sign-up"}>
        <ColinSignUp />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "contest-lobby"}>
        <ContestLobby />
      </Route>
      <Route
        exact
        path={process.env.REACT_APP_START_URL + "contest-lobby/:contestId"}
      >
        <ContestEvent />
      </Route>
      <Route
        exact
        path={
          process.env.REACT_APP_START_URL +
          "contest-lobby/:contestId/:userContestId"
        }
      >
        <ContestEvent />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "my-contests/live"}>
        <MyContestsLive />
      </Route>
      <Route
        exact
        path={process.env.REACT_APP_START_URL + "my-contests/history"}
      >
        <MyContestsHistory />
      </Route>
      <Route
        exact
        path={
          process.env.REACT_APP_START_URL +
          "my-contests/upcoming/:view/:contestId/:userContestId"
        }
      >
        <ContestEvent />
      </Route>
      <Route
        exact
        path={
          process.env.REACT_APP_START_URL +
          "my-contests/:view/:contestId/:userContestId"
        }
      >
        <MyContestEvent />
      </Route>
      <Route
        exact
        path={
          process.env.REACT_APP_START_URL +
          "my-contests/:view/traditional/:contestId/:userContestId/head-to-head/:opponentsId/:opponentUserContestId"
        }
      >
        <MyContestTraditionalHeadToHead />
      </Route>
      <Route
        exact
        path={
          process.env.REACT_APP_START_URL +
          "my-contests/:view/versus/:contestId/:userContestId/head-to-head/:opponentsId/:opponentUserContestId"
        }
      >
        <MyContestVersusHeadToHead />
      </Route>
      {/*<Route exact path={process.env.REACT_APP_START_URL + "referral-sign-up"}>
        <ReferralSignUp />
      </Route>*/}
      <Route
        exact
        path={process.env.REACT_APP_START_URL + "my-contests/upcoming"}
      >
        <MyContestsUpcoming />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "props-lobby"}>
        <PropsLobby />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "my-props/live"}>
        <MyPropsLive />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "my-props/history"}>
        <MyPropsHistory />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "my-props/upcoming"}>
        <MyPropsUpcoming />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "notifications"}>
        <Notifications />
      </Route>
      <Route
        exact
        path={process.env.REACT_APP_START_URL + "completed-registration/wallet"}
      >
        <Wallet />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "wallet"}>
        <Wallet />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "settings"}>
        <Settings />
      </Route>
      <Route
        path={process.env.REACT_APP_START_URL + ":name(envy|fj|colin|promo)"}
      >
        <PromoLanding />
      </Route>
      <Route
        path={
          process.env.REACT_APP_START_URL +
          ":infopage(privacy-policy|terms-and-conditions|how-to-play|frequently-asked-questions)"
        }
      >
        <InfoPage />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL + "sweepstakes"}>
        <PokerGoSweepstakes />
      </Route>
      {/* <Route exact path={process.env.REACT_APP_START_URL + "affiliates"}>
      <Affiliates />
      </Route>*/}
      <Route exact path={process.env.REACT_APP_START_URL + ":sport"}>
        <Home />
      </Route>
      <Route exact path={process.env.REACT_APP_START_URL}>
        <Home />
      </Route>
      <Route>
        <span>404</span>
      </Route>
    </Switch>
  );
};
export default withRouter(Main);
