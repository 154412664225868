import React from 'react';
import {withRouter} from "react-router";
import './my-contest-versus-picks-live.scss';
import $ from 'jquery';
import Moment from 'react-moment';
import PlayerStatsPopup from "../player-stats-popup/player-stats-popup";
import beltImage from "../../assets/images/belt_render.jpg";

const getPicksOptions = {
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    }
};

class MyContestVersusPicksLive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            picks: [],
            pickArray: [],
            picksData: [],
            contestId: this.props.match.params.contestId,
            userContestId: this.props.match.params.userContestId,

        };
        this.fetchNewPicksData = this.fetchNewPicksData.bind(this);
        this.checkProps = this.checkProps.bind(this);
        this.checkForDisable = this.checkForDisable.bind(this);

        this.playerStats = React.createRef();

    }


    /*get picks and format array*/
    fetchNewPicksData(getPicksOptions) {
        $('.loader').addClass('show');

        fetch(process.env.REACT_APP_SERVER_URL + 'contests/get-user-picks-live-contest?contestid=' + this.state.contestId + '&userContestId=' + this.state.userContestId, getPicksOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    let picksData = [];
                    data['response'].forEach((liveData) => {
                        picksData.push(liveData);
                    });
                    this.checkProps(picksData);

                    $('.loader').removeClass('show');
                } else {
                    $('.loader').removeClass('show');
                }
            });

    }


    componentDidMount() {
        this.fetchNewPicksData(getPicksOptions);
    }

    checkProps(pickData) {
        let picksData = pickData;
        let pickArray = [];
        let primaryScore = {}, secondaryScore = {};
        picksData.forEach(data => {
            if (data.isICE && data.icePrimary) {
                primaryScore = data;
            } else if (data.isICE && !data.icePrimary) {
                secondaryScore = data;
            }
            if (!data.isICE && !data.propDisabled) {
                pickArray.push(data);
            }

        });
        this.setState({pickArray: pickArray, picksData: picksData});
        this.checkForDisable(primaryScore, secondaryScore);
    }

    checkForDisable(primaryScore, secondaryScore) {
        let count = 0;
        let picksData = this.state.picksData;
        let pickArray = this.state.pickArray;
        picksData.forEach(data => {
            if (!data.isICE && data.propDisabled) {
                count++;
                if (count === 1) {
                    if (!primaryScore.propDisabled) {
                        pickArray.push(primaryScore);
                    } else {
                        if (!secondaryScore.propDisabled) {
                            pickArray.push(secondaryScore);
                            count++;
                        }
                    }
                } else if (count === 2) {
                    if (!secondaryScore.propDisabled) {
                        pickArray.push(secondaryScore);
                    }
                }

            }
        });
        this.setState({pickArray: pickArray, picksData: picksData});
    }

    teamAbr(data, versusPlayer2) {
        let str = '';
        if (versusPlayer2) {
            const team1Score = data['team1ScoreEvent2'] ? data.team1ScoreEvent2 : 0;
            const team2Score = data['team2ScoreEvent2'] ? data.team2ScoreEvent2 : 0;
            if (data.team1locationTypePlayer2 === 'home') {
                str = data.team2AbbrEvent2 + ' ' + team2Score + ' - ' + data.team1AbbrEvent2 + ' ' + team1Score;
            } else if (data.team1locationTypePlayer2 === 'away') {
                str = data.team1AbbrEvent2 + ' ' + team1Score + ' - ' + data.team2AbbrEvent2 + ' ' + team2Score;
            }
        } else {
            const team1Score = data['team1Score'] ? data.team1Score : 0;
            const team2Score = data['team2Score'] ? data.team2Score : 0;
            if (data.team1locationType === 'home') {
                str = data.team2Abbr + ' ' + team2Score + ' - ' + data.team1Abbr + ' ' + team1Score;
            } else if (data.team1locationType === 'away') {
                str = data.team1Abbr + ' ' + team1Score + ' - ' + data.team2Abbr + ' ' + team2Score;
            }
        }
        return str;
    }

    numSuffix(n) {
        const suffix = ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th";
        return n + suffix;
    }

    propsSort(props, player) {
        let propString = [];
        if (player === 'player2') {
            for (let i = 0; i < props.length; i++) {
                if (!props[i].belongsToPlayer1) {
                    propString.push(props[i].abbreviation);
                }
            }
        } else if (player === 'player1') {
            for (let i = 0; i < props.length; i++) {
                if (props[i].belongsToPlayer1) {
                    propString.push(props[i].abbreviation);
                }
            }
        }
        return propString.join('+');
    }

    triggerPlayerStatsPopup(player) {
        this.playerStats.current.openPlayerStatsPopup(player);
    }

    getMatchupStylingHomePlayer1(locationType, leagueType, pick) {
        let uniform = pick.player1.uniform !== null ? parseInt(pick.player1.uniform) : null
        if (leagueType === "PUBG") {
            return <span>{pick.team1Name}</span>
        }
        if(locationType !== 'home') {
            return ''
        }
        else {
            return <span>
            {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {pick.event1Team1Name} vs. {pick.event1Team2Name} </span> : ''}
            {pick.inningEvent >= 1 && !pick.event1Complete ? <span> {pick.event1Team1Name} vs. {pick.event1Team2Name} | {this.numSuffix(pick.inningEvent)} Round </span> : ''}
            {pick.event1Complete ? <span> Final |  {pick.event1Team1Name} vs. {pick.event1Team2Name}</span> : ''}</span>
        }

    }
    getMatchupStylingAwayPlayer1(locationType, leagueType, pick) {
        if(locationType !== 'away') {
            return ''
        }
        else {
            return <span>
            {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {pick.event1Team1Name} vs. {pick.event1Team2Name} </span> : ''}
            {pick.inningEvent >= 1 && !pick.event1Complete ? <span> {pick.event1Team1Name} vs. {pick.event1Team2Name} | {this.numSuffix(pick.inningEvent)} Round </span> : ''}
            {pick.event1Complete ? <span> Final |  {pick.event1Team1Name} vs. {pick.event1Team2Name}</span> : ''}</span>
        }
    }

    getMatchupStylingHomePlayer2(locationType, leagueType, pick) {
        if (leagueType === "PUBG") {
            return <span>{pick.team1Name}</span>
        }
        if(locationType !== 'home') {
            return ''
        }
        else {
            return <span>
            {pick.inningEvent2 === null || pick.inningEvent2 === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {pick.event2Team1Name} vs. {pick.event2Team2Name} </span> : ''}
            {pick.inningEvent2 >= 1 && !pick.event2Complete ? <span> {pick.event2Team1Name} vs. {pick.event2Team2Name} | {this.numSuffix(pick.inningEvent2)} Round </span> : ''}
            {pick.event2Complete ? <span> Final |  {pick.event2Team1Name} vs. {pick.event2Team2Name}</span> : ''}</span>
        }

    }
    getMatchupStylingAwayPlayer2(locationType, leagueType, pick) {
        let uniform = pick.player2.uniform !== null ? parseInt(pick.player2.uniform) : null
        if(locationType !== 'away') {
            return ''
        }
        else {
            return <span>
            {pick.inningEvent2 === null || pick.inningEvent2 === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {pick.event2Team1Name} vs. {pick.event2Team2Name} </span> : ''}
                {pick.inningEvent2 >= 1 && !pick.event2Complete ? <span> {pick.event2Team1Name} vs. {pick.event2Team2Name} | {this.numSuffix(pick.inningEvent2)} Round </span> : ''}
                {pick.event2Complete ? <span> Final |  {pick.event2Team1Name} vs. {pick.event2Team2Name}</span> : ''}</span>
        }
    }

    render() {
        const picks = this.state.pickArray;
        console.log(picks);
        return (
            <div className="tab-container versus-live active" rel="making-picks">
                <div className="making-picks-listing">
                    <div className="head">
                        <span>Player 1</span>
                        <span>VS</span>
                        <span>Player 2</span>
                    </div>
                    <ul className="listing">
                        {this.state.pickArray.length ? this.state.pickArray.map(pick => (
                            <li key={pick.propId}>
                                <div className="player" onClick={() => this.triggerPlayerStatsPopup(pick.player1)}>
                                    {
                                        pick.player1.leagueType === 'MLB' ?
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.inningEvent >= 1 && pick.inningEvent <= 9 && !pick.event1Complete ?
                                                    <span>{this.numSuffix(pick.inningEvent)} INN | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.event1Complete ? <span> Final | {this.teamAbr(pick, false)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player1.leagueType === 'NFL' ?
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.inningEvent >= 1 && pick.inningEvent <= 4 && !pick.event1Complete ?
                                                    <span>{this.numSuffix(pick.inningEvent)} QTR | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.event1Complete ? <span> Final | {this.teamAbr(pick, false)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player1.leagueType === 'NBA' ?
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.inningEvent >= 1 && pick.inningEvent <= 4 && !pick.event1Complete ?
                                                    <span>{this.numSuffix(pick.inningEvent)} QTR | {this.teamAbr(pick)}</span> : ''}
                                                {pick.event1Complete ? <span> Final | {this.teamAbr(pick, false)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player1.leagueType === 'GOLF' ?
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.numSuffix(pick.golfRound)} RND</span> : ''}
                                                {!pick.event1Complete && pick.inningEvent !== 0 && pick.inningEvent != null ?
                                                    <span>{this.numSuffix(pick.inningEvent)} HOLE | {this.numSuffix(pick.golfRound)} RND</span> : ''}
                                                {pick.event1Complete ? <span> Final | {this.numSuffix(pick.golfRound)} RND</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player1.leagueType === 'CRICKET' ?//nelsoncleancricket
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.inningEvent >= 1 && /*pick.inningEvent <= 9 && */!pick.event1Complete ?
                                                    <span>{this.numSuffix(pick.inningEvent)} INN | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.event1Complete ? <span> Final | {this.teamAbr(pick, false)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player1.leagueType === 'CSGO2' ?
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} RND | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.event1Complete ? <span> Final | {this.teamAbr(pick, false)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player1.leagueType === 'LOL' ?
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} RND | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.event1Complete ? <span> Final | {this.teamAbr(pick, false)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player1.leagueType === 'DOTA2' ?
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} RND | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.event1Complete ? <span> Final | {this.teamAbr(pick, false)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player1.leagueType === 'OW' ?
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} RND | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.event1Complete ? <span> Final | {this.teamAbr(pick, false)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player1.leagueType === 'SOCCER' ?
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} HALF | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.event1Complete ? <span> Final | {this.teamAbr(pick, false)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player1.leagueType === 'AUDL' ?
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} HALF | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.event1Complete ? <span> Final | {this.teamAbr(pick, false)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player1.leagueType === 'VAL' ?
                                            <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ?
                                                        <span><Moment utc local
                                                                      format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                {pick.inningEvent >= 1 && !pick.event1Complete ?
                                                    <span>{this.numSuffix(pick.inningEvent)} RND | {this.teamAbr(pick, false)}</span> : ''}
                                                {pick.event1Complete ?
                                                    <span> Final | {this.teamAbr(pick, false)}</span> : ''}
                                                </span>
                                            : ''
                                    }

                                    <img src={pick.player1.image} alt={pick.player1.firstName}/>
                                    <h3>
                                        {pick.player1.firstName} {pick.player1.lastName}
                                        {pick.team1locationType === 'home' && pick.player1.leagueType !== "UFC"
                                            ? (<span>{pick.player1.positionAbbreviation}-{pick.player1.teamAbbr}| {pick.team2Abbr} @ {pick.team1Abbr}</span>)
                                            : this.getMatchupStylingHomePlayer1(pick.team1locationType, pick.player1.leagueType, pick)
                                        }
                                        {pick.team1locationType === 'away' && pick.player1.leagueType !== "UFC"
                                            ? (<span>{pick.player1.positionAbbreviation}-{pick.player1.teamAbbr}| {pick.team1Abbr} @ {pick.team2Abbr}</span>)
                                            : this.getMatchupStylingAwayPlayer1(pick.team1locationType, pick.player1.leagueType, pick)
                                        }

                                        {/*{pick.team1locationType === 'home'*/}
                                        {/*    ? (<span>{pick.player1.positionAbbreviation}-{pick.player1.teamAbbr} | {pick.team2Abbr} @ {pick.team1Abbr}</span>)*/}
                                        {/*    : ''*/}
                                        {/*}*/}
                                        {/*{pick.team1locationType === 'away'*/}
                                        {/*    ? (<span>{pick.player1.positionAbbreviation}-{pick.player1.teamAbbr} | {pick.team1Abbr} @ {pick.team2Abbr}</span>)*/}
                                        {/*    : ''*/}
                                        {/*}*/}
                                    </h3>
                                    <h4>
                                        Live Points: <strong>{pick.livePropPoints + pick.player1Advantage}</strong>
                                    </h4>
                                    <h4>
                                        Total ({this.propsSort(pick.propParameters, 'player1')})
                                        <span> +{pick.player1Advantage}</span>
                                    </h4>
                                    {
                                        !pick.event1Complete || ! pick.event2Complete ?
                                            pick.isOver ?
                                                    <div>
                                                        <div className={pick.livePropPoints + pick.player1Advantage < pick.livePropPointsPlayer2 ? 'blue' : 'green'}>{pick.player1Points} PTS
                                                        </div>
                                                        <span>Owned {pick.ownedPercentage}%</span>
                                                    </div>
                                                    : ''

                                            :
                                            pick.isOver ?
                                                    <div>
                                                        <div className={pick.livePropPoints + pick.player1Advantage < pick.livePropPointsPlayer2 ? 'red' : 'green'}>{pick.player1Points} PTS
                                                        </div>
                                                        <span>Owned {pick.ownedPercentage}%</span>
                                                    </div>
                                                    : ''

                                    }


                                </div>
                                <div className="vs">
                                    <span>VS</span>
                                </div>
                                <div className="player" onClick={() => this.triggerPlayerStatsPopup(pick.player2)}>
                                    {
                                        pick.player2.leagueType === 'MLB' ?
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.inningEvent2 >= 1 && pick.inningEvent2 <= 9 && !pick.event2Complete ?
                                                    <span>{this.numSuffix(pick.inningEvent2)} INN | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.teamAbr(pick, true)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player2.leagueType === 'NFL' ?
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.inningEvent2 >= 1 && pick.inningEvent2 <= 4 && !pick.event2Complete ?
                                                    <span>{this.numSuffix(pick.inningEvent2)} QTR | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.teamAbr(pick, true)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player2.leagueType === 'NBA' ?
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.inningEvent2 >= 1 && pick.inningEvent2 <= 4 && !pick.event2Complete ?
                                                    <span>{this.numSuffix(pick.inningEvent2)} QTR | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.teamAbr(pick, true)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player2.leagueType === 'GOLF' ?
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.numSuffix(pick.golfRound)} RND</span> : ''}
                                                {!pick.event2Complete && pick.inningEvent2 !== 0 && pick.inningEvent2 != null ?
                                                    <span>{this.numSuffix(pick.inningEvent)} HOLE | {this.numSuffix(pick.golfRound)} RND</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.numSuffix(pick.golfRound)} RND</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player2.leagueType === 'CRICKET' ?//nelsoncleancricket
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.inningEvent2 >= 1 && /*pick.inningEvent2 <= 9 && */!pick.event2Complete ?
                                                    <span>{this.numSuffix(pick.inningEvent2)} INN | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.teamAbr(pick, true)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player2.leagueType === 'CSGO2' ?
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.inningEvent2 >= 1 && !pick.event2Complete ? <span>{this.numSuffix(pick.inningEvent2)} RND | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.teamAbr(pick, true)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player2.leagueType === 'LOL' ?
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.inningEvent2 >= 1 && !pick.event2Complete ? <span>{this.numSuffix(pick.inningEvent2)} RND | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.teamAbr(pick, true)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player2.leagueType === 'DOTA2' ?
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.inningEvent2 >= 1 && !pick.event2Complete ? <span>{this.numSuffix(pick.inningEvent2)} RND | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.teamAbr(pick, true)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player2.leagueType === 'OW' ?
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.inningEvent2 >= 1 && !pick.event2Complete ? <span>{this.numSuffix(pick.inningEvent2)} RND | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.teamAbr(pick, true)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player2.leagueType === 'SOCCER' ?
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.inningEvent2 >= 1 && !pick.event2Complete ? <span>{this.numSuffix(pick.inningEvent2)} HALF | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.teamAbr(pick, true)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player2.leagueType === 'AUDL' ?
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.inningEvent2 >= 1 && !pick.event2Complete ? <span>{this.numSuffix(pick.inningEvent2)} HALF | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.teamAbr(pick, true)}</span> : ''}
                                                </span>
                                            : ''
                                    }
                                    {
                                        pick.player2.leagueType === 'VAL' ?
                                            <span>
                                                    {pick.inningEvent2 === null || pick.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{pick.startTimeEvent2}</Moment> | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.inningEvent2 >= 1 && !pick.event2Complete ? <span>{this.numSuffix(pick.inningEvent2)} RND | {this.teamAbr(pick, true)}</span> : ''}
                                                {pick.event2Complete ? <span> Final | {this.teamAbr(pick, true)}</span> : ''}
                                                </span>
                                            : ''
                                    }

                                    <img src={pick.player2.image} alt={pick.player2.firstName}/>
                                    <h3>
                                        {pick.player2.firstName} {pick.player2.lastName}
                                        {pick.team1locationTypePlayer2 === 'home' && pick.player2.leagueType !== "UFC"
                                            ? (<span>{pick.player2.positionAbbreviation}-{pick.player2.teamAbbr}| {pick.team2AbbrPlayer2} @ {pick.team1AbbrPlayer2}</span>)
                                            : this.getMatchupStylingHomePlayer2(pick.team1locationTypePlayer2, pick.player2.leagueType, pick, pick.player2.number)
                                        }
                                        {pick.team1locationTypePlayer2 === 'away' && pick.player2.leagueType !== "UFC"
                                            ? (<span>{pick.player2.positionAbbreviation}-{pick.player2.teamAbbr}| {pick.team1AbbrPlayer2} @ {pick.team2AbbrPlayer2}</span>)
                                            : this.getMatchupStylingAwayPlayer2(pick.team1locationTypePlayer2, pick.player2.leagueType, pick, pick.player2.number)
                                        }
                                    </h3>
                                    <h4>
                                        Live Points: <strong>{pick.livePropPointsPlayer2}</strong>
                                    </h4>
                                    <h4>
                                        Total ({this.propsSort(pick.propParameters, 'player2')})
                                    </h4>
                                    {
                                        !pick.event1Complete || !pick.event2Complete ?
                                            !pick.isOver ?
                                                <div>
                                                    <div className={pick.livePropPointsPlayer2 < pick.livePropPoints + pick.player1Advantage ? 'blue' : 'green'}>{pick.player2Points} PTS
                                                    </div>
                                                    <span>Owned {pick.ownedPercentage}%</span>
                                                </div>
                                                : ''
                                        :
                                        !pick.isOver ?
                                            <div>
                                                <div className={pick.livePropPointsPlayer2 < pick.livePropPoints + pick.player1Advantage ? 'red' : 'green'}>{pick.player2Points} PTS
                                                </div>
                                                <span>Owned {pick.ownedPercentage}%</span>
                                            </div>
                                            : ''
                                    }
                                </div>

                            </li>
                        )) : ''}
                    </ul>
                </div>
                <PlayerStatsPopup ref={this.playerStats}/>
            </div>
        );
    }
}


export default withRouter(MyContestVersusPicksLive);
